@mixin merchant-edit__btn--primary {
    cursor: pointer;
    padding: 5px 10px;
    background: #17948C;
    border: 1px solid #17948C;
    border-radius: 10px;
    width: fit-content;

    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: #FAFAFA;
    text-align: center;
    white-space: nowrap;
}

@mixin merchant-edit__btn--secondary {
    cursor: pointer;
    padding: 5px 10px;
    border: 1px solid #17948C;
    box-sizing: border-box;
    border-radius: 10px;
    background: transparent;
    width: fit-content;

    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: #17948C;
    text-align: center;
    white-space: nowrap;
}

@mixin merchant-edit__subtitle {
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #031615;
}

@mixin merchant-edit__text--primary {
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #17948C;
}

@mixin merchant-edit__caption {
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    letter-spacing: 0.415584px;
    color: #626262;
}


/*=========================================
    SEPARETOR MAIN : MERCHANT UPDATE
=========================================*/
.merchantUpdate {
    display: flex;
    flex-direction: column;

}

.merchantUpdate__header {
    height: 262px;
    padding: 12px 16px;
    background-image: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    &__desc {
        display: flex;
        align-items: center;

        >span {
            flex-grow: 1;
            font-weight: 500;
            font-size: 24px;
            line-height: 24px;
            letter-spacing: 0.03em;
            color: #000000;
        }

        >button {
            min-width: 124px;
            height: 38.55px;
            background: #17948C;
            border: 1.2px solid #17948C;
            box-sizing: border-box;
            border-radius: 10px;
            font-size: 14px;
            line-height: 24px;
            text-align: center;
            letter-spacing: 0.03em;
            color: #FAFAFA;
        }
    }

    &__actions {
        display: flex;
        align-items: center;

        >label {
            display: flex;
            align-items: center;
            margin-right: 23px;

            >span {
                font-size: 14px;
                line-height: 21px;
                letter-spacing: 0.0709534px;
                color: #031615;
                margin-right: 32px;
            }
        }
    }

}

.merchantUpdate__main {
    padding: 32px;

    &__navigations {
        margin-bottom: 32px
    }
}

.merchantUpdateToggle .react-toggle-track,
.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #C4C4C4;
}

.merchantUpdateToggle.react-toggle--checked .react-toggle-track,
.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #66D67B;
}


.merchantUpdateToggleText {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 12px;
    letter-spacing: 0.0709534px;
    color: #6C6C6C;
}

/*=========================================
    SEPARETOR CAOMPONENT TAB : MERCHANT INFO
=========================================*/
.merchantInfo {
    display: flex;
    flex-direction: column;

    &__main {
        display: flex;
    }

    &__forms {
        width: 100%;
    }

    &__forms,
    &__previewApp {
        background: #FFFFFF;
        border: 1px solid #E4E7E7;
        border-radius: 10px;
        padding: 32px;
        height: fit-content;
        // min-width : 345px;
        min-width: 417px;
    }

    //each form
    &__forms {
        >div:not(:last-child) {
            margin-bottom: 32px
        }
    }

    &__form {
        >div:nth-child(1) {
            font-weight: 500;
            font-size: 12px;
            line-height: 17px;
            letter-spacing: 0.01em;
            text-transform: capitalize;
            color: #031615;
            margin-bottom: 8px;
        }
    }

    //specific form
    &__form__sports {
        display: flex;
        flex-wrap: wrap;

        >span {
            padding: 8px 12px;
            background: #FFFFFF;
            border: 1px solid #17948C;
            box-sizing: border-box;
            border-radius: 22px;
            margin-bottom: 8px;
            margin-right: 12px;
            font-size: 12px;
            line-height: 17px;
            letter-spacing: 0.01em;
            color: #17948C;
        }
    }

    &__form__selectCity,
    &__form__selectSport {
        width: 343px
    }

    &__form__facilities {
        >div:nth-child(2) {
            overflow-x: auto;
            display: flex;
            // max-width: 342px;
            margin-bottom: 8px;
        }

        &__facility {
            display: flex;
            flex-direction: column;
            text-align: center;
            margin-right: 20px;

            >div {
                width: 70px;
                height: 70px;
                border-radius: 50%;
                border: 1px solid #E0E0E0;
                display: flex;
                justify-content: center;
                align-items: center;

                >img {
                    height: 45px;
                }
            }

            >span {
                font-size: 12px;
                line-height: 17px;
                text-align: center;
                letter-spacing: 0.01em;
                color: #828282;
            }
        }

        &--add {
            width: 343px;
            margin-top: 8px;
        }
    }

    &__form__searchMap {
        >div:nth-child(1) {
            cursor: pointer
        }

        >div:nth-child(2) {
            display: flex;
            align-items: center;
        }
    }

    &__form__coordinat {
        >div:nth-child(1) {
            width: 110px;
        }
    }

    &__form__map {
        height: 194px;
        width: 100%;
        padding: 2px;
        border: 1px solid #C9CFCE;
        border-radius: 10px;
    }

    &__form__address {
        >*:not(:first-child) {
            margin-bottom: 8px;
        }
    }

    &__form__addressesSuggestion {
        position: absolute;
        z-index: 10;
        width: 343px;
        height: 194px;
        overflow-y: auto;
        max-height: 250px;
        padding: 14px 24px;
        border: 1px solid #C9CFCE;
        border-radius: 10px;
        background-color: #FFFFFF;
        color: #031615;
        font-size: 12px;
        line-height: 17px;
        letter-spacing: 0.01em;

        >span {
            font-weight: 500;
            text-transform: capitalize;
            color: #031615;
            padding-bottom: 12px;
        }

        >li {
            cursor: pointer;
        }

        >li:hover {
            color: #17948C;
        }
    }

    &__form__socialMedia {
        svg {
            width: 24px;
            height: 24px;
        }

        >:not(:first-child) {
            margin-bottom: 8px;
        }

        >div:not(:first-child) {
            >div:nth-child(1) {
                cursor: pointer;
            }
        }
    }

    //Mobile App Preview
    &__previewApp {
        display: flex;
        flex-direction: column;
        margin-left: 42px;

        >div:nth-child(1) {
            font-weight: 500;
            font-size: 12px;
            line-height: 24px;
            letter-spacing: 0.03em;
            color: #031615;
            margin-bottom: 8px;
        }
    }

    &__footer {
        display: flex;
        flex-direction: row-reverse;
        margin-top: 32px;

        >button:not(:first-child) {
            margin-right: 16px;
        }
    }
}


.merchantInfo__form--split {
    color: #17948C;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.01em;
    background-color: #F2F3F3;
    border: 1px solid #C9CFCE;
    border-radius: 10px;
    display: flex;
    min-height: 44px;
    width: 100%;

    align-items: center;

    input {
        background-color: #F2F3F3;
        border: none;
        width: 100%;
        margin-left: 16px;
        margin-right: 16px;
        color: #17948C;
    }

    >div:nth-child(1) {
        background-color: #ffffff;
        border-right: 1px solid #C9CFCE;
        box-sizing: border-box;
        border-radius: 10px;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 10px;
    }
}

/*=========================================
    SEPARETOR CAOMPONENT TAB : USER
=========================================*/
.user-merchant {
    padding: 32px;
    display: flex;

    &:not(:last-child) {
        margin-bottom: 32px;
    }

}

.user-merchant {

    //general style inside form
    &__form {
        &:not(:last-child) {
            margin-bottom: 15px;
        }

        >div:nth-child(1) {
            font-weight: 500;
            font-size: 12px;
            line-height: 17px;
            letter-spacing: 0.01em;
            color: #031615;
            margin-bottom: 8px;
        }
    }

    &__form--left,
    &__form--right {
        min-width: 378px;
        display: flex;
        flex-direction: column;
    }

    &__form--right {
        margin-left: 78px;
        margin-top: 139px;

        width: 100%;
    }

    //specific style inside form
    &__form__add-section {
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: flex-end;
        align-items: flex-end;

        >button {
            border: 1px solid #17948C;
            padding: 10px 22px;
            border-radius: 10px;
            height: 44px;
            display: flex;
            align-items: center;
            font-size: 12px;
            line-height: 17px;
            letter-spacing: 0.01em;
            color: #17948C;
            background-color: #FFFFFF;
            cursor: pointer;

            >i {
                display: flex;
                align-items: center;
                border: 1px solid #17948C;
                border-radius: 50%;
                margin-right: 8px;
            }
        }
    }

    &__form__photo {
        display: flex;
        flex-direction: column;

        >img {
            width: 100px;
            height: 100px;
            object-fit: cover;
            border-radius: 50%;
        }

        >[type="file"] {
            border: 0;
            clip: rect(0, 0, 0, 0);
            height: 1px;
            overflow: hidden;
            padding: 0;
            position: absolute !important;
            white-space: nowrap;
            width: 1px;
        }

        >[type="file"],
        >label {
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #17948C;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            margin-top: -31px;
            margin-left: 70px;

            svg path {
                fill: #fff;
            }
        }
    }

    &__form__name {
        >i {
            vertical-align: middle;
            margin-left: 14px;
        }
    }

    &__form__email {}

    &__form__password {
        >i {
            vertical-align: middle;
            margin-left: 14px;
        }
    }

    &__form__password2 {
        >i {
            vertical-align: middle;
            margin-left: 14px;
        }
    }

    &__form__role {}

}


.pulldown--select {
    display: flex;
    align-items: center;
    background-color: #F2F3F3;
    width: 343px;
    height: 44px;
    padding: 16px 14px;
    border: 1px solid #C9CFCE;
    border-radius: 10px;
    cursor: pointer;

    >span {
        font-size: 12px;
        line-height: 17px;
        letter-spacing: 0.01em;
        color: #17948C;
        display: flex;
        flex-grow: 1;
    }

    i {
        display: flex;
        align-items: center;

        svg path {
            fill: #17948C;
        }
    }

}

.pulldown--option {
    z-index: 99;
    position: absolute;
    margin-top: 8px;
    min-height: 44px;
    width: 343px;
    background: #FFFFFF;
    border: 1px solid #C9CFCE;
    border-radius: 10px;

    >span {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        font-size: 12px;
        line-height: 17px;
        letter-spacing: 0.01em;
        color: #17948C;
        padding: 7px 16px;
        cursor: pointer;

        &:first-child {
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }

        &:last-child {
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
        }

        &:hover {
            background-color: #F2F3F3;
        }
    }
}

/*=========================================
        SEPARETOR CAOMPONENT TAB : BUSSINESS
=========================================*/
@mixin merchant-business__primary-text {
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #031615;
}

@mixin merchant-business__secondary-text {
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #17948C;
}

.merchant-business {
    display: flex;
    flex-direction: column;
}

.merchant-business__form {
    display: flex;
    padding: 31px;

    .merchant-business__form--container {
        margin-right: 100px;

        >div:not(:first-child) {
            margin-top: 27px;
        }
    }

    svg {
        width: 24px;
        height: 24px;

        path {
            fill: #358B9D
        }
    }

    &__join-date {
        >div:nth-child(1) {
            @include merchant-business__primary-text;
            margin-bottom: 8px;
        }

    }

    &__contact {

        &--input {
            display: flex;
            flex-direction: column;

            >:first-child {
                @include merchant-business__primary-text;
                margin-bottom: 8px
            }

            >:nth-child(2) {
                margin-bottom: 8px;
            }
        }
    }

    &__commision {
        >:first-child {
            @include merchant-business__primary-text;
            margin-bottom: 8px
        }

        //input section
        >div:nth-child(2) {
            color: #17948C;
            font-size: 12px;
            line-height: 17px;
            letter-spacing: 0.01em;
            background-color: #F2F3F3;
            border: 1px solid #C9CFCE;
            border-radius: 10px;
            height: 44px;
            width: 343px;
            display: flex;
            align-items: center;

            //icon-left section
            >div {
                background-color: #ffffff;
                border-right: 1px solid #C9CFCE;
                border-radius: 10px;
                height: 100%;
                display: flex;
                justify-content: center;

                i {
                    padding: 10px;
                    cursor: pointer;
                }

                >div:nth-child(1) {
                    display: flex;
                }

                >div:nth-child(2) {
                    position: absolute;
                    background-color: #ffffff;
                    margin-top: 48px;
                    margin-left: -25px;
                    display: flex;
                    flex-direction: column;
                    border: 1px solid #C9CFCE;
                    border-radius: 10px;
                    width: fit-content;
                    padding: 4px 0;

                    >i {
                        padding: 4px 8px;

                        &:hover {
                            background-color: #F2F3F3;
                        }
                    }
                }
            }

            input {
                background-color: #F2F3F3;
                border: none;
                width: 100%;
                margin-left: 16px;
                margin-right: 16px;
                color: #17948C;
            }
        }
    }

    &__expired {
        >:first-child {
            @include merchant-business__primary-text;
            margin-bottom: 8px
        }
    }

    &__bank {

        &--input {
            display: flex;
            flex-direction: column;

            >:first-child {
                @include merchant-business__primary-text;
                margin-bottom: 8px;
            }

            >:not(:last-child) {
                margin-top: 8px;
                margin-bottom: 8px;
            }
        }
    }

    &__add-bank {
        cursor: pointer;
        background: #fff;
        color: #17948C;
        font-size: 12px;
        line-height: 17px;
        letter-spacing: 0.01em;
        width: 343px;
        height: 44px;
        padding: 0 16px;
        border: none;
        border: 1px solid #C9CFCE;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 8px;

        >span {
            margin-left: 14px;
        }

        &:hover {
            background: #17948C;
            color: #fff;
            border-style: none;

            svg path {
                fill: #fff;
            }
        }
    }
}

.merchant-business__footer {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 32px;

    >button:not(:first-child) {
        margin-right: 16px;
    }
}

/*=========================================
        SEPARETOR CAOMPONENT TAB : GALERRY
=========================================*/
//merchant galery
.merchant-galery {
    display: flex;

    //merchant galery card
    &__photos>div:nth-child(1),
    &__preview {
        background: #FFFFFF;
        border: 1px solid #E4E7E7;
        border-radius: 10px;

        padding: 32px;
        height: fit-content;
        min-width: 417px;

    }

    &__photos {
        margin-right: 90px;

    }
}

.merchant-galery__photos {
    display: flex;
    flex-direction: column;

}

.merchant-galery__photo {
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
        margin-bottom: 16px;
    }

    span:first-child {
        margin-bottom: 8px;
        @include merchant-edit__subtitle;
    }

    span:last-child {
        margin-top: 8px;
        @include merchant-edit__caption;
    }

    div {
        display: flex;

        img {
            width: 343px;
            height: 170px;
            border-radius: 10px;
            border: 1px solid #C9CFCE;
            margin-right: 24px;
        }

        >div {
            justify-content: flex-end;
            display: flex;
            flex-direction: column;
        }
    }

    &__btn--primary {
        @include merchant-edit__btn--primary;

        &:not(:last-child) {
            margin-bottom: 12px;
        }
    }

    &__btn--secondary {
        @include merchant-edit__btn--secondary;

        &:not(:last-child) {
            margin-bottom: 12px;
        }
    }
}

.merchant-galery__add-photo {
    @include merchant-edit__text--primary;
    text-align: center;

    cursor: pointer;
    border: 1px solid #17948C;
    box-sizing: border-box;
    border-radius: 10px;
    background-color: transparent;
    padding: 10px;
    width: 343px;
}

.merchant-galery__btn-submit {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;

    >button:nth-child(1) {
        @include merchant-edit__btn--secondary;
        margin-right: 16px;

    }

    >button:nth-child(2) {
        @include merchant-edit__btn--primary;
    }
}

//merchant preview
.merchant-galery__preview {
    display: flex;
    flex-direction: column;

    >span:first-child {
        @include merchant-edit__subtitle;
        margin-bottom: 8px;
    }
}

.merchantUpdateWrapper {
    display: flex;
    width: 100%;

    &__wrapper {
        width: 100%;
    }
}