.infoPackage {
   display: flex;
   margin-top: 16px;
   padding-bottom: 50px;

   .bigCards {
      background: #FFFFFF;
      border: 1px solid #E4E7E7;
      border-radius: 10px;
      margin-right: 16px;
      padding: 12px 16px;
      width: 200%;

      >.title {
         font-style: normal;
         font-weight: 500;
         font-size: 14px;
         line-height: 21px;
         letter-spacing: 0.12px;
         color: #1C1C1C;
         margin-bottom: 31px;
      }

      .contentWrapper {
         display: flex;
         flex-wrap: wrap;

         margin-bottom: 32px;


         .wrapper {
            width: 33%;
            padding-bottom: 32px;

            >.title {
               font-style: normal;
               font-weight: 500;
               font-size: 12px;
               line-height: 18px;
               letter-spacing: 0.12987px;
               color: #333333;
               margin-bottom: 4px;
            }

            .dropdown {
               width: fit-content;
               background: #46AC89;
               border-radius: 8px;
               padding: 2px 8px;
               cursor: pointer;

               svg {
                  width: 16px;

                  path {
                     fill: #FFFFFF;
                  }
               }

               >.title {
                  font-style: normal;
                  font-weight: normal;
                  font-size: 10px;
                  line-height: 15px;
                  letter-spacing: 0.155844px;
                  color: #FFFFFF;
               }
            }

            .flex {
               display: flex;
            }

            .content {

               >.title {
                  font-style: normal;
                  font-weight: normal;
                  font-size: 14px;
                  line-height: 21px;
                  letter-spacing: 0.3px;
                  color: #798685;
               }

               >.subInfo {
                  font-style: normal;
                  font-weight: normal;
                  font-size: 10px;
                  line-height: 15px;
                  letter-spacing: 0.3px;
                  color: #798685;
               }

               .capsules {
                  .title {
                     border: 1px solid #17948C;
                     box-sizing: border-box;
                     border-radius: 22px;
                     padding: 2px 12px;
                     margin-right: 6px;

                     font-style: normal;
                     font-weight: normal;
                     font-size: 10px;
                     line-height: 17px;
                     letter-spacing: 0.01em;
                     color: #17948C;
                  }
               }

               .bluePrice,
               .greenPrice {
                  width: 18px;
                  height: 18px;
                  border-radius: 100%;
                  margin-right: 8px;
               }

               .bluePrice {
                  background-color: #2F80ED;
               }

               .greenPrice {
                  background-color: #27AE60;
               }
            }
         }
      }

      .containerWrapper {
         .wrapper {
            display: flex;
            align-items: center;
            margin-bottom: 24px;

            .list {
               width: 6px;
               height: 6px;
               border-radius: 100%;
               background: #17948C;
               margin-right: 9px;
            }

            >.title {
               font-style: normal;
               font-weight: normal;
               font-size: 14px;
               line-height: 21px;
               letter-spacing: 0.3px;
               color: #798685;
               width: 15rem;
            }

            >.progress {
               margin-right: 26px;
            }

            >input {
               width: 53px;
               margin: 0 9px;
               font-style: normal;
               font-weight: normal;
               font-size: 12px;
               line-height: 17px;
               letter-spacing: 0.01em;
               color: #17948C;
               padding: 9px 12px;

               background: #F2F3F3;
               border: 1px solid #C9CFCE;
               box-sizing: border-box;
               border-radius: 10px;
            }

            >.info {
               font-style: normal;
               font-weight: normal;
               font-size: 10px;
               line-height: 15px;
               letter-spacing: 0.3px;
               color: #031615;
            }
         }

         .action {
            display: flex;
            justify-content: flex-end;
            margin-top: 95px;

            button {
               background: #17948C;
               border-radius: 10px;
               border: none;
               padding: 5px 21px;
               cursor: pointer;
               margin-top: auto;

               font-style: normal;
               font-weight: 500;
               font-size: 14px;
               line-height: 24px;
               text-align: center;
               letter-spacing: 0.03em;
               color: #FFFFFF;
            }
         }

      }
   }

   .cards {
      width: 100%;

      svg {
         position: absolute;
         right: 1rem;
         top: 9rem;
         cursor: pointer;
      }

      .userInfo {
         background: #FFFFFF;
         border: 1px solid #E4E7E7;
         border-radius: 10px;
         padding: 12px 16px;
         margin-bottom: 13px;
         width: 100%;
         position: relative;

         >.title {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.12px;
            color: #1C1C1C;
            margin-bottom: 31px;
         }

         .contentWrapper {
            display: flex;
            align-items: center;

            .profilePicture {
               margin: 0 84px 0 66px;
            }

            .container {
               .wrapper {
                  padding-bottom: 34px;

                  .title {
                     font-style: normal;
                     font-weight: 500;
                     font-size: 12px;
                     line-height: 18px;
                     letter-spacing: 0.12987px;
                     color: #333333;
                     margin-bottom: 3px;
                  }

                  .info {
                     font-style: normal;
                     font-weight: normal;
                     font-size: 14px;
                     line-height: 21px;
                     letter-spacing: 0.3px;
                     color: #798685;
                  }
               }
            }
         }
      }

      .purchaseInfo {
         background: #FFFFFF;
         border: 1px solid #E4E7E7;
         border-radius: 10px;
         padding: 12px 191px 12px 16px;
         width: fit-content;
         position: relative;

         >.title {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.12px;
            color: #1C1C1C;
            margin-bottom: 34px;
         }

         .contentWrapper {
            .wrapper {
               padding-bottom: 32px;
            }

            .title {
               font-style: normal;
               font-weight: 500;
               font-size: 12px;
               line-height: 18px;
               letter-spacing: 0.12987px;
               color: #333333;
               margin-bottom: 3px;
            }

            .info {
               font-style: normal;
               font-weight: normal;
               font-size: 14px;
               line-height: 21px;
               letter-spacing: 0.3px;
               color: #798685;
            }
         }
      }
   }
}