.banner {
  @mixin title__h6 {
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: #000000; 
  }

  @mixin flex_center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @mixin text--primary {
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #17948C;
  }

  @mixin input {
    background: #F2F3F3;
    border: 1px solid #C9CFCE;
    border-radius: 10px;
    padding: 14px 16px;
    width: 100%;
    @include text--primary;
  }

  @mixin btn--primary {
    cursor: pointer;
    padding: 5px 10px;
    background: #17948C;
    border: 1px solid #17948C;
    border-radius: 5px;
    width: fit-content;

    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: #FAFAFA;
    text-align: center;
    white-space: nowrap;
  }

  @mixin banner-table__text--primary {
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.3px;
    color: #031615;
  }

  @mixin banner-table__text--primary {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.3px;
    color: #031615;
  }

  @mixin banner-table__text--secondary {
    font-size: 10px;
    line-height: 15px;
    letter-spacing: 0.3px;
    color: #4F4F4F;
  }

  &_title {
    @include title__h6;
    margin: 1rem 0 3rem 0;
  }

  &__button-create {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    >span {
        @include banner-table__text--primary;
    }

    >button, >a {
        padding: 8px 33px;
        margin: 0 10px;
        border-radius: 10px;
        background-color: #17948C;
        border: 1.2px solid #17948C;
        font-weight: 500;
        font-size: 14px;
        color: #FAFAFA;
        cursor: pointer;
        @include flex_center;
    }
  }

  &__table__list {

    >tbody td {
        vertical-align: top;
        max-width: 250px;
        border-right: 1px solid rgb(235, 235, 235);
        word-wrap: break-word;
        &:last-child {
            border-right: none;
        }
    }

    &__id {
        @include banner-table__text--primary;
    }

    &__date,
    &__title,
    &__impression {
        >div {
            display: flex;
            flex-direction: column;

            >span:nth-child(1) {
                @include banner-table__text--primary;
                margin-bottom: 6px;
            }

            >span:nth-child(2) {
                @include banner-table__text--secondary;
            }
        }
    }

    &__category {
      max-width: 300px;
    }

    &__status {
        @include banner-table__text--primary;
    }

    &__image {
      .image_wrapper {
        background-color: #C9CFCE;
        width: 200px;
        height: 80px;
        @include flex_center;
        color: white;
        > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
    }

    &__action {
      vertical-align: middle !important;
      .remove_button {
        color: #E53935;
      }
      .add_button {
        color: #17948C;
      }
    }
  }

  &__table__transparent {
    width: 100%;
    table-layout: fixed;
    & tr td {
      border: none;
      padding: 0.5rem;
      word-wrap:break-word;
      &:nth-child(1) {
        width: 40%
      }
      &:nth-child(2) {
        width: 5%
      }
      &:nth-child(3) {
        width: 55%;
      }
    }
  }

  &_form_wrapper {
    background-color: white;
    border-radius: 10px;
    padding: 2rem;
    input, textarea, select {
      &.is-invalid {
        border-color: #E53935;
      }
    }
    &_group {
      padding: 1rem;
      border-radius: 5px;
      border: 1px solid #C9CFCE;
      margin-bottom: 1rem;
    }
    &_control {
      margin-bottom: 1rem;
      flex: 1;
      &_title {
        font-weight: 500;
        color: #031615;
        margin-bottom: 1rem;
      }
      > header {
        font-weight: 500;
        color: #031615;
        margin-bottom: 1rem;
      }
      > input {
        @include input;
      }
      &.type {
        max-width: 300px;
        margin-bottom: 2rem;
      }
      &.date {
        flex: 1;
      }
      .selectWrapper {
        position: relative;
        @include input;
        > select, > input {
          width: 100%;
          border: none;
          background: none;
          @include text--primary;
        }
        .dropdownBtn {
          border: none;
          outline: none;
          background-color: none;
          font-size: 10px;
          @include text--primary;
          &:hover {
            background-color: none;
          }
        }
        .suggestions {
          position: absolute;
          width: 100%;
          padding: 0.5rem 0;
          background-color: white;
          top: calc(100% + 0.5rem);
          left: 0;
          border-radius: 5px;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
          &__item {
            padding: 0.3rem 1rem;
            cursor: pointer;
            &:hover {
              background-color: #ececec;
            }
          }
        }
      }
      &.customParameters {
        > .input_wrapper {
          @include input;
          > input {
            background: none;
            border: none;
            &:nth-child(1) {
              border-right: 1px solid #C9CFCE;
            }
            &:nth-child(2) {
              padding-left: 1rem
            }
          }
        }
        > .parameter_preview {
            @extend .input_wrapper;
            background: #edf7f6;
            padding: 10px;
            margin-bottom: 1rem;
            button {
              background: none;
              color: red;
              margin: 0 !important;
              padding: 0.5rem;
            }
        }
        button {
          background-color: rgb(0, 128, 100);
          color: white;
          margin: 1rem 0;
          border-radius: 7px;
          border: none;
          outline: none;
          box-shadow: none;
          cursor: pointer;
        }
      }
    }
  }

  &_section_data {
    &_title {
      display: flex;
      align-items: center;
      margin: 1.5rem 0 2rem 0;
      header {
        @include title__h6;
        font-size: 20px;
        margin-right: 1rem;
      }
      .line {
        background-color: #f0f0f0;
        height: 2px;
        flex: 1;
      }
    }
  }

  &_image_drop_area {
    width: 100%;
    min-height: 300px;
    border: 2px dashed #ccc;
    margin: 0 auto 1.5rem auto;
    padding: 1.5rem;
    @include flex_center;
    flex-direction: column;
    > header, > span {
      font-weight: 500;
      color: #031615;
      margin-bottom: 0.4rem;
    }
    > button {
      @include btn--primary;
    }
    &.dragover {
      border: 2px solid #ccc !important;
    }
  }

  &_image_preview {
    max-width: 700px;
    margin: 0 auto;
    > img {
      width: 100%;
      display: block;
    }
    &_button_wrapper {
      text-align: center;
      margin-top: 1rem;
      .change {
        @include btn--primary;
      }
      .delete {
        @include btn--primary;
        color: #17948C;
        background-color: #f3f3f3;
      }
    }
  }

  &_upload_icon {
    color: #aed3d0;
    font-size: 80px;
    margin-bottom: 1rem;
  }

  &_list_wrapper {
    background-color: white;
    border-radius: 10px;
    padding: 2rem;
    max-width: 100%;
  }

  &_select_category {
    display: flex;
    align-items: center;
    flex: 1;
    margin-right: 1rem;
    max-width: 500px;
    .selectWrapper {
      @include input;
      > select, > input {
        width: 100%;
        border: none;
        background: none;
        @include text--primary;
      }
    }
  }

  &_button {
    @include btn--primary;
  }
}