.scheduleMerchant {
  width: '100%';
  padding: 10px 18px 0;

  >div:nth-child(1) {
    position: relative;
    display: flex;
    justify-content: flex-end;

    >div {
      background: #17948c;
      border-radius: 8px;
      font-weight: 500;
      font-size: 12px;
      text-align: center;
      letter-spacing: 0.155844px;
      color: #ffffff;
      padding: 10px 26px;
      cursor: pointer;
    }
  }

  &__button {
    background: #17948C;
    border: 1.2px solid #17948C;
    box-sizing: border-box;
    border-radius: 10px;
    width: fit-content;
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    margin-top: 32px;
    margin-bottom: 40px;

    >button {
      cursor: pointer;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.03em;
      color: #FAFAFA;
      padding: 5px 10px;
      border: none;
      background-color: transparent;
    }
  }

  &__header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: #FFFFFF;
    padding: 19px 31px;
    border-radius: 15px 15px 0 0;

    &__title {
      flex: 1;
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 17px;
      letter-spacing: 0.01em;
      color: #17948C;
      align-self: center;

      >div:nth-child(1) {
        margin-right: 8px;
      }

      &__button {
        background: #FFFFFF;
        border: 1px solid #17948C;
        box-sizing: border-box;
        border-radius: 22px;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 17px;
        letter-spacing: 0.01em;
        color: #17948C;
        align-self: center;
        padding: 8px 12px;
        cursor: pointer;

        >button {
          border: none;
        }

        &--active {
          background: #17948C;
          border: 1px solid #FFFFFF;
          box-sizing: border-box;
          border-radius: 22px;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 17px;
          letter-spacing: 0.01em;
          color: #fff;
          align-self: center;
          padding: 8px 12px;
          cursor: pointer;
        }
      }
    }

    &__filter {
      background: #ffffff;
      border: 1px solid #c9cfce;
      border-radius: 5px;
      display: flex;
      align-items: center;
      margin-right: 17px;
      cursor: pointer;
      padding: 6px 8px;

      >div {
        margin-right: 10px;
      }
    }

    >button {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      letter-spacing: 0.181818px;
      color: #ffffff;
      background: #17948c;
      border-radius: 5px;
      padding: 5px 10px;
      border: none;
      cursor: pointer;
    }
  }

  &__newSchedule {
    display: flex;
    justify-content: flex-end;

    >button {
      background: #17948C;
      border: 1.2px solid #17948C;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 5px 10px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.03em;
      color: #FAFAFA;
      margin: 32px 0;
      border: none;
      cursor: pointer;
    }
  }
}

.scheduleMerchant__classList {
  display: flex;
  background-color: #E4E7E7;

  &__menu {
    width: 180px;
    background: #e4e7e7;
    padding: 16px 21px;
    display: flex;
    flex-direction: column;

    &__header {
      font-size: 12px;
      letter-spacing: 0.155844px;
      color: #031615;
      margin-bottom: 10px;
    }

    &__search {
      font-size: 12px;
      letter-spacing: 0.01em;
      background: #ffffff;
      border: 1px solid #afb6b6;
      border-radius: 4px;
      display: flex;
      align-items: center;
      padding: 6px 8px;
      margin-bottom: 5px;

      >input[type="text"] {
        border: none;
        width: 100px;
        background-color: transparent;
        font-size: 12px;

        &::placeholder {
          color: #17948c;
        }
      }
    }

    >button {
      background: #17948c;
      border: 1px solid #afb6b6;
      border-radius: 4px;
      font-size: 12px;
      letter-spacing: 0.01em;
      color: #fbfefe;
      padding: 7px 5px;
      margin-bottom: 3px;
      cursor: pointer;
    }
  }

  &__course {
    &__header {
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 0.3px;
      color: #000000;
    }

    &__card {
      background: #ffffff;
      border: 1px solid #e4e7e7;
      border-radius: 4px;
      padding: 4px 8px;
      font-size: 14px;
      letter-spacing: 0.3px;
      color: #1c1c1c;
      margin-bottom: 6px;

      &:last-child {
        margin-bottom: 10px;
      }

      svg {
        cursor: pointer;
      }

      &__title {
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        >div:nth-child(1) {
          flex: 1;
        }
      }

      &__price {
        margin-top: 8px;
        display: flex;
        align-items: center;
        mix-blend-mode: normal;
        color: rgba($color: #1c1c1c, $alpha: 0.5);

        >div:nth-child(1) {
          flex: 1;
        }
      }
    }
  }

  &__content {
    overflow-x: auto;
    display: flex;
    cursor: move;
    background: #E4E7E7;
    border: 1px solid #E4E7E7;

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      background-color: #e0e0e0;
    }

    &::-webkit-scrollbar {
      height: 10px;
      background-color: #e0e0e0;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
      background: #828282;
    }

    &__card {
      &__active {
        background: #337F99;
        color: #FFFFFF;
        border-radius: 10px;
        width: 181px;
        padding: 12px;
        margin: 18px 12px;
        height: fit-content;
        cursor: default;

        >div:nth-child(1) {
          >div:nth-child(1) {
            color: #FFFFFF;
          }
        }

        >div:nth-child(2) {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          letter-spacing: 0.207792px;
          color: #FFFFFF;
        }

        >div:nth-child(3) {
          font-style: normal;
          font-weight: normal;
          font-size: 10px;
          line-height: 15px;
          letter-spacing: 0.207792px;
          color: #FFFFFF;
          margin-top: 11px;
          border-bottom: 1px solid #FFFFFF;
        }

        >div:nth-child(4) {
          &::-webkit-scrollbar {
            width: 14px;
            background-color: transparent;
          }

          &::-webkit-scrollbar-thumb {
            height: 6px;
            border: 4px solid rgba(0, 0, 0, 0);
            background-clip: padding-box;
            -webkit-border-radius: 10px;
            background-color: #E4E7E7;
            -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
          }

          &::-webkit-scrollbar-button {
            width: 0;
            height: 0;
            display: none;
          }

          &::-webkit-scrollbar-corner {
            background-color: transparent;
          }
        }

        svg {
          path {
            stroke: #FFFFFF;
          }
        }
      }
    }

    &__card {
      background: #F2F3F3;
      border: 1px solid #E4E7E7;
      border-radius: 10px;
      width: 181px;
      padding: 12px;
      margin: 18px 12px;
      height: fit-content;
      cursor: default;

      >div:nth-child(2) {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.207792px;
        color: #798685;
      }

      >div:nth-child(3) {
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 15px;
        letter-spacing: 0.207792px;
        color: #4F4F4F;
        margin-top: 11px;
        border-bottom: 1px solid #828282;
      }

      &__header {
        display: flex;
        align-items: center;

        >div {
          flex: 1;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.01em;
          color: #031615;
          white-space: nowrap;
        }

        >svg {
          margin-left: 8px;
          cursor: pointer;
        }

        label {
          margin-left: 15px;
        }
      }

      &__scrollable {
        overflow-y: auto;
        max-height: 585px;

        &::-webkit-scrollbar {
          width: 14px;
          background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
          height: 6px;
          border: 4px solid rgba(0, 0, 0, 0);
          background-clip: padding-box;
          -webkit-border-radius: 10px;
          background-color: #828282;
          -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
        }

        &::-webkit-scrollbar-button {
          width: 0;
          height: 0;
          display: none;
        }

        &::-webkit-scrollbar-corner {
          background-color: transparent;
        }
      }

      &__class {
        background: #ffffff;
        border: 1px solid #e0e0e0;
        border-radius: 10px;
        padding: 8px 10px;
        margin-top: 11px;
        position: relative;

        >div:not(:nth-child(1)) {
          margin-bottom: 8px;
        }

        >div:nth-child(1) {
          display: flex;
          align-items: center;
          font-style: normal;
          font-weight: 600;
          font-size: 10px;
          line-height: 15px;
          letter-spacing: 0.001em;
          color: #031615;
          margin-bottom: 8px;

          >div:nth-child(1) {
            flex: 1;
          }

          >svg {
            cursor: pointer;
          }
        }

        >a {
          >div:nth-child(1) {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0.03em;
            color: #1C1C1C;
            text-transform: capitalize;
          }

          >div:nth-child(2) {
            font-style: normal;
            font-weight: normal;
            font-size: 10px;
            line-height: 15px;
            letter-spacing: 0.207792px;
            color: #AFB6B6;
            margin-top: 5px;

            >div {
              flex: 1;
            }
          }
        }
      }

      &__cancelled {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba($color: #F27A89, $alpha: 0.65);
        border: 1px solid #E4E7E7;
        border-radius: 10px;

        >svg {
          position: absolute;
          top: 8px;
          right: 8px;
          cursor: pointer;

          >path {
            fill: #031615;
            opacity: 1;
          }
        }

        >div {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.001em;
          color: #FFFFFF;
        }
      }

      &__notBookable {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba($color: #BDBDBD, $alpha: 0.65);
        border: 1px solid #E4E7E7;
        border-radius: 10px;

        >svg {
          position: absolute;
          top: 8px;
          right: 8px;
          cursor: pointer;

          >path {
            fill: #031615;
            opacity: 1;
          }
        }

        >div {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.001em;
          color: #031615;
        }
      }
    }
  }
}

.modalAddSchedule {
  z-index: 5;
  width: 290px;
  position: absolute;
  top: 113%;
  left: 32%;

  &__card {
    background: #e4e7e7;
    border-radius: 10px;
    position: relative;

    &__button {
      display: flex;
      justify-content: flex-end;

      >button {
        background: #17948c;
        border-radius: 10px;
        padding: 7px 25px;
        margin-bottom: 18px;
        margin-right: 20px;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 17px;
        letter-spacing: 0.01em;
        color: #ffffff;
        border: none;
        cursor: pointer;
      }
    }

    &__header {
      background-color: #fff;
      position: relative;
      border-radius: 10px 10px 0px 0px;

      &__icon{
        margin-top: 10px;
        margin-right: 10px;
      }

      >svg {
        position: absolute;
        right: 5px;
        width: 16px;
        cursor: pointer;
      }

      >div {
        text-align: center;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.03em;
        color: #000000;
        padding: 15px 90px;
      }
    }

    &__content {
      padding: 15px;

      &__title {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.3px;
        color: #000000;
      }

      &__capsule {
        display: flex;
        align-items: center;
        background: #ffffff;
        border: 1px solid #c9cfce;
        box-sizing: border-box;
        border-radius: 10px;
        min-width: 110px;

        >input {
          border-left: 1px solid #c9cfce;
          padding: 14px 8px;
        }

        >select {
          border-left: 1px solid #c9cfce;
          padding: 14px 8px;
        }

        >svg {
          padding: 0 6.5px;
          width: 37px;
        }
        

        &__header {
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 17px;
          align-items: center;
          letter-spacing: 0.01em;
          color: #17948c;
          padding: 10px;
        }

        &__text,
        &__textGrey {
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 17px;
          letter-spacing: 0.01em;
        }

        &__text {
          color: #17948c;
          border: none;
          width: 80%;
        }

        &__textGrey {
          color: #798685;
          flex: 1;
          border: none;
          width: 80%;
        }
      }

      &__add {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 12px;

        svg {
          path {
            fill: #17948c;
          }
        }
      }

      &__time {
        display: flex;
        margin-bottom: 12px;

        >div:nth-child(1) {
          margin-right: 12px;
        }

        >div {
          flex-basis: 50%;

          >div:nth-child(1) {
            margin-bottom: 8px;
          }
        }
      }

      &__quota,
      &__trainer,
      &__price {
        >div {
          margin-bottom: 8px;
        }

        >div:last-child {
          margin-bottom: 12px;
        }
      }
    }
  }
}

.modalCreateSchedule {
  scroll-margin-top: 120px;
  &__card {
    margin-bottom: 50px;

    &__content {
      background: #E4E7E7;
      padding: 17px;
      border-radius: 0 0 10px 10px;

      &__container {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;

        &::-webkit-scrollbar {
          width: 14px;
          background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
          height: 6px;
          border: 4px solid rgba(0, 0, 0, 0);
          background-clip: padding-box;
          -webkit-border-radius: 10px;
          background-color: #828282;
          -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
        }

        &::-webkit-scrollbar-button {
          width: 0;
          height: 0;
          display: none;
        }

        &::-webkit-scrollbar-corner {
          background-color: transparent;
        }
      }

      &__wrapper {
        background: #F2F3F3;
        flex-basis: 30%;
        border-radius: 10px;
        margin-right: 20px;
      }

      &__list {
        padding: 12px 14px;
      }

      &__capsule {
        display: flex;
        align-items: center;
        border: 1px solid #C9CFCE;
        background: #FFFFFF;
        box-sizing: border-box;
        border-radius: 10px;
        margin: 8px 12px 8px 0;

        >svg {
          padding: 0 6.5px;
          width: 37px;
        }

        &__text {
          padding: 12px 8px;
          border-left: 1px solid #C9CFCE;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 17px;
          letter-spacing: 0.01em;
          color: #17948C;
        }
      }

      &__time {
        &__wrapper {}

        &__header {
          display: flex;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 18px;
          letter-spacing: 0.3px;
          color: #000000;

          >div {
            padding-right: 70px;
          }
        }

        &__capsule {
          display: flex;
          align-items: center;

          >svg {
            cursor: pointer;
          }

          .closeCircle {
            margin-left: 8px;
          }
        }
      }

      &__button {
        display: flex;
        justify-content: flex-end;

        >button {
          border-radius: 10px;
          box-sizing: border-box;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 17px;
          letter-spacing: 0.01em;
          padding: 8px 16px;
          border: none;
          cursor: pointer;
        }

        >button:nth-child(1) {
          background: #F2F3F3;
          border: 1px solid #17948C;
          color: #17948C;
        }

        >button:nth-child(2) {
          background: #17948C;
          color: #FFFFFF;
          margin-left: 12px;
        }
      }
    }
  }

  &__button {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;

    >button {
      background: #17948C;
      border-radius: 10px;
      padding: 8px;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 17px;
      letter-spacing: 0.01em;
      color: #FFFFFF;
      margin: 10px 0px;
      border: none;
      cursor: pointer;
    }
  }
}

.modalCreateScheduleOnDemand {
  background: #E0E0E0;
  border-radius: 10px 10px 10px 10px;

  >div:last-child {
    padding-bottom: 12px;
    display: flex;
    justify-content: flex-end;
    margin-right: 20px;

    >button {
      background: #17948C;
      border-radius: 10px;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 17px;
      letter-spacing: 0.01em;
      color: #FFFFFF;
      align-self: center;
      margin: 10px 0px;
      padding: 7px 8px;
      border: none;
      cursor: pointer;
    }
  }

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #FAFAFA;
    border-radius: 10px 10px 0px 0px;
    position: relative;
    padding: 15px 0;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.03em;
    color: #000000;

    >svg {
      position: absolute;
      right: 16px;
    }
  }

  &__content {
    padding-top: 18px;

    &__card {
      background: #E4E7E7;
      border-radius: 10px;

      &__header {
        background: #FFFFFF;
        border: 1px solid #FAFAFA;
        border-radius: 10px 10px 0px 0px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.03em;
        color: #000000;
        padding: 16px 18px;
      }

      &__container {
        display: flex;
        margin-bottom: 12px;

        &__list {
          display: flex;
          align-items: center;

          >svg {
            margin-left: 18px;
            cursor: pointer;
          }
        }
      }

      &__wrapper {
        border-radius: 10px;
        padding: 18px;

        &__capsule--active {
          display: flex;
          align-items: center;
          background: #F2F3F3;
          border: 1px solid #C9CFCE;
          border-radius: 10px;
          margin-right: 18px;

          >div {
            padding: 14px 16px;
            border-left: 1px solid #C9CFCE;
          }

          >svg {
            margin: 10px;
          }
        }

        &__capsule {
          display: flex;
          align-items: center;
          background: #FFFFFF;
          border: 1px solid #C9CFCE;
          border-radius: 10px;
          margin-right: 18px;

          >div {
            padding: 14px 16px;
            border-left: 1px solid #C9CFCE;
          }

          >svg {
            margin: 10px;
          }

          &__icon {
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 17px;
            letter-spacing: 0.01em;
            color: #17948C;
          }

          &__text {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 17px;
            letter-spacing: 0.01em;
            width: 120px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #17948C;
          }
        }
      }

      &__button {
        display: flex;
        align-items: center;
        background: #17948C;
        border-radius: 10px;
        width: fit-content;
        padding: 10px;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 17px;
        letter-spacing: 0.01em;
        color: #FFFFFF;
        margin-top: 12px;
        cursor: pointer;

        >svg {
          margin-right: 14px;
        }
      }
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    width: 100%;

    >div {
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 17px;
      letter-spacing: 0.01em;
      color: #17948C;
      cursor: pointer;

      >span {
        margin-left: 5px;
      }

      .iconTrash {
        margin-left: 10px;

        >path {
          fill: #17948C;
        }
      }
    }
  }
}

.modalUpdateList {
  &__card {
    position: fixed;
    z-index: 3;
    top: 30%;
    left: 48%;
    right: auto;
    bottom: auto;
  }

  &__header {
    display: flex;
    background: #FFFFFF;
    border-radius: 10px 10px 0px 0px;
    padding: 15px 120px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #000000;

    >svg {
      width: 16px;
      position: absolute;
      right: 10px;
      top: 12px;
      cursor: pointer;

      >path {
        fill: #000000
      }
    }
  }

  &__content {
    background: #E4E7E7;
    border-radius: 0 0 10px 10px;
    padding: 27px 14px 5px 14px;

    svg {
      cursor: pointer;
    }

    >div {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.3px;
    }

    >div:nth-child(1),
    >div:nth-child(2),
    >div:nth-child(4),
    >div:nth-child(5) {
      justify-content: space-between;
    }

    >div:nth-child(3) {
      padding-bottom: 12px;
      border-bottom: 1px solid #C9CFCE;

      >div:nth-child(2) {
        margin-left: 12px;
        color: #17948C;
        text-decoration-line: underline;
        cursor: pointer;
      }
    }

    >div:nth-child(6) {
      justify-content: flex-end;
      margin-top: 14px;

      >button {
        background: #17948C;
        border-radius: 10px;
        padding: 8px 20px;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 17px;
        letter-spacing: 0.01em;
        color: #FFFFFF;
        border: none;
        cursor: pointer;
      }
    }
  }
}

.modalTimeAction {
  position: absolute;
  right: 105px;
  top: 185px;
  background: #FFFFFF;
  border-radius: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.3px;
  color: #17948C;

  >div {
    padding: 0 14px;
    margin: 12px 0;
    cursor: pointer;

    &:hover {
      background: #E4E7E7;
    }
  }
}

.modalDeleteAction {
  position: fixed;
  z-index: 3;
  top: 62.5%;
  left: 72.5%;
  right: auto;
  bottom: auto;

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    border-radius: 10px 10px 0px 0px;
    padding: 14px 8px;

    >div {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      letter-spacing: 0.03em;
      color: #000000;
    }

    >svg {
      position: absolute;
      left: 0;
    }
  }

  &__content {
    background: #E4E7E7;
    display: flex;
    flex-direction: column;
    border-radius: 0 0 10px 10px;
    padding: 18px 14px;
    width: 286px;

    >div {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.3px;
      color: #000000;
    }

    >button {
      background: #E44D60;
      border-radius: 10px;
      padding: 8px 0;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.3px;
      color: #FFFFFF;
      margin-top: 19px;
      border: none;
      cursor: pointer;
    }
  }
}

.modalEditAction {
  position: fixed;
  z-index: 3;
  top: 15%;
  left: 28%;
  right: auto;
  bottom: auto;
  width: 862px;

  &__header {
    background: #FFFFFF;
    border: 1px solid #FAFAFA;
    border-radius: 10px 10px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 0;

    >div {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.03em;
      color: #000000;
    }

    >svg {
      position: absolute;
      right: 16px;
    }
  }

  &__scrollable {
    max-height: 400px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 14px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      height: 6px;
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      -webkit-border-radius: 10px;
      background-color: #ffffff;
      -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
    }

    &::-webkit-scrollbar-button {
      width: 0;
      height: 0;
      display: none;
    }

    &::-webkit-scrollbar-corner {
      background-color: transparent;
    }
  }

  &__content {
    background: #E4E7E7;
    border-radius: 0 0 10px 10px;

    &__wrapper {
      display: flex;
      justify-content: space-evenly;
      padding: 18px 0;
      border-bottom: 1px solid #AFB6B6;
    }

    &__class {
      max-width: 80px;
    }

    &__action {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      >button {
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 15px;
        letter-spacing: 0.155844px;
        color: #FFFFFF;
        padding: 2px 8px;
        border-radius: 8px;
        border: none;
        cursor: pointer;
      }

      &__bookable {
        background: #17948C;
        margin-bottom: 32px;
      }

      &__delete {
        background: #EB5757;
      }
    }
  }

  &__button {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 32px 20px;

    >button {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 17px;
      letter-spacing: 0.01em;
      padding: 8px 23px;
      border-radius: 10px;
      border: none;
      cursor: pointer;
    }

    >button:nth-child(1) {
      color: #17948C;
      border: 1px solid #17948C;
      border-radius: 10px;
      background: transparent;
    }

    >button:nth-child(2) {
      color: #FFFFFF;
      background: #17948C;
      margin-left: 14px;
    }
  }
}

.modalDeleteClassAction {
  position: fixed;
  z-index: 3;
  top: 50.7%;
  left: 73.5%;
  right: auto;
  bottom: auto;

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    border-radius: 10px 10px 0px 0px;
    padding: 14px 8px;

    >div {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      letter-spacing: 0.03em;
      color: #000000;
    }

    >svg {
      position: absolute;
      left: 0;
    }
  }

  &__content {
    background: #E4E7E7;
    display: flex;
    flex-direction: column;
    border-radius: 0 0 10px 10px;
    padding: 18px 14px;
    width: 286px;

    >div {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.3px;
      color: #000000;
    }

    >button {
      background: #E44D60;
      border-radius: 10px;
      padding: 8px 0;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.3px;
      color: #FFFFFF;
      margin-top: 19px;
      text-transform: uppercase;
      border: none;
      cursor: pointer;
    }
  }
}

.modalEditClassAction {
  position: fixed;
  z-index: 3;
  top: 55.8%;
  left: 73.5%;
  right: auto;
  bottom: auto;
  width: 286px;

  &__header {
    background: #FFFFFF;
    border: 1px solid #FAFAFA;
    border-radius: 10px 10px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 0;

    >div {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.03em;
      color: #000000;
    }

    >svg {
      position: absolute;
      left: 0;
    }
  }

  &__content {
    background: #E4E7E7;
    border-radius: 0 0 10px 10px;

    >div {
      padding: 16px;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.3px;
      color: #000000;
      cursor: pointer;

      &:hover {
        background: #FFFFFF;
      }
    }
  }
}

.modalEditList {
  &__card {
    position: fixed;
    z-index: 3;
    top: 30%;
    left: 48%;
    right: auto;
    bottom: auto;
  }

  &__header {
    display: flex;
    background: #FFFFFF;
    border-radius: 10px 10px 0px 0px;
    padding: 15px 120px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #000000;

    >svg {
      width: 16px;
      position: absolute;
      right: 10px;
      top: 12px;
      cursor: pointer;

      >path {
        fill: #000000
      }
    }
  }

  &__content {
    background: #E4E7E7;
    border-radius: 0 0 10px 10px;
    padding: 27px 14px 5px 14px;

    svg {
      cursor: pointer;
    }

    >div {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.3px;
    }

    >div:nth-child(1),
    >div:nth-child(2),
    >div:nth-child(3) {
      justify-content: space-between;
    }

    >div:nth-child(1) {
      border-bottom: 1px solid #C9CFCE;
      padding-bottom: 19px;
    }

    >div:nth-child(4) {
      justify-content: flex-end;
      margin-top: 14px;

      >button {
        background: #17948C;
        border-radius: 10px;
        padding: 8px 20px;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 17px;
        letter-spacing: 0.01em;
        color: #FFFFFF;
        border: none;
        cursor: pointer;
      }
    }
  }
}