/*==============================
    DETAIL BOOKING'S MODAL CUSTOM STYLING
================================*/
.detailBooking {
    padding: 13px 18px;

    span {
        color: #798685;
    }

    &__breadcrumbs {
        padding-top: 13px;
        font-size: 14px;
        letter-spacing: 0.01em;
        color: #798685;
    }

    &__header {
        font-weight: 500;
        color: black;
        font-size: 18px;
        margin-bottom: 20px;
    }

    &__card {
        display: flex;
        flex-wrap: nowrap;
    }

    &__transactionInfo,
    &__invoice,
    &__userInfo,
    &__classInfo {
        background: #FFFFFF;
        border: 1px solid #E4E7E7;
        border-radius: 10px;
        padding: 12px 14px;

        >div:nth-child(1) {
            font-weight: 500;
            font-size: 14px;
            letter-spacing: 0.12px;
            color: #1C1C1C;
            margin-bottom: 34px;
        }
    }

    &__transactionInfo {
        flex-basis: 80%;
        margin-right: 17px;

        &__wrapper {
            display: flex;
            justify-content: space-between;
        }

        &__container {
            position: relative;
            display: flex;
            justify-content: space-evenly;
            margin-top: 68px;
            font-weight: 500;

            .line {
                position: absolute;
                top: 35%;
                z-index: 0;
                width: 70%;
                height: 1px;
                background-color: #BDBDBD;
            }
        }

        &__status {
            text-align: center;
            z-index: 1;

            >div {
                text-align: center;
                font-size: 12px;
                color: #fff;
                background: #66D67B;
                border-radius: 8px;
                padding: 12px 40px;
            }

            >span {
                font-size: 10px;
            }
        }

        &__confirmStatus {
            >div {
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #FFFFFF;
                border: 1px solid #66D67B;
                color: #66D67B;
                border-radius: 8px;

                >div {
                    padding: 12px 30px;
                    font-size: 12px;
                }

                >span {
                    svg path {
                        fill: #66D67B;
                    }

                    margin-left: -20px;
                    padding-right: 20px;
                }
            }

            &:hover {
                >div {
                    background: #66D67B;
                    color: #ffffff;

                    >span {
                        svg path {
                            fill: #fff;
                        }
                    }
                }
            }
        }

        .containerModalStatus {
            position: relative;
        }

        .modalConfirmStatus {
            position: absolute;
            background: #FFFFFF;
            margin-top: 10px;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
            border-radius: 5px;
            width: 100%;

            >div {
                padding: 16px 16px;
                font-size: 12px;
                letter-spacing: 0.155844px;
                cursor: pointer;

                &:hover {
                    color: #fff;
                    background: #66D67B;
                }
            }

        }
    }

    &__invoice {
        flex-basis: 20%;

        &__wrapper {
            >div {
                padding: 10px 3px;
            }

            >div:not(:last-child) {
                display: flex;
                justify-content: space-between;
            }

            .totalPrice {
                border-top: 1px solid #AFB6B6;
                ;
            }

        }
    }

    &__userInfo {
        flex-basis: 60%;
        margin-right: 17px;
        margin-top: 16px;

        &__wrapper {
            display: flex;
            align-items: center;
            flex-wrap: nowrap;

            &__rightSide {
                flex-basis: 70%;
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                max-height: 162px;

                >div {
                    padding: 10px 3px;
                }
            }

            &__leftSide {
                flex-basis: 30%;
                display: flex;
                justify-content: center;
            }
        }
    }

    &__classInfo {
        flex-basis: 40%;
        margin-top: 16px;

    }
}

.profilePicture {
    width: 90px;
    height: 100px;
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
    -webkit-clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);

    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
}

.bookingType {
    padding: 4px 8px;
    background: #495050;
    border-radius: 8px;
    font-size: 10px;
    letter-spacing: 0.155844px;
    color: #FFFFFF;
    border: none;
}

.bookingSource {
    background-color: transparent;
    border: none;
    font-size: 14px;
    padding: 0;
    letter-spacing: 0.3px;
    color: #798685;
}

.bookingStatus {

    &--cancel,
    &--checkedin,
    &--confirm,
    &--reject,
    &--pending {
        color: #fff;
        border-radius: 8px;
        padding: 4px 8px;
        letter-spacing: 0.155844px;
        font-size: 10px;
        border: none;
        text-align: center;
    }

    &--cancel,
    &--reject {
        background-color: #e44d60;
    }

    &--checkedin {
        background-color: #46ac89;
    }

    &--confirm {
        background-color: #64d47c;
    }

    &--pending {
        background-color: #66D67B;
    }
}