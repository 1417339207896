.packages {
    &__id {
        @include listInformation__text--primary;
    }

    &__name {
        @include listInformation__text--primary;
    }

    &__class {
        @include listInformation__text--primary;
        max-width: 175px;
    }

    &__kouta {
        @include listInformation__text--primary;
    }

    &__duration {
        @include listInformation__text--primary;

    }

    &__price {
        @include listInformation__text--primary;

    }

    &__booked {
        @include listInformation__text--primary;

    }

}