// @mixin listInformation__text--primary {
// @mixin listInformation__text--secondary {

// Tab Booking On Transaction Food
.food-order__table {
    td {
        vertical-align: top;
    }
}

.food-order__table__id {
    @include listInformation__text--primary;
}

.food-order__table__date {
    div {
        white-space: nowrap;
    }

    div {
        @include listInformation__text--primary;
    }

    span {
        @include listInformation__text--secondary
    }
}


.food-order__table__package {
    max-width: 300px;

    div:nth-child(1) {
        @include listInformation__text--primary;
    }

    div:nth-child(2) {
        @include listInformation__text--secondary;
    }
}

.food-order__table__merchant {
    @include listInformation__text--primary;
}

.food-order__table__batch {
    @include listInformation__text--primary;

    >div {
        white-space: nowrap;
    }
}

.food-order__table__user {
    max-width: 200px;

    >div:nth-child(1) {
        @include listInformation__text--primary;
    }

    >div:nth-child(2),
    >div:nth-child(3) {
        @include listInformation__text--secondary;
    }

    div>:nth-child(2) {
        word-break: break-all;
    }
}

.food-order__table__address {
    max-width: 250px;

    >div:nth-child(1) {
        @include listInformation__text--secondary($color:#fff);
        width: fit-content;
        padding: 2px 8px;
        border-radius: 8px;
        background: #17948C;
        margin-bottom: 5px;
    }

    >div:nth-child(2),
    >div:nth-child(3) {
        @include listInformation__text--secondary;
    }
}

.food-order__table__price {
    @include listInformation__text--primary;
    white-space: nowrap;
}


// Tab Membership Gold On Transaction Food
.food-gold__table {
    thead {
        tr th {
            text-align: left;     
        }
    }
    td {
        vertical-align: top;
    }
}

.food-gold__table__id {
    @include listInformation__text--primary;
}

.food-gold__table__date {
    @include listInformation__text--primary;
}



.food-gold__table__membership {
    >div:nth-child(1) {
        @include listInformation__text--primary;
    }

    >div:nth-child(2) {
        @include listInformation__text--secondary;
    }

}

.food-gold__table__user {
    >div:nth-child(1) {
        @include listInformation__text--primary;
    }

    >div:not(:first-child) {
        @include listInformation__text--secondary;
    }
}

.food-gold__table__source {
    @include listInformation__text--primary;
}

.food-gold__table__status {
    >div:first-child {
        width: fit-content;
    }

    >div:not(:first-child) {
        @include listInformation__text--secondary;
    }
}

.food-gold__table__action {
    display: flex;
    justify-content: center;
    position: relative;
    cursor: pointer;
}