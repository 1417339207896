.col {
  width: 100%;
  
  &.pd-1 {
    padding: 0 0.5rem;
  }
  
  &.col {
    &-1 {max-width: 8.333333333333333%}
    &-2 {max-width: 16.66666666666667%}
    &-3 {max-width: 25%}
    &-4 {max-width: 33.33333333333333%}
    &-5 {max-width: 41.66666666666667%}
    &-6 {max-width: 50%}
    &-7 {max-width: 58.33333333333334%}
    &-8 {max-width: 66.66666666666667%}
    &-9 {max-width: 75%}
    &-10 {max-width: 83.33333333333333%}
    &-11 {max-width: 91.66666666666666%}
    &-12 {max-width: 100%}
  }
  @media (min-width: 576px) {
    &.sm {
      &-1 {max-width: 8.333333333333333%}
      &-2 {max-width: 16.66666666666667%}
      &-3 {max-width: 25%}
      &-4 {max-width: 33.33333333333333%}
      &-5 {max-width: 41.66666666666667%}
      &-6 {max-width: 50%}
      &-7 {max-width: 58.33333333333334%}
      &-8 {max-width: 66.66666666666667%}
      &-9 {max-width: 75%}
      &-10 {max-width: 83.33333333333333%}
      &-11 {max-width: 91.66666666666666%}
      &-12 {max-width: 100%}
    }
  }
  @media (min-width: 768px) {
    &.md {
      &-1 {max-width: 8.333333333333333%}
      &-2 {max-width: 16.66666666666667%}
      &-3 {max-width: 25%}
      &-4 {max-width: 33.33333333333333%}
      &-5 {max-width: 41.66666666666667%}
      &-6 {max-width: 50%}
      &-7 {max-width: 58.33333333333334%}
      &-8 {max-width: 66.66666666666667%}
      &-9 {max-width: 75%}
      &-10 {max-width: 83.33333333333333%}
      &-11 {max-width: 91.66666666666666%}
      &-12 {max-width: 100%}
    }
  }
  @media (min-width: 992px) {
    &.lg {
      &-1 {max-width: 8.333333333333333%}
      &-2 {max-width: 16.66666666666667%}
      &-3 {max-width: 25%}
      &-4 {max-width: 33.33333333333333%}
      &-5 {max-width: 41.66666666666667%}
      &-6 {max-width: 50%}
      &-7 {max-width: 58.33333333333334%}
      &-8 {max-width: 66.66666666666667%}
      &-9 {max-width: 75%}
      &-10 {max-width: 83.33333333333333%}
      &-11 {max-width: 91.66666666666666%}
      &-12 {max-width: 100%}
    }
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  &.pd-1 {
    margin: 0 -0.5rem
  }
}