.voucher__container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1rem;
  margin-bottom: 1rem;
}

.voucher__info {
  position: relative;
  overflow: hidden;
}

.voucher__flag {
  position: absolute;
  right: 0;
  top: 0;
  padding: 0.5rem;
  text-align: center;
  font-weight: normal;
  font-size: 12px;
  color: #fff;
  width: 5rem;
  border-radius: 0 0 0 10px;
}

.voucher__flag--active {
  background-color: #17948C;
}

.voucher__flag--expired {
  background-color: #EB5757;
}

.voucher__info,
.voucher__map {
  background-color: #fff;
  border-radius: 10px;
  padding: 1rem;
  box-shadow: 0 5px 10px #dcdcdc;
}

.voucher__wrapper {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;

  >div {
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 5px 10px hsl(0, 0%, 90%);
    margin: 0 -1px -1px 0;
  }
}

.voucher__assigned {
  margin-top: 1rem;
  display: grid;
  // grid-template-columns: repeat(2, 1fr);
  column-gap: 1rem;
  row-gap: 1rem;
  align-self: stretch;
}

.voucher__item,
.voucher__item-list {
  font-weight: normal;
  font-size: 12px;
  color: hsl(0, 0%, 50%);

  >div:first-of-type {
    font-weight: 500;
    font-size: 12px;
    color: #000;
  }
}

.voucher__map {
  margin-top: 0.5rem;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.voucher__value {
  position: relative;
  padding: 0.2rem;
  padding-left: 1rem;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 0.5rem;
    height: 0.5rem;
    background-color: #17948C;
    border-radius: 50%;
  }
}