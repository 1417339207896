
//display
.d-block {
  display: block;
}
.d-inline-block {
  display: inline-block;
}

//flex
.flex {
  display: flex !important;
}

.displayFlexCol {
  display: flex;
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-column {
  flex-direction: column;
}

.flex-1 {
  flex: 1 !important
}

.justify-between {
  justify-content: space-between !important;
}
.justify-start {
  justify-content: flex-start !important;
}
.justify-end {
  justify-content: flex-end !important;
}
.justify-center {
  justify-content: center !important;
}

.items-center {
  align-items: center;
}
.items-end {
  align-items: end;
}
.items-start {
  align-items: start;
}

// overflow
.overflow {
  &-hidden { overflow: hidden; }
  &-scroll { overflow: scroll; }
  &-auto { overflow: auto; }
}

//position
.position {
  &-absolute { position: absolute };
  &-fixed { position: fixed };
  &-relative { position: relative };
  &-static { position: static };
}

// sizing
.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100vh;
}

.text-center {
  text-align: center !important;
}

//padding
.p {
  &-0 { padding: 0 !important}
  &-1 { padding: 1rem !important; }
  &-1-5 { padding: 1.5rem !important; }
  &-2 { padding: 2rem !important; }
  &-2-5 { padding: 2.5rem !important; }
  &-3 { padding: 3rem !important; }
  &-3-5 { padding: 3.5rem !important; }
  &-4 { padding: 4rem !important; }

  &b {
    &-0 {padding-bottom: 0 !important}
    &-1 { padding-bottom: 1rem !important; }
    &-1-5 { padding-bottom: 1.5rem !important; }
    &-2 { padding-bottom: 2rem !important; }
    &-2-5 { padding-bottom: 2.5rem !important; }
    &-3 { padding-bottom: 3rem !important; }
    &-3.5 { padding-bottom: 3.5rem !important; }
    &-4 { padding-bottom: 4rem !important; }
  }

  &t {
    &-0 { padding-top: 0 !important }
    &-1 { padding-top: 1rem !important; }
    &-1-5 { padding-top: 1.5rem !important; }
    &-2 { padding-top: 2rem !important; }
    &-2-5 { padding-top: 2.5rem !important; }
    &-3 { padding-top: 3rem !important; }
    &-3-5 { padding-top: 3.5rem !important; }
    &-4 { padding-top: 4rem !important; }
  }

  &r {
    &-0 { padding-right: 0 !important}
    &-1 { padding-right: 1rem !important; }
    &-1-5 { padding-right: 1.5rem !important; }
    &-2 { padding-right: 2rem !important; }
    &-2-5 { padding-right: 2.5rem !important; }
    &-3 { padding-right: 3rem !important; }
    &-3-5 { padding-right: 3.5rem !important; }
    &-4 { padding-right: 4rem !important; }
  }

  &l {
    &-0 { padding-left: 0 !important }
    &-1 { padding-left: 1rem !important; }
    &-1-5 { padding-left: 1.5rem !important; }
    &-2 { padding-left: 2rem !important; }
    &-2-5 { padding-left: 2.5rem !important; }
    &-3 { padding-left: 3rem !important; }
    &-3-5 { padding-left: 3.5rem !important; }
    &-4 { padding-left: 4rem !important; }
  }

  &x {
    &-0 { padding: 0 0 !important}
    &-1 { padding: 0 1rem !important; }
    &-1-5 { padding: 0 1.5rem !important; }
    &-2 { padding: 0 2rem !important; }
    &-2-5 { padding: 0 2.5rem !important; }
    &-3 { padding: 0 3rem !important; }
    &-3-5 { padding: 0 3.5rem !important; }
    &-4 { padding: 0 4rem !important; }
  }

  &y {
    &-0 { padding: 0 0 !important}
    &-1 { padding: 1rem 0 !important; }
    &-1-5 { padding: 1.5rem 0 !important; }
    &-2 { padding: 2rem 0 !important; }
    &-2-5 { padding: 2.5rem 0 !important; }
    &-3 { padding: 3rem 0 !important; }
    &-3-5 { padding: 3.5rem 0 !important; }
    &-4 { padding: 4rem 0 !important; }
  }
}

//margin
.m {
  &-auto { margin: auto !important }
  &-0 { margin: 0 !important }
  &-0-5 { margin: 0.5rem !important }
  &-1 { margin: 1rem !important; }
  &-1-5 { margin: 1.5rem !important; }
  &-2 { margin: 2rem !important; }
  &-2-5 { margin: 2.5rem !important; }
  &-3 { margin: 3rem !important; }
  &-3-5 { margin: 3.5rem !important; }
  &-4 { margin: 4rem !important; }

  &b {
    &-auto { margin-bottom: auto !important }
    &-0 { margin-bottom: 0 !important }
    &-0-5 { margin-bottom: 0.5rem !important }
    &-1 { margin-bottom: 1rem !important; }
    &-1-5 { margin-bottom: 1.5rem !important; }
    &-2 { margin-bottom: 2rem !important; }
    &-2-5 { margin-bottom: 2.5rem !important; }
    &-3 { margin-bottom: 3rem !important; }
    &-3-5 { margin-bottom: 3.5rem !important; }
    &-4 { margin-bottom: 4rem !important; }
  }

  &t {
    &-auto { margin-top: auto !important }
    &-0 { margin-top: 0 !important }
    &-0-5 { margin-top: 0.5rem !important }
    &-1 { margin-top: 1rem !important; }
    &-1-5 { margin-top: 1.5rem !important; }
    &-2 { margin-top: 2rem !important; }
    &-2-5 { margin-top: 2.5rem !important; }
    &-3 { margin-top: 3rem !important; }
    &-3-5 { margin-top: 3.5rem !important; }
    &-4 { margin-top: 4rem !important; }
  }

  &r {
    &-auto { margin-right: auto !important }
    &-0 { margin-right: 0 !important }
    &-0-5 { margin-right: 0.5rem !important }
    &-1 { margin-right: 1rem !important; }
    &-1-5 { margin-right: 1.5rem !important; }
    &-2 { margin-right: 2rem !important; }
    &-2-5 { margin-right: 2.5rem !important; }
    &-3 { margin-right: 3rem !important; }
    &-3-5 { margin-right: 3.5rem !important; }
    &-4 { margin-right: 4rem !important; }
  }

  &l {
    &-auto { margin-left: auto !important }
    &-0 { margin-left: 0 !important }
    &-0-5 { margin-left: 0.5rem !important }
    &-1 { margin-left: 1rem !important; }
    &-1-5 { margin-left: 1.5rem !important; }
    &-2 { margin-left: 2rem !important; }
    &-2-5 { margin-left: 2.5rem !important; }
    &-3 { margin-left: 3rem !important; }
    &-3-5 { margin-left: 3.5rem !important; }
    &-4 { margin-left: 4rem !important; }
  }

  &x {
    &-0 { margin: 0 0 !important}
    &-0-5 { margin: 0 0.5rem !important }
    &-1 { margin: 0 1rem !important; }
    &-1-5 { margin: 0 1.5rem !important; }
    &-2 { margin: 0 2rem !important; }
    &-2-5 { margin: 0 2.5rem !important; }
    &-3 { margin: 0 3rem !important; }
    &-3-5 { margin: 0 3.5rem !important; }
    &-4 { margin: 0 4rem !important; }
  }

  &y {
    &-0 { margin: 0 0 !important}
    &-1 { margin: 1rem 0 !important; }
    &-1-5 { margin: 1.5rem 0 !important; }
    &-2 { margin: 2rem 0 !important; }
    &-2-5 { margin: 2.5rem 0 !important; }
    &-3 { margin: 3rem 0 !important; }
    &-3-5 { margin: 3.5rem 0 !important; }
    &-4 { margin: 4rem 0 !important; }
  }
}

// card
.card {
  background-color: white;
  border-radius: 10px;
}

// font weight
.font-weight {
  &-bold {
    font-weight: bold;
  }
  &-500 {
    font-weight: 500;
  }
  &-600 {
    font-weight: 600;
  }
}