.mainContainerPadd {
   padding: 13px 22px 13px 18px;

}

.corporates {

   &__action {
      background-color: transparent !important;
   }

   >.buttonAction {
      display: flex;
      justify-content: flex-end;

      button {
         margin-left: 18px;
         background: #17948C;
         border: none;
         border-radius: 10px;
         padding: 4px 8px;
         font-weight: 500;
         font-size: 16px;
         line-height: 24px;
         letter-spacing: 0.342857px;
         color: #FFFFFF;
         margin-bottom: 20px;
         cursor: pointer;
      }
   }

   >.title {
      font-weight: 600;
      font-size: 24px;
      align-items: center;
      letter-spacing: 0.01em;
      color: #000000;
   }

   &__card {
      &-small {
         max-width: 250px;
      }

      &-med {
         min-width: 500px;

         >div:nth-child(1) {
            width: 10rem;
         }

         >div:nth-child(2) {
            width: 100%;
         }
      }
   }

   .flexEnd {
      margin-left: auto;
   }
}