.label {}

.labelOntable {
    display: flex;
    justify-content: center;
    align-items: center;

    span {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1px 6px;
        border-radius: 5px;
        height: 32px;
        width: 100px;
    }
}

.labelOnModal {

    span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 26px;
        margin: 7px 0;
        border-radius: 5px;
        padding: 0 8px;
        text-align: center;
        color: #fff;
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 8px;
    }
}