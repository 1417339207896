@mixin merchant-create__btn--primary {
    cursor: pointer;
    padding: 5px 10px;
    background: #17948C;
    border: 1px solid #17948C;
    border-radius: 10px;
    width: fit-content;

    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: #FAFAFA;
    text-align: center;
    white-space: nowrap;
}

@mixin merchant-create__btn--secondary {
    cursor: pointer;
    padding: 5px 10px;
    border: 1px solid #17948C;
    box-sizing: border-box;
    border-radius: 10px;
    background: transparent;
    width: fit-content;

    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: #17948C;
    text-align: center;
    white-space: nowrap;
}

@mixin merchant-create__h6 {
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: #000000;
}

@mixin merchant-create__subtitle {
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #031615;
}

@mixin merchant-create__text--primary {
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #17948C;
}

@mixin merchant-create__caption {
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    letter-spacing: 0.415584px;
    color: #626262;
}

@mixin merchant-create__textarea {
    @include merchant-create__text--primary;
    background: #F2F3F3;
    border: 1px solid #C9CFCE;
    border-radius: 10px;
    padding: 14px 16px;
    width: 100%;
    min-height: 170px;
}

@mixin merchant-create__input {
    @include merchant-create__text--primary;
    background: #F2F3F3;
    border: 1px solid #C9CFCE;
    border-radius: 10px;
    padding: 14px 16px;
    width: 100%;
}

@mixin merchant-create__input-spilt {
    background-color: #F2F3F3;
    border: 1px solid #C9CFCE;
    border-radius: 10px;
    display: flex;
    min-height: 41px;
    width: 100%;
    align-items: center;

    input {
        background-color: #F2F3F3;
        border: none;
        width: 100%;
        margin-left: 16px;
        margin-right: 16px;
        color: #17948C;
        @include merchant-create__text--primary;
    }

    >div:nth-child(1) {
        background-color: #ffffff;
        border-right: 1px solid #C9CFCE;
        box-sizing: border-box;
        border-radius: 10px;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 10px;
    }
}

.create-class__selectPublish {
    &--title {
        font-weight: 500;
        font-size: 12px;
        line-height: 17px;
        letter-spacing: 0.01em;
        color: #031615;
        margin-bottom: 16px;
    }

    &__Wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        >div:nth-child(1) {
            margin-right: 16px;
        }

        .select-publish {
            display: flex;
            align-items: center;
            justify-content: center;

            >div {
                font-size: 12px;
                line-height: 17px;
                letter-spacing: 0.01em;
                color: #031615;
                margin-left: 5px;
            }
        }
    }
}

.create-class__button {
    display: flex;
    justify-items: flex-end;

    button {
        background: #17948C;
        padding: 5px 10px;
        border: 1px solid #17948C;
        box-sizing: border-box;
        border-radius: 10px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.03em;
        color: #FAFAFA;
        cursor: pointer;
        width: 100%;
    }
}

.create-class__forms {
    padding: 12px 24px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(calc(50% - 116px), 1fr));
    grid-column-gap: 66px;

    >* {
        background: #FFFFFF;
        border: 1px solid #E4E7E7;
        border-radius: 10px;
    }
}

.create-class__form {
    display: flex;
    flex-direction: column;
    padding: 24px;

    &__title--h6 {
        @include merchant-create__h6;
        margin-bottom: 32px;
    }

    &__title--subtitle {
        @include merchant-create__subtitle;
        margin-bottom: 32px;
    }

    &__category {
        margin-bottom: 16px;

        >div:nth-child(1) {
            @include merchant-create__subtitle;
            margin-bottom: 8px;
        }
    }

    &__title {
        font-weight: 500;
        font-size: 12px;
        line-height: 17px;
        letter-spacing: 0.01em;
        color: #031615;
        margin-bottom: 16px;

        >div {
            margin-bottom: 8px;
        }

        input {
            background: #F2F3F3;
            color: #17948C;
            font-size: 12px;
            line-height: 17px;
            letter-spacing: 0.01em;
            width: 100%;
            border-radius: 10px;
            border: 1px solid #C9CFCE;
            padding: 14px 16px;
        }
    }

    &__sport {
        margin-bottom: 16px;

        >div:nth-child(1) {
            @include merchant-create__subtitle;
        }

        >input {
            @include merchant-create__input;
        }

        >*:not(:last-child) {
            margin-bottom: 8px;
        }
    }

    &__desc {
        margin-bottom: 16px;

        >div:nth-child(1) {
            @include merchant-create__subtitle;
            margin-bottom: 8px;
        }

        >textarea {
            @include merchant-create__textarea;
        }
    }

    &__price {
        margin-bottom: 16px;
        @include merchant-create__input-spilt;
    }

    &__photos {
        margin-bottom: 16px;

        >div:nth-child(1) {
            @include merchant-create__subtitle;
            margin-bottom: 8px;
        }

        >div:nth-child(2) {
            margin-bottom: 16px;

            div {
                position: relative;

                svg {
                    cursor: pointer;
                    position: absolute;
                    right: 8px;
                    top: 8px;
                }

                img {
                    width: 100%;
                    border: 1px solid #C9CFCE;
                    border-radius: 10px;
                }
            }
        }
    }

    &__add-photo {
        position: relative;

        >[type="file"] {
            border: 0;
            clip: rect(0, 0, 0, 0);
            height: 1px;
            overflow: hidden;
            padding: 0;
            white-space: nowrap;
            width: 1px;
            position: absolute;

        }

        >[type="file"],
        >label {
            @include merchant-create__input;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #ffffff;

            &:hover {
                background: #17948C;
                color: #FFFFFF;

                svg {
                    fill: #FFFFFF;
                }
            }

            >span {
                margin-left: 8px;
            }
        }
    }

    &__buttonSave {
        border: 1px solid #17948C;
        box-sizing: border-box;
        border-radius: 10px;
        padding: 8px 16px;

        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 17px;
        display: flex;
        align-items: center;
        letter-spacing: 0.01em;
        cursor: pointer;
        background: #17948C;
        color: #FFFFFF;
    }

}