.tabPackage__header {
   display: flex;
   justify-content: space-between;
   align-items: center;

   margin-top: 20px;

   .title {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.12px;
      color: #1C1C1C;
   }

   .action {
      background: #17948C;
      border: 1.2px solid #17948C;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 5px 12px;
      cursor: pointer;

      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.03em;
      color: #FAFAFA;

      &:hover {
         background: #0f726b;
         border: 1.2px solid #0f726b;
      }
   }
}

.tabPackage__title {
   background: #FFFFFF;
   border: 1px solid #FAFAFA;
   border-radius: 10px 10px 0px 0px;
   padding: 16px 10px;
   margin-top: 10px;
}

.tabPackage__content {
   background: #E4E7E7;
   border: 1px solid #E4E7E7;
   border-radius: 10px;

   .cardWrapper {
      background: #E4E7E7;
      border: 1px solid #E4E7E7;
      border-radius: 0 0 10px 10px;
      padding: 17px 20px;

      >div {
         display: grid;
         grid-template-columns: repeat(4, minmax(0, 1fr));
         grid-gap: 24px;
         @media screen and (max-width: 1000px) {
            grid-template-columns: repeat(3, minmax(0, 1fr));
         }
         @media screen and (max-width: 768px) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
         }
      }

   }

}