.classPreview {
    width: 345px;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;

    &__main {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        z-index: 1;
        margin-top: -47px;
        min-height: 500px;
        background-color: #ffffff;
        display: flex;
        flex-direction: column;

        >div:nth-child(1) {
            text-align: center;
        }
    }

    &__mainConsultation {
        margin-top: -47px;
        min-height: 370px;
        background-color: #ffffff;
        display: flex;
        flex-direction: column;

        >div:nth-child(1) {
            text-align: center;
        }
    }
}

.classPreview__main {
    &__price {
        padding-left: 16px;
        margin-bottom: 27px;

        >span:nth-child(1) {
            font-weight: 600;
            font-size: 14px;
            line-height: 14px;
            letter-spacing: 0.03em;
            color: #17948C;
        }

        >span:nth-child(2) {
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.03em;
            color: #828282;
        }
    }

    &__session {
        display: flex;
        margin-bottom: 24px;
        padding: 0 16px;

        >div {
            border: 1px solid #17948C;
            box-sizing: border-box;
            border-radius: 20px;
            padding: 10px 12px;
            width: fit-content;

            font-size: 12px;
            line-height: 17px;
            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: 0.01em;
            color: #17948C;


            &:not(:last-child) {
                margin-right: 15px;
            }
        }
    }

    &__attendance {
        padding: 0px 16px;

        &__header {
            display: flex;
            justify-content: space-between;
            margin-top: 24px;
            margin-bottom: 13px;

            >span:nth-child(1) {
                font-weight: 500;
                font-size: 14px;
                line-height: 19px;
                letter-spacing: 0.01em;
                color: #031615;
            }

            >span:nth-child(2) {
                font-weight: 500;
                font-size: 14px;
                line-height: 14px;
                letter-spacing: 0.03em;
                color: #17948C;
            }
        }

        &__users {
            display: flex;
            align-items: center;
            margin-bottom: 28px;
            flex-wrap: wrap;

            >div {
                display: flex;

                >img {
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    object-fit: cover;
                    margin-bottom: 4px;

                    &:last-child {
                        margin-right: 8px;
                    }

                    &:not(:last-child) {
                        margin-right: -12px;
                    }
                }
            }

            >div>span:nth-of-type(1) {
                font-size: 12px;
                line-height: 17px;
                letter-spacing: 0.01em;
                color: #262825;
                margin-right: 10px;
            }

            >div>span:nth-of-type(2) {
                font-size: 12px;
                line-height: 17px;
                letter-spacing: 0.01em;
                color: #828282;
                white-space: nowrap;

            }
        }
    }

    &__about {
        display: flex;
        flex-direction: column;
        padding: 0 16px;
        margin-bottom: 32px;

        >span:nth-child(1) {
            font-weight: 500;
            font-size: 14px;
            line-height: 19px;
            letter-spacing: 0.01em;
            color: #031615;
            margin-bottom: 13px;
        }

        >span:nth-child(2) {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            color: #262825;
        }
    }

    &__trainer {
        display: flex;
        flex-direction: column;
        padding: 16px;
        margin-bottom: 32px;

        >span {
            font-weight: 500;
            font-size: 14px;
            line-height: 19px;
            letter-spacing: 0.01em;
            color: #031615;
            margin-bottom: 16px;
        }

        >div {
            display: flex;
            align-items: center;

            img {
                width: 53px;
                height: 53px;
                border-radius: 10px;
                object-fit: cover;
                margin-right: 19px;
            }

            >div {
                display: flex;
                flex-direction: column;

                >span:nth-child(1) {
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 17px;
                    letter-spacing: 0.03em;
                    color: #333333;
                }

                >span:nth-child(2) {

                    font-size: 12px;
                    line-height: 17px;
                    letter-spacing: 0.03em;
                    color: #333333;
                    opacity: 0.5;
                }
            }

            >div:last-child {
                min-width: 28px;
                flex-grow: 1;
                align-items: flex-end;
            }
        }
    }

    &__notes {
        display: flex;
        flex-direction: column;
        padding: 0 16px;
        margin-bottom: 32px;

        >span:nth-child(1) {
            margin-bottom: 16px;
            font-weight: 500;
            font-size: 14px;
            line-height: 19px;
            letter-spacing: 0.01em;
            color: #031615;
        }

        >:nth-child(2) {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            color: #262825;
        }
    }

}