@mixin user-create--content-title-text($color : true) {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.207792px;
    color: #000000;
}

@mixin user-create--primary-text($color : true) {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.01em;

    @if $color {
        color: $color;

    }

    @else {
        color: #031615;
    }

}

@mixin user-create--secondary-text($color : true) {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 15px;
    letter-spacing: 0.207792px;

    margin-top: 8px;
    margin-bottom: 24px;

    @if $color {
        color: $color;

    }

    @else {
        color: #031615;
    }
}

@mixin user-create--input() {
    background: #F2F3F3;
    border: 1px solid #C9CFCE;
    border-radius: 10px;
    padding: 14px 16px;
    font-size: 12px;
    line-height: 17px;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    color: #17948C;
    width: 100%;
}

.user-create {
    display: flex;
    flex-direction: column;

    &__title-page {
        font-weight: 600;
        font-size: 24px;
        line-height: 48px;
        letter-spacing: 0.01em;
        color: #000000;
        margin-bottom: 21px;
    }
}


/*============================================
                USER INFO
============================================*/
.user-info {

    display: flex;
    flex-direction: column;
    margin-bottom: 48px;

    //grid system
    &__forms {
        width: 100%;
        // display: grid;
        // grid-template-columns: repeat(auto-fill, minmax(calc(50% - 25px), 1fr));
        // grid-column-gap: 50px;
    }

    //form container
    &__form {
        background: #FFFFFF;
        border: 1px solid #E4E7E7;
        border-radius: 10px;
        padding: 32px;
        height: -moz-fit-content;
        height: fit-content;
    }

    &__submit-form {
        display: flex;

        >button {
            color: #FAFAFA;
            background-color: #17948C;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.03em;
            border-radius: 10px;
            border: none;
            padding: 5px 15px;
            cursor: pointer;
            margin-top: 32px;
        }
    }

    &__submit-form-browse-user {
        display: flex;
        justify-content: right;
        margin: 20px;

        >button {
            color: #FAFAFA;
            background-color: #17948C;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.03em;
            border-radius: 10px;
            border: none;
            padding: 5px 15px;
            cursor: pointer;
            margin-left: 10px;
        }
    }

    &__submit-form-disabled {
        display: flex;
        flex-direction: row-reverse;

        >button {
            color: #666666;
            background-color: #cccccc;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.03em;
            border-radius: 10px;
            border: none;
            padding: 5px 15px;
            cursor: pointer;
            margin-top: 32px;
        }
    }
}

//each input-styling inside form
.user-info__form {
    >div {
        margin-bottom: 16px;
    }

    &__title-category {
        @include user-create--content-title-text;
        margin-bottom: 32px !important;
    }

    &__name {
        >div:first-child {
            @include user-create--primary-text;
            margin-bottom: 8px;
        }

        >input {
            @include user-create--input;
        }
    }

    &__phone {
        >div:first-child {
            @include user-create--primary-text;
            margin-bottom: 8px;
        }

        >input {
            @include user-create--input;
        }
    }

    &__mail {
        >div:first-child {
            @include user-create--primary-text;
            margin-bottom: 8px;
        }

        >input {
            @include user-create--input;
        }
    }

    &__password,
    &__password--retype {
        >div:first-child {
            @include user-create--primary-text;
            margin-bottom: 8px;
        }

        >input {
            @include user-create--input;
        }
    }

    &__profile-pic {
        display: flex;
        flex-direction: column;

        >div:nth-child(1) {
            @include user-create--primary-text;
            margin-bottom: 8px;
        }

        >img {
            width: 100px;
            height: 100px;
            object-fit: cover;
            border-radius: 50%;
        }

        >[type="file"] {
            border: 0;
            clip: rect(0, 0, 0, 0);
            height: 1px;
            overflow: hidden;
            padding: 0;
            position: absolute !important;
            white-space: nowrap;
            width: 1px;
        }

        >[type="file"],
        >label {
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #17948C;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            margin-top: -31px;
            margin-left: 70px;

            svg path {
                fill: #fff;
            }
        }
    }

    &__birth-date {
        >div:first-child {
            @include user-create--primary-text;
            margin-bottom: 8px;
        }

        >input {
            @include user-create--input;
        }
    }

    &__gender {
        >div:first-child {
            @include user-create--primary-text;
            margin-bottom: 8px;
        }

        >input {
            @include user-create--input;
        }
    }

    &__hobbies {
        >div:first-child {
            @include user-create--primary-text;
            margin-bottom: 8px;
        }

        >input {
            @include user-create--input;
        }
    }

    &__occupation {
        >div:first-child {
            @include user-create--primary-text;
            margin-bottom: 8px;
        }

        >input {
            @include user-create--input;
        }
    }
}

/*============================================
                USER ROLE
============================================*/
.user-role {
    display: flex;
    flex-direction: column;
    padding: 32px;

    &__title {
        @include user-create--content-title-text;
        margin-bottom: 32px;
    }

}

.user-role__role-select {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(calc(25% - 24px), 1fr));
    grid-column-gap: 32px;

    >div>div:nth-child(1) {
        @include user-create--primary-text;
        margin-bottom: 8px;
    }

    &__list {
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;

        &__role {
            width: fit-content;
            color: #17948C;

            text-align: center;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.03em;
            border-radius: 21px;
            border: none;
            padding: 2px 10px;
            border: 1px solid #17948C;
            cursor: pointer;

            &:not(:last-child) {
                margin-bottom: 8px;
            }

            &:hover {
                padding: 3px 11px;
            }
        }

        &__role--active {
            @extend .user-role__role-select__list__role;
            color: #fff;
            background-color: #17948C;
        }
    }
}

.user-role__form {
    >div:not(:last-child) {
        margin-bottom: 8px;
    }
}