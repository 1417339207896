.createMerchantFood__title {
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: #000000;
    margin-top: 12px;
    margin-bottom: 32px;
}

.createMerchantFood__nav {
    border-bottom: 1px solid #E4E7E7;
    margin-bottom: 32px;
}