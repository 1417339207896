.tab-package__list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    justify-content: center;
    grid-gap: 32px 20px;
    padding: 17px 20px;
    background: #E4E7E7;
    border: 1px solid #E4E7E7;

    .card {
        background: #FFFFFF;
        border: 1px solid #E4E7E7;
        border-radius: 10px;
        margin-bottom: 16px;

        display: flex;
        width: 100%;

        &:hover {
            background: rgba($color: #000000, $alpha: 0.1);
            opacity: 0.9;
            cursor: pointer;
        }

        >div {
            padding: 12px 16px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
        }

        &__title {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
        }

        &__price {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
            letter-spacing: 0.03em;
            color: #17948C;
            margin-top: 5px;
        }

        &__action {
            display: flex;
            margin-top: auto;
            width: fit-content;
        }

        &__button {
            display: flex;
            background: #17948C;
            border-radius: 8px;
            border: none;
            padding: 2px 8px;

            font-style: normal;
            font-weight: normal;
            font-size: 10px;
            line-height: 15px;
            letter-spacing: 0.155844px;
            color: #FFFFFF;

            &:not(:first-child) {
                margin-left: 8px;
            }
        }

        &__img {
            padding: 0 !important;
        }

        &__packageIMG {
            width: 113px;
            height: 115px;
            border: 1px solid #E4E7E7;
            border-radius: 10px 0px 0px 10px;
            object-fit: cover;
        }
    }
}