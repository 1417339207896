@mixin merchantPreview__overflow {
    ::-webkit-scrollbar-track {
        // border-radius: 10px;
        // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        // background-color: #F5F5F5;
        background-color: rgb(238, 240, 238);
    }

    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        // background-color: #F5F5F5;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: rgb(206, 204, 204);
    }
}

/*===================================
          REUSE STYLE
===================================*/
.merchant-preview-content-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    color: #031615;
}

.merchant-preview-content-desc {
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #828282;
}

.merchant-preview-padding-screen {
    padding-left: 16px;
    padding-right: 16px;

}

.merchant-preview-hr {
    border: #C4C4C4;
}

/*===================================
            MAIN STYLE
===================================*/

.merchantPreview {
    width: 345px;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;

    &__Image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__header {
        display: flex;
        flex-direction: column;
    }

    &__header__navigation {
        z-index: 1;
        display: flex;
        width: 100%;
        height: 64px;
        padding: 28px 16px 12px 16px;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) -18.75%, rgba(0, 0, 0, 0) 100%);
        justify-content: space-between;

        >div>svg:nth-child(1) {
            margin-right: 24px;
        }

        svg path {
            fill: #ffffff
        }
    }

    &__header__navigationConsultation {
        display: flex;
        width: 100%;
        height: 64px;
        padding: 28px 16px 12px 16px;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) -18.75%, rgba(0, 0, 0, 0) 100%);
        justify-content: space-between;

        >div>svg:nth-child(1) {
            margin-right: 24px;
        }

        svg path {
            fill: #ffffff
        }
    }

    &__header__carousel--container {
        margin-top: -64px;
    }

    &__header__carousel {
        // z-index: 10;
        width: 375px;
        height: 304px;

        >img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }

        &__dot {
            // bottom: 0;
        }
    }

    &__main {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        z-index: 1;
        margin-top: -47px;
        min-height: 500px;
        background-color: #ffffff;
        display: flex;
        flex-direction: column;

        >div:nth-child(1) {
            text-align: center;
        }
    }
}

.merchantPreview__main {
    &__star {
        @extend .merchant-preview-padding-screen;
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        >span {
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: #17948C;
            margin-left: 14px;
        }
    }

    &__name {
        @extend .merchant-preview-padding-screen;
        font-weight: 600;
        font-size: 21px;
        line-height: 24px;
        letter-spacing: 0.015em;
        color: #000000;
        margin-bottom: 12px;
    }

    &__address {
        @extend .merchant-preview-padding-screen;
        display: flex;
        align-items: center;
        margin-bottom: 22px;

        >i {
            display: flex;
            align-items: center;
        }

        span {
            font-size: 14px;
            line-height: 19px;
            letter-spacing: 0.01em;
            color: #828282;
        }
    }

    &__sports {
        @extend .merchant-preview-padding-screen;
        overflow-x: auto;
        display: flex;
        margin-bottom: 25px;

        >*:not(:first-child) {
            margin-left: 16px;
        }

        span {
            padding: 8px 12px;
            height: 33px;
            background: #FFFFFF;
            border: 1px solid #17948C;
            border-radius: 22px;

            font-size: 12px;
            line-height: 17px;
            letter-spacing: 0.01em;
            color: #17948C;

        }
    }

    &__navigations {
        @extend .merchant-preview-padding-screen;
        margin-top: 21px;
        margin-bottom: 31px;

        div {
            border-bottom: 1px solid #828282;
            display: flex;
            justify-content: space-between;

            >span {
                font-weight: 600;
                font-size: 14px;
                line-height: 14px;
                display: flex;
                align-items: center;
                text-align: center;
                letter-spacing: 0.03em;
                padding-bottom: 11px;
                border-bottom: 3px solid transparent;
            }

            >span:nth-child(1) {
                color: #17948C;
                border-bottom: 3px solid #17948C;
            }

            >span:nth-child(2),
            >span:nth-child(3) {
                color: #828282;
            }
        }
    }

    &__about {
        @extend .merchant-preview-padding-screen;
        display: flex;
        flex-direction: column;
        margin-bottom: 32px;

        >span:nth-child(1) {
            @extend .merchant-preview-content-title;
            margin-bottom: 8px;
        }

        >span:nth-child(2) {
            @extend .merchant-preview-content-desc;
        }
    }

    &__facilities {
        @extend .merchant-preview-padding-screen;
        @include merchantPreview__overflow;
        margin-bottom: 32px;

        >span:nth-child(1) {
            @extend .merchant-preview-content-title;
            margin-bottom: 20px;
        }

        >div {
            display: flex;
            overflow-x: auto;
            padding-bottom: 8px;
        }
    }

    &__facility {
        display: flex;
        flex-direction: column;
        text-align: center;
        margin-right: 20px;

        >div {
            width: 70px;
            height: 70px;
            border-radius: 50%;
            border: 1px solid #E0E0E0;
            display: flex;
            justify-content: center;
            align-items: center;

            >img {
                height: 35px;
            }
        }

        >span {
            font-size: 12px;
            line-height: 17px;
            text-align: center;
            letter-spacing: 0.01em;
            color: #828282;
        }
    }

    &__review {
        @extend .merchant-preview-padding-screen;
        display: flex;
        flex-direction: column;
        margin-bottom: 32px;

        >span:nth-of-type(1) {
            @extend .merchant-preview-content-title;
            margin-bottom: 14px;
        }

        &__comment {
            @extend .merchant-preview-content-desc;
            margin-bottom: 16px;
        }

        &__totalReview {
            @extend .merchant-preview-content-desc;
            color: #17948C;
        }

        &__reviewer {
            display: flex;

            >img {
                width: 32px;
                height: 32px;
                border-radius: 50%;
                margin-right: 18px;
            }

            &__data {
                display: flex;
                flex-grow: 1;
                justify-content: space-between;
                margin-bottom: 16px;

                >div:nth-child(1) {
                    display: flex;
                    flex-direction: column;

                    >span:nth-child(1) {
                        font-size: 12px;
                        line-height: 17px;
                        letter-spacing: 0.01em;
                        color: #031615;
                        margin-bottom: 4px;
                    }

                    >span:nth-child(2) {
                        @extend .merchant-preview-content-desc
                    }
                }
            }
        }
    }

    &__location {
        @extend .merchant-preview-padding-screen;
        display: flex;
        flex-direction: column;
        margin-bottom: 32px;

        >span:nth-of-type(1) {
            @extend .merchant-preview-content-title;
            margin-bottom: 5px;
        }

        >span:nth-of-type(2) {
            @extend .merchant-preview-content-desc;
            margin-bottom: 16px;
        }

        &__map {
            height: 142px;
            width: 100%;
            padding: 2px;
            border-radius: 10px;
        }
    }

    &__trainer {
        @extend .merchant-preview-padding-screen;
        margin-bottom: 32px;

        >span:nth-of-type(1) {
            @extend .merchant-preview-content-title;
        }

    }

    &__socialMedias {
        @include merchantPreview__overflow;
        @extend .merchant-preview-padding-screen;
        margin-bottom: 32px;

        >span:nth-of-type(1) {
            @extend .merchant-preview-content-title;
            margin-bottom: 14px;
        }


        &__socialMedia {
            display: flex;
            overflow-x: auto;
            padding-bottom: 8px;
        }

        &__socialMedia__link {
            display: flex;
            align-items: center;
            background: #FFFFFF;
            padding: 8.5px 8px;
            border: 1px solid #17948C;
            border-radius: 22px;
            margin-right: 14px;

            >i {
                display: flex;
                align-items: center;
            }

            >span {
                font-size: 12px;
                line-height: 17px;
                letter-spacing: 0.01em;
                color: #17948C;
                margin-left: 4px;
            }
        }
    }
}