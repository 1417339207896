.class-own-merchant {
    &__id {
        padding: 12px 0;
    }

    &__name {
        padding: 12px 0;
    }

    &__booking {}

    &__confirmed {}

    &__canceled {}

    &__publish {}
}