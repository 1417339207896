@mixin merchant-create__btn--primary {
    cursor: pointer;
    padding: 5px 10px;
    background: #17948C;
    border: 1px solid #17948C;
    border-radius: 10px;
    width: fit-content;

    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: #FAFAFA;
    text-align: center;
    white-space: nowrap;
}

@mixin merchant-create__btn--secondary {
    cursor: pointer;
    padding: 5px 10px;
    border: 1px solid #17948C;
    box-sizing: border-box;
    border-radius: 10px;
    background: transparent;
    width: fit-content;

    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: #17948C;
    text-align: center;
    white-space: nowrap;
}

@mixin merchant-create__h6 {
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: #000000;
}

@mixin merchant-create__subtitle {
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #031615;
}

@mixin merchant-create__text--primary {
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #17948C;
}

@mixin merchant-create__caption {
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    letter-spacing: 0.415584px;
    color: #626262;
}

@mixin merchant-create__textarea {
    background: #F2F3F3;
    border: 1px solid #C9CFCE;
    border-radius: 10px;
    padding: 14px 16px;
    width: 100%;
    min-height: 170px;
    @include merchant-create__text--primary;
}

@mixin merchant-create__input {
    background: #F2F3F3;
    border: 1px solid #C9CFCE;
    border-radius: 10px;
    padding: 14px 16px;
    width: 100%;
    @include merchant-create__text--primary;
}

@mixin merchant-create__input-spilt {
    color: #17948C;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.01em;
    background-color: #F2F3F3;
    border: 1px solid #C9CFCE;
    border-radius: 10px;
    display: flex;
    min-height: 41px;
    width: 100%;

    align-items: center;

    input {
        background-color: #F2F3F3;
        border: none;
        width: 100%;
        margin-left: 16px;
        margin-right: 16px;
        color: #17948C;
    }

    >div:nth-child(1) {
        background-color: #ffffff;
        border-right: 1px solid #C9CFCE;
        box-sizing: border-box;
        border-radius: 10px;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 10px;
    }

}


.merchant-create__title {
    @include merchant-create__h6;
    margin-top: 12px;
}

.merchant-create__main {
    &__navigations {
        margin: 32px 0;
    }
}

.error {
    font-size: 10px;
    line-height: 15px;
    align-items: center;
    letter-spacing: 0.01em;
    color: #EB5757;
    margin-top: 4px;
}

.merchantCreate {
    &__radioButton {

        &__main {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 20px;

            >.title {
                display: flex;
                align-items: center;
                margin-bottom: 0 !important;
            }
        }

        &__content {
            display: flex;
            align-items: center;

            div:nth-child(1) {
                margin-right: 20px;
            }
        }

        &__title {
            display: flex;
            align-items: center;

            >div {
                font-size: 12px;
                line-height: 17px;
                letter-spacing: 0.01em;
                color: #17948C;
                width: 5.5rem;
            }

            svg {
                margin-right: 5px;
                cursor: pointer;
            }
        }
    }

    &__dropdown {
        background: #f2f3f3;
        border: 1px solid #c9cfce;
        border-radius: 10px;
        padding: 14px 16px;
        width: 100%;
        font-size: 12px;
        line-height: 17px;
        letter-spacing: .01em;
        color: #17948c;
        // position: relative;

        &__content {
            position: absolute;
            bottom: -11.3rem;
            max-height: 10.7rem;
            overflow: auto;
            background: #f2f3f3;
            border: 1px solid #c9cfce;
            border-radius: 10px;
            padding: 14px 16px;
            width: 28rem;
            font-size: 12px;
            line-height: 17px;
            letter-spacing: .01em;
            color: #17948c;

            &::-webkit-scrollbar {
                width: 15px;
                background-color: transparent;
            }

            &::-webkit-scrollbar-thumb {
                border: 4px solid rgba(0, 0, 0, 0);
                background-clip: padding-box;
                -webkit-border-radius: 10px;
                background-color: #FAFAFA;
            }

            &::-webkit-scrollbar-button {
                width: 0;
                height: 0;
                display: none;
            }

            &::-webkit-scrollbar-corner {
                background-color: transparent;
            }
        }

        &__title {
            padding-bottom: 5px;
        }
    }
}

/*====================================
    SEPARATOR COMPONENT : MERCHANT INFO
====================================*/
.merchant-create__info {
    display: flex;
    flex-direction: column;

    //forms card
    &__forms {
        background: #FFFFFF;
        border-radius: 10px;
        padding: 32px;

        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(calc(50% - 116px), 1fr));
        // grid-template-columns: repeat(auto-fill, minmax(calc(20% - 13px), 1fr));
        grid-column-gap: 116px;
        margin-bottom: 24px;
    }

    &__button {
        display: flex;
        justify-content: flex-end;

        >button {
            @include merchant-create__btn--primary;
        }
    }
}


//styling each form
.merchant-create__info__form {
    &__name {
        margin-bottom: 32px;

        >div:nth-child(1) {
            @include merchant-create__subtitle;
            margin-bottom: 8px;
        }

        >input {
            @include merchant-create__input;
        }
    }

    &__desc {
        >div:nth-child(1) {
            @include merchant-create__subtitle;
            margin-bottom: 8px;
        }

        >textarea {
            @include merchant-create__textarea;
        }
    }

    &__map {
        margin-bottom: 32px;
        position: relative;

        >div:nth-child(1) {
            @include merchant-create__subtitle;
            margin-bottom: 8px;
        }

        >div:nth-child(2) {
            @include merchant-create__input-spilt;
            margin-bottom: 8px;
        }

        &__suggestion {
            position: absolute;
            z-index: 10;
            width: 100%;
            height: 194px;
            overflow-y: auto;
            max-height: 250px;
            padding: 14px 24px;
            border: 1px solid #C9CFCE;
            border-radius: 10px;
            background-color: #FFFFFF;
            color: #031615;
            font-size: 12px;
            line-height: 17px;
            letter-spacing: 0.01em;

            >span {
                font-weight: 500;
                text-transform: capitalize;
                color: #031615;
                padding-bottom: 12px;
            }

            >li {
                cursor: pointer;
            }

            >li:hover {
                color: #17948C;
            }
        }

        &__display {
            height: 194px;
            width: 100%;
            padding: 2px;
            border: 1px solid #C9CFCE;
            border-radius: 10px;
            margin-bottom: 8px;
        }
    }

    &__lat,
    &__lng {
        margin-bottom: 8px;
        @include merchant-create__input-spilt;
    }

    &__address {
        position: relative;

        >div:nth-child(1) {
            @include merchant-create__subtitle;
            margin-bottom: 8px;
        }

        >textarea {
            @include merchant-create__textarea;
        }

        >select {
            @include merchant-create__input;
        }
    }
}

/*====================================
    SEPARATOR COMPONENT : GALLERY
====================================*/

.merchant-create__gallery {
    display: flex;
    flex-direction: column;

    //forms card
    &__forms {
        background: #FFFFFF;
        border-radius: 10px;
        padding: 32px;

        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(calc(50% - 116px), 1fr));
        grid-column-gap: 66px;
        margin-bottom: 24px;
    }

    &__form {
        &__title {
            margin-bottom: 8px;
            @include merchant-create__subtitle;
        }
    }

    &__button {
        display: flex;
        justify-content: flex-end;

        >button {
            @include merchant-create__btn--primary;
        }
    }

}

.merchant-create__gallery__form__photo {
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
        margin-bottom: 16px;
    }

    span:last-child {
        margin-top: 8px;
        @include merchant-create__caption;
    }

    div {
        display: flex;

        img {
            width: 343px;
            height: 170px;
            border-radius: 10px;
            border: 1px solid #C9CFCE;
            margin-right: 24px;
            object-fit: cover;
        }

        >div {
            justify-content: flex-end;
            display: flex;
            flex-direction: column;
        }
    }

    &__btn--primary {
        @include merchant-create__btn--primary;

        &:not(:last-child) {
            margin-bottom: 12px;
        }
    }

    &__btn--secondary {
        @include merchant-create__btn--secondary;

        &:not(:last-child) {
            margin-bottom: 12px;
        }
    }
}