@mixin label-status__text {
    font-size: 10px;
    line-height: 15px;
    letter-spacing: 0.155844px;
    font-weight: normal;
    white-space: nowrap;
}

.start--unknown {
    @include label-status__text;
    background: #0E5854;
    color: #ffffff;
}

.status--reject {
    @include label-status__text;
    background-color: #A30015;
    color: #ffffff;
}

.status--cancel {
    @include label-status__text;
    background-color: #8F2D56;
    color: #ffffff;
}

.status--pending {
    @include label-status__text;
    background-color: #E0CA3C;
    color: #ffffff;
}

.status--confirmed {
    @include label-status__text;
    background-color: #335C67;
    color: #ffffff;
}

.status--active {
    @include label-status__text;
    //alter for : finished || check-in
    background-color: #46AC89;
    color: #ffffff;
}

.status--expired {
    @include label-status__text;
    background: #495050;
    color: #ffffff;
}