.creditCard{
    margin-top: 16px;

    &__header{
        >h2{
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 24px;
            margin: 12px 0 32px;
        }
    }

    &__body{
        display: flex;
        justify-content: space-between;
        width: 100%;

        &__form{
            background-color: #fff;
            width: 60%;
            padding: 16px 32px;
            border-radius: 10px;
            
            &__fields{
                margin-bottom: 20px;
                >div{
                    width: 100%;    
                                    
                    >span{
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 21px;
                        margin-bottom: 8px;
                    }
                    
                    >input{
                        margin: 10px 0;
                        background: #F2F3F3;
                        border: 1px solid #C9CFCE;
                        box-sizing: border-box;
                        border-radius: 10px;
                        width: 100%;
                        padding: 14px 16px;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 17px;
                        color: #17948C;
                    }
                }
            }

            &__action{
                display: flex;
                align-items: flex-end;
                justify-content: flex-end;

                >button{
                    background: #17948C;
                    border: 1px solid #17948C;
                    box-sizing: border-box;
                    border-radius: 10px;
                    padding: 5px 10px;
                    cursor: pointer;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 24px;
                    letter-spacing: 0.03em;
                    color: #FAFAFA;
                }
            }
        }

        &__results{
            background-color: #fff;
            width: 38%;
            padding: 16px 32px;
            border-radius: 10px;

            >h4{
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 24px;
                letter-spacing: 0.03em;
                color: #000000;
                margin-bottom: 16px;

                padding-bottom: 10px;
                margin-bottom: 16px;
                border-bottom: 1px solid #ddd;
            }
            
            >section{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                padding: 16px 10px;
                border-radius: 10px;
                background-color: #E7F8FE;

                >h3{
                    font-size: 14px;
                    font-weight: 600;
                    text-align: center;
                    color: #127670;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                    width: 600px;
                }
            }

            >div{
                margin-top: 20px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                >button{
                    width: 48%;

                    background: #F2F3F3;
                    border: 1px solid #17948C;
                    box-sizing: border-box;
                    border-radius: 10px;
                    padding: 5px 10px;
                    cursor: pointer;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 24px;
                    letter-spacing: 0.03em;
                    color: #17948C;
                }

                >button:hover{
                    background: #17948C;
                    color: #fff;
                }
            }
        }
    }
}