.containerLogin {
    width: 100%;
    margin: 0!important;
    height: 100vh;
    display: flex;
}

.containerLogin .content {
    width: 57%;
    padding: 80px 200px 100px;
}

.containerLogin .content input, .select-role{
    border-radius: 4px;
    margin: 10px 0;
    padding: 15px 21px;
    border: 1px solid #d1d1d1;
    font-size: 10pt!important;
    width: 100%;
    color: #000;
    padding: 18px 29px;
    margin: 10px 0;
}

.containerLogin .content .select-role{
    padding-right: 15px;
}   

.containerLogin .content select{
    border: none;
    width: 100%;
}   

.containerLogin  .content input:focus {
    outline: none;
    border: 1px solid #66d67b;
}

.containerLogin .content .createAccount span {
    font-size: 14px;
}

.containerLogin .content .createAccount a {
    font-size: 10pt;
    color: #82d173;
    margin-left: 6px;
}

.containerLogin .sideBackground{
    height: 100vh;
    position: fixed;
    height: 100%!important;
    right: 0;
    border: none;
    -webkit-animation: right-to-left .5s;
    animation: right-to-left .5s;
    top: 0;
    width: 43%;
}

.sideBackground .chatIcon {
    position: absolute;
    bottom: 20px;
    height: 75px;
    cursor: pointer;
    z-index: 9999;
    left: -35px;
}





.buttonGrey {
    width: fit-content;
    padding: 12px 35px;
    text-transform: uppercase;
    font-weight: 500;
    background: #e1e1e1;
    color: #fff;
    margin: 14px 0;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    font-weight: 500;
    font-size: inherit;
    /* background: #66d67b; */
}


@media screen and (max-width: 961px){
    .containerLogin .content { 
        width: 100%;
        padding: 80px 40px 100px;
    }
    .containerLogin .sideBackground{
        width: 0;
    }
    .sideBackground .chatIcon {
    left: -100px;
    }
}


