.redeemed {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
}


.redeemed__card {
  border-radius: 10px;
  overflow: hidden;
  // height: 8.5rem;
  box-shadow: 0 0 5px rgb(214, 214, 214);
  display: flex;
  flex-direction: column;

  span {
    background-color: #17948C;
    display: block;
    padding: 0.5rem;
    text-align: center;
    color: #fafafa;
    font-weight: bold;
  }
}

.redeemed__card--active {
  span {
    background-color: #EB5757;
    display: block;
    padding: 0.5rem;
    text-align: center;
    color: #fafafa;
    font-weight: bold;
  }
}

.redeemed__user {
  padding: 0.5rem;
  color: #000;
  font-size: 12px;
  background-color: #fff;
  margin-top: auto;

  div:first-of-type {
    font-weight: bold;
  }
}

.redeemed__available {
  padding: 0.5rem;
  color: #000;
  font-size: 12px;
  background-color: #fff;
  margin-top: auto;
  font-weight: bold;
  height: 100%;
  display: grid;
  place-items: center;
  min-height: 88px;
}