//Transaction for booking
@mixin merchant-detail__transaction-table__text--primary {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.3px;
    color: #031615;
}

@mixin merchant-detail__transaction-table__text--secondary {
    font-weight: normal;
    font-size: 10px;
    line-height: 15px;
    letter-spacing: 0.3px;
    color: #4F4F4F;
}

/*==========================================
      SUB-TAB TRANSACTION : BOOKING
==========================================*/
.merchant-detail__booking {
    &__fitlerd-date {
        display: flex;
        align-items: center;
        padding: 16px 24px;

        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        letter-spacing: 0.181818px;
        color: #031615;

        svg {
            margin-right: 8px;
        }
    }

    >tbody td {
        vertical-align: top;
    }

    &__id {
        @include merchant-detail__transaction-table__text--primary;
    }

    &__date {
        @include merchant-detail__transaction-table__text--primary;
        white-space: nowrap;
    }

    &__session {
        >div {
            display: flex;
            flex-direction: column;

            >span {
                max-width: 150px
            }

            >span:first-child {
                @include merchant-detail__transaction-table__text--primary;
            }

            >span:not(:first-child) {
                @include merchant-detail__transaction-table__text--secondary;
                white-space: nowrap;
            }
        }
    }

    &__user {
        >div {
            display: flex;
            flex-direction: column;

            >span {
                max-width: 175px
            }

            >span:first-child {
                @include merchant-detail__transaction-table__text--primary;
            }

            >span:not(:first-child) {
                word-break: break-all;
                @include merchant-detail__transaction-table__text--secondary;
            }
        }
    }

    &__source {
        @include merchant-detail__transaction-table__text--primary;
    }

    &__type {
        >div {
            display: flex;
            flex-direction: column;

            >span:first-child {
                @include merchant-detail__transaction-table__text--primary;
                white-space: nowrap;
            }

            >span:not(:first-child) {
                @include merchant-detail__transaction-table__text--secondary;
            }
        }


    }

    &__status {
        >div {
            display: flex;
            flex-direction: column;

            >span:first-child {
                padding: 2px 8px;
                border-radius: 10px;
                width: fit-content;
            }

            >span:nth-child(2) {
                margin-top: 8px;
            }

            >span:not(:first-child) {
                @include merchant-detail__transaction-table__text--secondary;
            }
        }
    }

    &__action {
        >div {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;

            span {
                cursor: pointer
            }
        }
    }
}

/*==========================================
      SUB-TAB TRANSACTION : OWN-PACKAGE
==========================================*/
.merchant-detail__own-package {
    >tbody>tr>td {
        vertical-align: top;
    }

    &__id {
        @include merchant-detail__transaction-table__text--primary;
    }

    &__date {
        @include merchant-detail__transaction-table__text--primary;
        white-space: nowrap;
    }

    &__package-name {
        @include merchant-detail__transaction-table__text--primary;
        max-width: 175px;
    }

    &__user-info {
        >div {
            display: flex;
            flex-direction: column;

            >span:first-child {
                @include merchant-detail__transaction-table__text--primary;
            }

            >span:not(:first-child) {
                @include merchant-detail__transaction-table__text--secondary;
            }
        }
    }

    &__status {
        >span {
            padding: 2px 8px;
            border-radius: 10px;
        }
    }

    &__action {
        >div {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;

            span {
                cursor: pointer
            }
        }
    }

}