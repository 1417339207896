.table {
    display: grid;
    row-gap: 6px;
    width: 100%;

    &__header {
        color: #031615;
        font-weight: 500;
        font-size: 10px;
        letter-spacing: 0.181818px;
        margin: auto;
        text-align: center;
        text-transform: uppercase;
        border-bottom: 1px solid #C9CFCE;
        border-top: 1px solid #E4E7E7;
        width: 100%;
        padding: 12px 10px;
    }

    &__body {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.3px;
        color: #031615;
        width: 100%;
        text-align: center;
        border-bottom: 1px solid #E4E7E7;
        padding: 12px 10px;

        &.--title {
            margin: 0;
            text-align: left;
        }

        &.--action {
            position: relative;
        }

        &.empty {
            padding: 0;
        }
    }

}

.css-0 {
    margin: auto;
}

.merchantDetail {
    &__package {
        &.table {
            grid-template-columns: 1fr 4fr 2fr 1fr 1fr 2fr 1fr 1fr 1fr;

            &__loader {
                display: grid;
                grid-template-columns: 1fr 4fr 2fr 1fr 1fr 2fr 1fr 1fr 1fr;
            }
        }
    }

    &__review {
        &.table {
            grid-template-columns: 0.5fr 3fr 1fr 1fr 1fr 4fr 1fr;

            &__loader {
                display: grid;
                grid-template-columns: 0.5fr 3fr 1fr 1fr 1fr 4fr 1fr;
            }
        }
    }

    &__course {
        &.table {
            grid-template-columns: 0.5fr 3fr 3fr 1fr 1fr;

            &__loader {
                display: grid;
                grid-template-columns: 0.5fr 3fr 1fr 1fr;
            }
        }

        > div {
            height: 100%;
        }

        > .table__header {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}