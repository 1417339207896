.createDiscount {
   &__title {
      margin-bottom: 34px;
   }

   &__content {
      background: #FAFAFA;
      border-radius: 10px;
      padding: 16px 24px;
      margin-bottom: 24px;

      &__wrapper {
         display: flex;
         align-items: center;
         width: 100%;

         >div:not(:first-child) {
            margin-left: 50px;
         }
      }

      &__title {
         margin-bottom: 32px;

         &__wrapper {
            display: flex;
            align-items: center;
            margin-bottom: 32px;

            >div:first-child {
               flex: 1;
            }
         }

         &__button {
            display: flex;
            align-items: center;
            background: #17948C;
            border: 1.2px solid #17948C;
            border-radius: 10px;
            padding: 9px 12px;
            cursor: pointer;

            &:hover {
               background: #116d67;
               border: 1.2px solid #116d67;
            }

            >svg {
               path {
                  fill: #FAFAFA;
               }

               circle {
                  stroke: #FAFAFA;
               }
            }

            >button {
               border: none;
               font-style: normal;
               font-weight: 500;
               font-size: 14px;
               line-height: 24px;
               letter-spacing: 0.03em;
               color: #FAFAFA;
               background: transparent;
               cursor: pointer;
            }
         }
      }

      &__subContent {
         width: 100%;

         >svg {
            margin-left: 0;
            margin-top: 1.5rem;
            width: 24px;
            height: 24px;
            cursor: pointer;

            path {
               fill: #17948C;
            }

            &:hover {
               path {
                  fill: #e44d60
               }
            }
         }

         >div:nth-child(1) {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 17px;
            letter-spacing: 0.01em;
            color: #031615;
            margin-bottom: 8px;
         }

         >div:nth-child(3) {
            font-style: normal;
            font-weight: normal;
            font-size: 10px;
            line-height: 15px;
            letter-spacing: 0.207792px;
            color: #495050;
            margin-top: 8px;
            margin-bottom: 24px;
         }

         &__wrapper {
            display: flex;
            align-items: center;
         }

         &__dropdown {
            position: relative;
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: space-between;
            background: #F2F3F3;
            border: 1px solid #C9CFCE;
            box-sizing: border-box;
            border-radius: 10px;
            padding: 14px 16px;
            width: 100%;

            >div {
               font-style: normal;
               font-weight: normal;
               font-size: 12px;
               line-height: 17px;
               letter-spacing: 0.01em;
               color: #17948C;

               >input {
                  border: none;
                  background-color: transparent;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 12px;
                  line-height: 17px;
                  letter-spacing: 0.01em;
                  color: #17948C;

                  &.percentage {
                     width: 1.5rem;
                  }

                  &.notPercentage {
                     margin-left: .5rem;
                  }


                  /* Chrome, Safari, Edge, Opera */
                  &::-webkit-outer-spin-button,
                  &::-webkit-inner-spin-button {
                     -webkit-appearance: none;
                     margin: 0;
                  }

                  /* Firefox */
                  &[type=number] {
                     -moz-appearance: textfield;
                  }

               }
            }

            >svg {
               margin-right: 8px;
               cursor: pointer;

               path {
                  fill: #17948C;
               }
            }
         }

         &__menu {
            display: flex;
            align-items: center;
            background: #FFFFFF;
            border: 1px solid #C9CFCE;
            box-sizing: border-box;
            border-radius: 10px;
            width: fit-content;
            padding: 14px 16px;
            margin-right: 12px;
            color: #17948C;
            cursor: pointer;

            path {
               fill: #17948C;
            }

            >span {
               font-style: normal;
               font-weight: normal;
               font-size: 12px;
               line-height: 17px;
               letter-spacing: 0.01em;
            }

            >svg:not(:first-child) {
               margin-left: 22px;
            }

            &:hover {
               path {
                  fill: #FFFFFF;
               }

               background-color: #17948C;
               color: #FFFFFF;
               border: 1px solid #17948C;
            }
         }
      }
   }

   &__button {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      >button {
         font-style: normal;
         font-weight: 500;
         font-size: 14px;
         line-height: 24px;
         letter-spacing: 0.03em;
         border-radius: 10px;
         border: none;
         padding: 5px 10px;
         border: 1px solid #17948C;
         cursor: pointer;
      }

      >button:first-child {
         color: #17948C;
         margin-right: 16px;

         &:hover {
            color: #FAFAFA;
            background: #e44d60;
            border: 1px solid #e44d60;
         }

      }

      >button:last-child {
         color: #FAFAFA;
         background: #17948C;

         &:hover {
            color: #FAFAFA;
            background-color: #116d67;
            border: 1px solid #116d67;
         }
      }
   }
}

.trash {
   width: fit-content;
}

.modalCreateDiscount {
   position: absolute;
   width: 100%;
   background: #FFFFFF;
   padding: 16px 17px;
   border-radius: 10px;
   border: 1px solid #828282;
   top: 4rem;
   left: 0;
   z-index: 5;

   &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 13px;

      >div {
         font-style: normal;
         font-weight: normal;
         font-size: 10px;
         line-height: 12px;
         letter-spacing: 0.155844px;
      }

      >div:nth-child(1) {
         color: #798685;
      }

      >div:nth-child(2) {
         color: #EB5757;
         cursor: pointer;
      }
   }

   &__search {
      display: flex;
      align-items: center;
      background: #F7F8F8;
      border: 1px solid #828282;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 12px 18px;
      margin-bottom: 8px;

      >input {
         border: none;
         background: transparent;
         width: 100%;
      }
   }

   &__list {
      margin-bottom: 10px;
      max-height: 10rem;
      overflow-y: auto;

      &__content {
         display: flex;
         align-items: center;
         margin-bottom: 9px;

         >div {
            flex: 1;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0.207792px;
            color: #828282;
         }

         >svg {
            margin-right: 11px;
            cursor: pointer;
         }

         &.selectAll {
            >div {
               color: #17948C;
            }
         }
      }
   }

   &__button {
      display: flex;
      justify-content: flex-end;

      >button {
         padding: 5px 10px;
         background: #17948C;
         border: 1px solid #17948C;
         box-sizing: border-box;
         border-radius: 10px;
         font-weight: 500;
         font-size: 14px;
         line-height: 24px;
         letter-spacing: 0.03em;
         color: #FAFAFA;
         cursor: pointer;
      }
   }
}

.modalSelectType {
   position: absolute;
   width: 100%;
   background: #FFFFFF;
   border-radius: 10px;
   border: 1px solid #828282;
   top: 4rem;
   left: 0;
   z-index: 5;

   &__list {
      max-height: 10rem;
      overflow-y: auto;

      >div:not(:last-child) {
         border-bottom: 0.5px solid #C9CECE;
      }

      &__content {
         display: flex;
         align-items: center;
         margin-bottom: 9px;
         padding: 16px 17px;
         color: #D6DADA;

         >div {
            flex: 1;
            cursor: pointer;
         }

         >svg {
            margin-right: 11px;
            cursor: pointer;

            path {
               fill: #17948C;
            }
         }

         &.active {
            color: #17948C;
         }
      }
   }
}