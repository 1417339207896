.promo {
    &__statistic {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
        grid-gap: 16px;
        grid-auto-rows: 124px;
        margin-bottom: 23px;
        margin-top: 16px;

        >div {
            background: #FFFFFF;
            border: 1px solid #E4E7E7;
            border-radius: 10px;
            padding: 14px;

            >span {
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                letter-spacing: 0.12px;
                border-bottom: 1px solid #ddd;

                // text-align: center;
                width: 100%;
                display: block;
                padding-bottom: 6px;
                text-transform: uppercase;
            }

            >h4 {
                margin-top: 16px;
                font-weight: 500;
                font-size: 24px;
                line-height: 36px;
                letter-spacing: 0.1px;
                flex-grow: 1;
                text-align: center;
            }
        }
    }

    &__detail {
        margin-top: 16px;
        display: flex;
        justify-content: space-between;

        >div {
            background: #FFFFFF;
            border: 1px solid #E4E7E7;
            border-radius: 10px;
            padding: 14px;
            width: 50%;
        }

        &__info {
            padding: 20px !important;
            margin-right: 20px;

            >img {
                width: 100%;
                height: 200px;
                background-color: #ddd;
                border-radius: 10px;
            }

            >section {
                border-bottom: 2px solid #ddd;
                padding-bottom: 6px;
                margin-bottom: 16px;

                >h3 {
                    margin-top: 8px;
                    font-size: 18px;
                }

                >div {
                    display: flex;
                    align-items: center;
                    margin-top: 4px;

                    >span {
                        font-size: 12px;
                        margin-right: 20px;

                        >b {
                            color: #17948C;
                        }

                    }
                }
            }

            >p {
                font-size: 12px;
            }

            >div {
                margin-top: 16px;
                width: 100%;
                border: 1px solid #C4C4C4;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 14px;
                border-radius: 10px;

                >span {
                    font-size: 12px;
                    border-bottom: 1px solid #C4C4C4;
                    padding-bottom: 4px;
                    font-weight: 600;
                    width: 100%;
                    margin-bottom: 10px;
                }

                >h4 {
                    width: 100%;
                    background-color: #E7F8FE;
                    font-size: 16px;
                    font-weight: 600;
                    text-align: center;
                    color: #127670;
                    border-radius: 10px;
                    padding: 16px;
                }
            }
        }

        &__terms {
            margin-left: 20px;
            padding: 20px !important;

            >div {
                >a {
                    cursor: pointer;
                }
            }

            >section {
                font-size: 12px;
                padding: 10px;
            }
        }
    }

    &__create {
        margin-top: 16px;
        background: #FFFFFF;
        border: 1px solid #E4E7E7;
        border-radius: 10px;
        padding: 16px;
        width: 60%;

        &__form {
            >section {
                margin-bottom: 16px;

                >span {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 21px;
                    margin-bottom: 8px;
                    display: block;
                }

                >input,
                select {
                    background: #F2F3F3;
                    border: 1px solid #C9CFCE;
                    box-sizing: border-box;
                    border-radius: 10px;
                    width: 100%;
                    padding: 14px 16px;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 17px;
                    color: #17948C;
                }

                // for Image and more
                >section {
                    display: flex;
                    flex-direction: column;

                    >img {
                        width: 343px;
                        height: 170px;
                        border-radius: 10px;
                        border: 1px solid #C9CFCE;
                        object-fit: cover;
                        margin-bottom: 8px;
                    }

                    >button {
                        background: #17948C;
                        border: 1px solid #17948C;
                        border-radius: 10px;
                        padding: 3px 8px;
                        cursor: pointer;
                        font-style: normal;
                        font-size: 12px;
                        color: #FAFAFA;
                        width: 100px;
                    }

                    // for chip
                    >div {
                        display: flex;
                        align-items: center;

                        >button {
                            border: 1px solid #17948C;
                            border-radius: 10px;
                            padding: 3px 10px;
                            cursor: pointer;
                            font-style: normal;
                            font-size: 12px;
                            background: #FAFAFA;
                            color: #17948C;
                            margin-right: 10px;

                            display: flex;
                            align-items: center;
                            justify-content: center;

                            &.--active {
                                background: #17948C;
                                color: #FAFAFA;
                            }

                            >svg {
                                height: 14px;
                                width: 14px;
                                margin-left: 6px;
                            }
                        }
                    }
                }

                >.error {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 10px;
                    line-height: 17px;
                    letter-spacing: 0.01em;
                    color: #EB5757;
                }
            }
        }

        &__action {
            margin-top: 16px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding-top: 10px;
            border-top: 1px solid #ddd;

            >button {
                background: #17948C;
                border: 1px solid #17948C;
                box-sizing: border-box;
                border-radius: 10px;
                padding: 5px 10px;
                cursor: pointer;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                letter-spacing: 0.03em;
                color: #FAFAFA;
            }
        }

        &__actionExport {
            margin-top: 16px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 10px;
            border-top: 1px solid #ddd;

            >button {
                background: #17948C;
                border: 1px solid #17948C;
                box-sizing: border-box;
                border-radius: 10px;
                padding: 5px 10px;
                cursor: pointer;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                letter-spacing: 0.03em;
                color: #FAFAFA;
            }
        }
    }

    >.tableDashboard__content {
        >.tableDashboard__content__navigation {
            >a {
                cursor: pointer;
            }
        }
    }

    .promoList {
        width: 100%;
        background-color: #fff;

        >div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #ddd;
        }
    }
}

.listVoucher__input {
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 20px;
}