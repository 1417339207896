.multi-select {
   margin-bottom: 1rem;

   input {
      margin-top: 0.5rem;
      border: 1px solid #C9CFCE;
      border-bottom: none;
      border-radius: 10px 10px 0 0;
      padding: 0.3rem 0.5rem;
   }
}

.multi-select__header {
   display: flex;
   flex-direction: column;

   font-style: bold;
   font-weight: 500;
   font-size: 14px;
   line-height: 21px;
}

.multi__select--active {
   display: flex;
   align-items: center;
   justify-content: center;
   background: #17948C;
   border: 1px solid #17948C;
   border-radius: 10px;
   padding: 5px 10px;
   cursor: pointer;
   font-style: normal;
   font-size: 10px;
   color: #FAFAFA;
   width: 100%;

   &:hover {
      border: 1px solid #EB5757;
      background-color: #EB5757;
   }
}

.multi__select--inactive {
   display: flex;
   align-items: center;
   justify-content: center;
   background: #17948C;
   border: 1px solid #17948C;
   border-radius: 10px;
   padding: 5px 10px;
   cursor: pointer;
   font-style: normal;
   font-size: 10px;
   color: #FAFAFA;
   width: 100%;

   // &:hover {
   //    border: 1px solid #EB5757;
   //    background-color: #EB5757;
   // }
}

.multi-select__selected {
   display: grid;
   grid-template-columns: repeat(3, 1fr);
   column-gap: 0.5rem;
   row-gap: 0.5rem;
}

.multi-select__data {
   border: 1px solid #C9CFCE;
   border-radius: 0 0 10px 10px;
   padding: 0.3rem 0.5rem;

   max-height: 7rem;
   overflow: auto;
}

.multi-select__item {
   font-style: normal;
   font-weight: normal;
   font-size: 12px;
   line-height: 17px;
   padding: 0.3rem 0;
   cursor: pointer;
}