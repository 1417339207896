.merchantDoofood {
   &__title {
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 48px;
      letter-spacing: 0.01em;
      color: #000000;
      margin: 5px 0 8px;
   }

   &__stats {
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 15px;
      letter-spacing: 0.155844px;
      color: #828282;

      >span {
         font-style: normal;
         font-weight: normal;
         font-size: 10px;
         line-height: 15px;
         letter-spacing: 0.155844px;
         color: #66D67B;
         margin: 0 5px;
      }
   }

   &__statistics {
      >span {
         margin-bottom: 7px;
      }

      >div {
         >div {
            display: flex;
            align-items: center;
            justify-content: space-between;

            >div {
               font-weight: 500;
               font-size: 14px;
               line-height: 21px;
               letter-spacing: 0.12px;
               color: #1C1C1C;
            }
         }
      }
   }

   &__action {
      display: flex;
      align-items: center;

      &__title {
         flex: 1;
         font-style: normal;
         font-weight: 500;
         font-size: 14px;
         line-height: 21px;
         letter-spacing: 0.3px;
         color: #1C1C1C;
      }

      &__button {
         >a {
            >button {
               background: #17948C;
               border: 1.2px solid #17948C;
               box-sizing: border-box;
               border-radius: 10px;
               font-style: normal;
               font-weight: 500;
               font-size: 14px;
               line-height: 24px;
               text-align: center;
               letter-spacing: 0.03em;
               color: #FAFAFA;
               padding: 4px 8px;
               margin-left: 16px;
               cursor: pointer;

               &:hover {
                  background: #127972;
                  border: 1.2px solid #127972;
               }
            }
         }
      }
   }

   &__list {
      background: #FFFFFF;
      border-radius: 10px;
      padding: 16px 10px;
      margin-top: 17px;

      &__search__user {
         position: relative;
         float: right;

         background: #F7F8F8;
         border: 1px solid #C9CFCE;
         box-sizing: border-box;
         border-radius: 10px;
         width: 20%;

         display: flex;
         align-items: center;

         input {
            width: 100%;
            flex: 1;
            background: none;
            border: none;
            padding: 12px 18px;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 17px;
            display: flex;
            align-items: center;
            letter-spacing: 0.01em;
            color: #17948C;

            &::placeholder {
               color: #17948C;
            }
         }

         >svg {
            position: absolute;
            right: 1rem;

            path {
               fill: #828282
            }
         }
      }
   }

   &__content {
      margin-top: 10px;

      >div {
         display: grid;
         grid-template-columns: repeat(4, minmax(0, 1fr));
         grid-gap: 10px;
         align-items: center;
         flex-wrap: wrap;
         align-content: stretch;
         width: 100%;
         @media screen and (max-width: 1200px) {
            grid-template-columns: repeat(3, minmax(0, 1fr));
         }
         @media screen and (max-width: 992px) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
         }
         @media screen and (max-width: 768px) {
            grid-template-columns: repeat(1, minmax(0, 1fr));
         }
      }

      &__cards {
         display: flex;
         background: #FFFFFF;
         border: 1px solid #BDBDBD;
         box-sizing: border-box;
         border-radius: 10px;
         padding: 14px 10px;
         overflow: hidden;
         &:hover {
            background-color: rgba($color: #000000, $alpha: 0.1);
            cursor: pointer;
         }

         &__img {
            margin-right: 16px;

            >img {
               border-radius: 10px;
               width: 101px;
               height: 101px;
               object-fit: cover;
            }
         }

         &__title {
            >.merchantName {
               font-style: normal;
               font-weight: 600;
               font-size: 14px;
               line-height: 14px;
               letter-spacing: 0.03em;
               color: #333333;
               margin-bottom: 13px;
            }

            >.merchantGoal {
               display: flex;
               align-items: center;

               >.title {
                  font-style: normal;
                  font-weight: normal;
                  font-size: 12px;
                  line-height: 17px;
                  letter-spacing: 0.01em;
                  color: #495050;
                  margin-bottom: 10px;

                  &:after {
                     content: ",\00A0"
                  }

                  &:last-of-type:after {
                     content: ""
                  }
               }

            }

            >.merchantRating {
               display: flex;
               align-items: center;
               margin-bottom: 8px;

               >div:nth-child(1) {
                  display: flex;
                  align-items: center;

                  >img {
                     height: 16px;
                  }

                  margin-right: 16px;
               }

               >div:nth-child(2),
               >div:nth-child(3) {
                  font-style: normal;
                  font-weight: normal;
                  font-size: 12px;
                  line-height: 17px;
                  letter-spacing: 0.01em;
                  color: #17948C;
                  margin-right: 5px;
               }
            }

            >.merchantCity {
               display: flex;
               align-items: center;

               >div {
                  font-style: normal;
                  font-weight: normal;
                  font-size: 12px;
                  line-height: 17px;
                  letter-spacing: 0.01em;
                  color: #333333;
                  opacity: 0.5;
                  white-space: nowrap;
                  overflow: hidden;
                  // text-overflow: ellipsis;

                  &:after {
                     content: ",\00A0"
                  }

                  &:last-of-type:after {
                     content: ""
                  }
               }
            }
         }
      }
   }
}