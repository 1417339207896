* {
    box-sizing: border-box;
    margin: 0px;
    font-family: 'Poppins', sans-serif;
}

*:focus {
    outline: none;
}


a {
    text-decoration: none;
    color: inherit;
}

input {
    margin: 0;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 500;
}

h1 {
    font-size: 2.5rem;
}

h2 {
    font-size: 2rem;
}

h3 {
    font-size: 1.75rem;
}

h4 {
    font-size: 1.5rem;
}

h5 {
    font-size: 1.25rem;
}

h6 {
    font-size: 1rem;
}