/*=====================================
        DETAIL MEMBERSHIP     
=====================================*/
.membershipDetail {
    &__title {
        font-weight: 600;
        font-size: 24px;
        line-height: 48px;
        letter-spacing: 0.01em;

        >span:nth-child(1) {
            color: #000000;
        }

        >span:nth-child(2) {
            color: #AFB6B6;
        }
    }
}

//container of card collection 
.membershipDetail__cards {
    display: flex;
    flex-wrap: wrap;
    margin-top: 9px;

    >div:not(:last-child) {
        margin-right: 14px
    }
}

.membershipDetail__card {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border: 1px solid #E4E7E7;
    border-radius: 10px;
    min-height: 132px;
    min-width: 220px;
    padding: 12px;
    margin-top: 9px;

    //title of card
    &__card-title {
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 0.12px;
        color: #1C1C1C;
        margin-bottom: 10px;
    }

    >div:first-child {
        display: flex;
        flex-direction: column;
    }

    &--link {
        @extend .membershipDetail__card;
        justify-content: space-between;
        flex-direction: row;

        >img:last-child {
            margin-left: 12px;
        }

    }
}

//styling each card
.membershipDetail__card {
    &__age> :nth-child(2) {
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        letter-spacing: 0.1px;
        color: #1C1C1C;
    }

    &__bookings>:nth-child(2) {

        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        letter-spacing: 0.1px;
        color: #1C1C1C;
        margin-bottom: auto;
    }

    &__invoice> :nth-child(2) {
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        letter-spacing: 0.1px;
        color: #1C1C1C;
    }

    &__extra-fee {
        >:nth-child(2) {
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            letter-spacing: 0.1px;
            color: #1C1C1C;
            margin-bottom: auto;
        }

        >:nth-child(3) {
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0.155844px;

            >span:nth-child(odd) {
                color: #66D67B;
            }

            >span:nth-child(even) {
                color: #828282;
            }
        }
    }
}


/*=====================================
    DETAIL MEMBERSHIP -> TAB : INFO PLAN    
=====================================*/
.membershipInfo__table {
    &__title {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.12987px;
        color: #333333;
    }

    &__content {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.3px;
        color: #798685;
    }

    tr>td {
        min-width: 234px;
        padding-bottom: 32px;
        vertical-align: top;
    }

    tr>td>div:first-child {
        margin-bottom: 4px;
        font-weight: 500;
        font-size: 12px;
    }

    &__data {
        min-width: 220px !important;
    }

    &__status {
        cursor: pointer;
        width: fit-content;

        &--loading {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 100px;
            width: fit-content;
            padding: 2px 8px;
            border-radius: 8px;
            border: 1px solid #E4E7E7;
        }

        span {
            display: flex;
            align-items: center;
            justify-content: space-between;
            min-width: 100px;
            width: fit-content;
            padding: 2px 8px;
            border-radius: 8px;
            font-size: 10px;
            line-height: 15px;
            border: 1px solid #E4E7E7;
            color: #FFFFFF;
        }

        span:after {
            content: url('../../../../assets/images/icon/arrow-down.svg');
        }

        &__pulldown {
            position: absolute;
            margin-top: 4px;
            min-width: 100px;
            width: fit-content;
            padding: 6px;
            background: #FFFFFF;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
            border-radius: 5px;
            font-size: 12px;
            line-height: 14px;


            >div {
                // padding-bottom: 12px;
                padding: 4px;
                border-radius: 3px;
                cursor: pointer;
            }

            >div:hover {
                background-color: rgba(128, 128, 128, 0.3);
            }

            &--active {
                color: #17948C;
            }

            &--suspend {
                color: #031615;
            }

            &--archive {
                color: #EB5757;
            }
        }
    }
}


.membershipUserInfo {
    display: flex;
    align-items: center;

    &__avatar {
        clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
        height: 98px;
        width: 98px;
        object-fit: cover;
        border-radius: 12px;
        margin-right: 24px;
    }

    &__table {
        tr>td>div:nth-child(1) {
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            color: #333333;
            padding-bottom: 4px;
        }

        tr>td>div:nth-child(2) {
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.3px;
            color: #798685;
        }

        tr:not(:last-child)>td>div:nth-child(2) {
            padding-bottom: 24px;
        }
    }
}

.membershipDetail {

    .membershipDetail__navigation {
        border-bottom: 1px solid #E4E7E7;
        margin-bottom: 16px;

        >div {
            cursor: pointer;
        }
    }

    .membershipTypes {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.155844px;

        color: #FFFFFF;
        background: #EDAE0F;
        width: fit-content;
        border-radius: 8px;
        padding: 2px 8px;
    }

    .statusMembership {
        display: flex;
        align-items: center;

        background: #46AC89;
        border-radius: 8px;
        padding: 2px 8px;

        .title {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 18px;
            text-align: center;
            letter-spacing: 0.155844px;
            color: #FFFFFF;
        }

        svg {
            path {
                fill: #FFFFFF;
            }
        }
    }
}


/*=====================================
    DETAIL MEMBERSHIP -> TAB : BOOKING
=====================================*/
.membership-bookings__filter {
    display: flex;
    flex-direction: column;
    padding: 20px 14px;

    &__date {
        display: flex;
        align-items: center;

        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        letter-spacing: 0.181818px;
        color: #031615;

        >div {
            display: flex;
            align-items: center;
            margin-right: auto;
        }

        svg {
            margin-right: 8px;
        }
    }

    &__button-filter {
        background: #17948C;
        color: #FFFFFF;
        padding: 4px 8px;
        border-radius: 10px;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.342857px;
        text-align: center;
        border: none;
        outline: none;
    }

    &__date-state {
        padding: 24px 0;
    }

}

.membership-bookings {
    tbody td {
        vertical-align: top;
    }
}

//styling each column on membership-booking
.membership-bookings {
    &__id {
        @include listInformation__text--primary;
    }

    &__create {
        @include listInformation__text--primary;
        white-space: nowrap;
    }

    &__merchant {
        >div {
            display: flex;
            flex-direction: column;
            text-align: left;

            >span:nth-child(1) {
                @include listInformation__text--primary;
            }

            >div {
                margin: 0 !important;
            }

        }

    }

    &__class {
        >div {
            display: flex;
            flex-direction: column;
            text-align: left;

            >span:nth-child(1) {
                @include listInformation__text--primary;
            }

            >span:nth-child(2) {
                @include listInformation__text--secondary;
            }

            >div>span {
                @include listInformation__text--secondary;
            }
        }
    }

    &__extra-fee {
        >div {
            display: flex;
            flex-direction: column;

            >span:nth-child(1) {
                @include listInformation__text--primary;
            }

            >span:nth-child(2) {
                @include listInformation__text--secondary;
            }
        }
    }

    &__source {
        @include listInformation__text--primary;
    }

    &__extra-fee {
        >div {
            display: flex;
            flex-direction: column;

            >span:nth-child(1) {
                @include listInformation__text--primary;
            }

            >span:nth-child(2) {
                @include listInformation__text--secondary;
            }
        }
    }

    &__status {
        >span {
            padding: 2px 8px;
            border-radius: 8px;
            color: #FFFFFF;
        }
    }
}

/*=====================================
    DETAIL MEMBERSHIP -> TAB : INVOICES
=====================================*/
.membership-invoice {
    tbody td {
        vertical-align: top;
    }
}

//each coloumn of table invoices
.membership-invoice {
    &__id {
        @include listInformation__text--primary;
    }

    &__issued-date {
        @include listInformation__text--primary;
        white-space: nowrap;
    }

    &__due-date {
        @include listInformation__text--primary;
        white-space: nowrap;
    }

    &__amont {
        @include listInformation__text--primary;
        white-space: nowrap;
    }

    &__extra-fee {
        >* {
            white-space: nowrap;
        }

        >div {
            @include listInformation__text--primary;
        }

        >span {
            @include listInformation__text--secondary;
        }
    }

    &__status {
        >span {
            border-radius: 10px;
            padding: 2px 8px;
            color: #FFFFFF;
        }
    }

    &__action {
        flex-direction: column;
    }
}