/*========================================================
    CARD SECTION -> Section that only have one card
========================================================*/
.bigCardSection {
    background-color: #ffffff;
    border-radius: 10px;
}


.twoCards {
    display: flex;
    align-items: center;
    width: 100%;

    // flex-wrap: wrap;
    >div:not(:first-child) {
        margin-left: 50px;
    }

    >div {
        min-height: 25rem;
        width: 100%;
        // flex-basis: 40%;
    }
}

.oneCard {
    width: 100%;
}

.secondColumn {
    margin-top: 16px;
}