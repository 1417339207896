.createClass {
   &__title {
      font-weight: 500;
       font-size: 24px;
       line-height: 24px;
       letter-spacing: 0.03em;
       color: #000000;
       margin-top: 12px;
       margin-bottom: 32px;
   }

   &__content {
      border-radius: 10px;
      margin-bottom: 24px;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
      justify-content: space-between;

      &__wrapper {
         display: flex;
         /* align-items: center; */
         width: 100%;

         &__cards
         {
            background: #FFFFFF;
            border-radius: 10px;
            padding: 35px;
            min-width:450px;

            >.cards
            {
               display: -webkit-flex;
               display: flex;
               -webkit-justify-content: space-between;
               justify-content: space-between;

               >.card
               {
                  -webkit-flex-basis: 100%;
                  -flex-basis: 100%;

                  >.wrapper
                  {
                     margin-bottom:1em;

                     >.title
                     {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 21px;
                        letter-spacing: 0.0709534px;
                        color: #000000;
                        margin-bottom: 8px;
                     }

                     >.validation
                     {
                        display: -webkit-flex;
                        display: flex;
                        -webkit-align-items: center;
                        align-items: center;
                        margin-bottom: 16px;

                        >input
                        {
                           background: #F2F3F3;
                           border: 1px solid #C9CFCE;
                           box-sizing: border-box;
                           border-radius: 10px;
                           padding: 14px 16px;
                           width: 100%;
                           margin-right: 12px;
                           font-style: normal;
                           font-weight: normal;
                           font-size: 12px;
                           line-height: 17px;
                           letter-spacing: 0.01em;
                           color: #17948C;
                        }
                     }

                     >.dropdown
                     {
                        background: #f2f3f3;
                        border: 1px solid #c9cfce;
                        border-radius: 10px;
                        padding: 14px 16px;
                        width: 100%;
                        font-size: 12px;
                        line-height: 17px;
                        letter-spacing: .01em;
                        color: #17948c;
                        margin-bottom: 20px;

                        select
                        {
                           width: 100%;
                           border: none;
                           background-color: transparent;
                        }
                     }

                     >textarea
                     {
                        background: #F2F3F3;
                        border: 1px solid #C9CFCE;
                        border-radius: 10px;
                        padding: 14px 20px;
                        width: 100%;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 17px;
                        letter-spacing: 0.01em;
                        color: #17948C;
                     }

                     >.inputContent
                     {
                        display: -webkit-flex;
                        display: flex;
                        -webkit-align-items: center;
                        align-items: center;
                        border-radius: 10px;
                        margin-bottom: 32px;
                        background: #F2F3F3;
                        border: 1px solid #C9CFCE;
                        box-sizing: border-box;

                        >.title
                        {
                           font-style: normal;
                           font-weight: bold;
                           font-size: 12px;
                           line-height: 17px;
                           padding: 13px 12px;
                           background: #FFFFFF;
                           border: 1px solid #C9CFCE;
                           box-sizing: border-box;
                           border-radius: 10px;
                        }

                        >input
                        {
                           font-style: normal;
                           font-weight: normal;
                           font-size: 12px;
                           line-height: 17px;
                           margin-left: 16px;
                           border: none;
                           background: none;
                           height: 100%;
                           width: 100%;
                        }
                     }
                  }
               }
            }

            >.action
            {
               display: -webkit-flex;
               display: flex;
               -webkit-justify-content: flex-end;
               justify-content: flex-end;

               button {
                  border: 1px solid #17948C;
                  box-sizing: border-box;
                  border-radius: 10px;
                  padding: 5px 10px;
                  margin-left: 16px;
                  margin-top: 24px;
                  cursor: pointer;

                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 24px;

                  &:nth-child(1) {
                     color: #17948C;
                  }

                  &:nth-child(2) {
                     color: #FAFAFA;
                     background: #17948C;
                  }
               }
            }
         }
      }

      &__title {
         margin-bottom: 32px;

         &__wrapper {
            display: flex;
            align-items: center;
            margin-bottom: 32px;

            >div:first-child {
               flex: 1;
            }
         }

         &__button {
            display: flex;
            align-items: center;
            background: #17948C;
            border: 1.2px solid #17948C;
            border-radius: 10px;
            padding: 9px 12px;
            cursor: pointer;

            &:hover {
               background: #116d67;
               border: 1.2px solid #116d67;
            }

            >svg {
               path {
                  fill: #FAFAFA;
               }

               circle {
                  stroke: #FAFAFA;
               }
            }

            >button {
               border: none;
               font-style: normal;
               font-weight: 500;
               font-size: 14px;
               line-height: 24px;
               letter-spacing: 0.03em;
               color: #FAFAFA;
               background: transparent;
               cursor: pointer;
            }
         }
      }

      &__subContent {
         width: 100%;

         >svg {
            margin-left: 0;
            margin-top: 1.5rem;
            width: 24px;
            height: 24px;
            cursor: pointer;

            path {
               fill: #17948C;
            }

            &:hover {
               path {
                  fill: #e44d60
               }
            }
         }

         >div:nth-child(1) {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 17px;
            letter-spacing: 0.01em;
            color: #031615;
            margin-bottom: 8px;
         }

         >div:nth-child(3) {
            font-style: normal;
            font-weight: normal;
            font-size: 10px;
            line-height: 15px;
            letter-spacing: 0.207792px;
            color: #495050;
            margin-top: 8px;
            margin-bottom: 24px;
         }

         &__wrapper {
            display: flex;
            align-items: center;
         }

         &__dropdown {
            position: relative;
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: space-between;
            background: #F2F3F3;
            border: 1px solid #C9CFCE;
            box-sizing: border-box;
            border-radius: 10px;
            padding: 14px 16px;
            width: 100%;

            >div {
               font-style: normal;
               font-weight: normal;
               font-size: 12px;
               line-height: 17px;
               letter-spacing: 0.01em;
               color: #17948C;

               >input {
                  border: none;
                  background-color: transparent;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 12px;
                  line-height: 17px;
                  letter-spacing: 0.01em;
                  color: #17948C;

                  &.percentage {
                     width: 1.5rem;
                  }

                  &.notPercentage {
                     margin-left: .5rem;
                  }


                  /* Chrome, Safari, Edge, Opera */
                  &::-webkit-outer-spin-button,
                  &::-webkit-inner-spin-button {
                     -webkit-appearance: none;
                     margin: 0;
                  }

                  /* Firefox */
                  &[type=number] {
                     -moz-appearance: textfield;
                  }

               }
            }

            >svg {
               margin-right: 8px;
               cursor: pointer;

               path {
                  fill: #17948C;
               }
            }
         }

         &__menu {
            display: flex;
            align-items: center;
            background: #FFFFFF;
            border: 1px solid #C9CFCE;
            box-sizing: border-box;
            border-radius: 10px;
            width: fit-content;
            padding: 14px 16px;
            margin-right: 12px;
            color: #17948C;
            cursor: pointer;

            path {
               fill: #17948C;
            }

            >span {
               font-style: normal;
               font-weight: normal;
               font-size: 12px;
               line-height: 17px;
               letter-spacing: 0.01em;
            }

            >svg:not(:first-child) {
               margin-left: 22px;
            }

            &:hover {
               path {
                  fill: #FFFFFF;
               }

               background-color: #17948C;
               color: #FFFFFF;
               border: 1px solid #17948C;
            }
         }
      }
   }

   &__button {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      >button {
         font-style: normal;
         font-weight: 500;
         font-size: 14px;
         line-height: 24px;
         letter-spacing: 0.03em;
         border-radius: 10px;
         border: none;
         padding: 5px 10px;
         border: 1px solid #17948C;
         cursor: pointer;
      }

      >button:first-child {
         color: #17948C;
         margin-right: 16px;

         &:hover {
            color: #FAFAFA;
            background: #e44d60;
            border: 1px solid #e44d60;
         }

      }

      >button:last-child {
         color: #FAFAFA;
         background: #17948C;

         &:hover {
            color: #FAFAFA;
            background-color: #116d67;
            border: 1px solid #116d67;
         }
      }
   }
}