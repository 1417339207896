.flexCenter {
  display: flex;
  align-items: center;
}

.radio-button {
  cursor: pointer;
}

.radio-button--circle {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: 1px solid #000;
  background-color: transparent;
  margin-right: 0.5rem;
}

.radio-button--active {
  background-color: #fafafa;
  position: relative;

  &::after {
    content: '';
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 50%;
    background-color: #17948C;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
  }
}

.radio-button--text {
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: #000;
}

.radio-button__btn {
  &:not(:last-of-type) {
    margin-right: 1rem;
  }
}