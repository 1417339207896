.tabTransaction{
   .searchTables{
      display: flex;
      align-items: center;
      
      background: #FFFFFF;
      border: 1px solid #C9CFCE;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 10px 18px;
      width: 20rem;
      >input{
         background: none;
         border: none;
         width: 100%;
         flex: 1;

         font-style: normal;
         font-weight: normal;
         font-size: 12px;
         line-height: 17px;
         letter-spacing: 0.01em;
         color: #17948C;
         &::placeholder{
            color: #17948C;
         }
      }
      >svg{
         // margin-right: 18px;
         path{fill: #828282;}
      }
   }
   .typeGold{
      background: #EDAE0F;
      border-radius: 8px;
      padding: 2px 8px;

      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 15px;
      letter-spacing: 0.155844px;
      color: #FFFFFF;
   }
   .typeNormal{
      @extend .typeGold;
      background: #17948C;
   }
}