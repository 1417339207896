@mixin alert--container {
  @media (max-width: 767px) {
      max-width: 300px;
      font-size: 12px;
      line-height: 22px;
      letter-spacing: 0.03em;
  }

  @media (min-width: 768px) {
      min-width: 400px;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.03em;
  }
}

.alert-toaster {
  @include alert--container();
  padding: 16px;
  background: #FFFFFF;
  border-radius: 10px;
  background: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2), 0px 2px 20px rgba(0, 0, 0, 0.1);
  position: relative;

  &__content {
      padding-right: 24px;
      display: flex;
      flex: 1;

      >img {
          width: 61px;
          height: 61px;
          margin-right: 32px;
          align-self: center;
      }

      //alert-toaster__content__desc--
      >div {
          display: flex;
          flex-direction: column;
          span {
            margin-bottom: 8px;
          }
      }
  }

  >svg {
      position: absolute;
      top: 16px;
      right: 16px;
      cursor: pointer;
  }
}

.alert-toaster__content__desc--info {
  >span {
      color: #031615;
  }

  b {
      color: #031615;
  }
}

.alert-toaster__content__desc--success {

  >span {
      color: #17948C;
  }

  b {
      color: #031615;
  }
}

.alert-toaster__content__desc--warning,
.alert-toaster__content__desc--failed {

  >span {
      color: #EB5757;
  }

  >b {
      color: #031615;
  }


}

.alert-button {
  cursor: pointer;
  background: none;
  border: 1px solid #cacaca;
  padding: 8px ;
  border-radius: 8px;
  color: #484848 !important;
  text-decoration: none;
  font-weight: 600;
  text-align: center;
  margin-top: 16px;
  display: block;
  width: 100%;
}