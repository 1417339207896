.CalendarDay {
	padding: 5px;
}



// Styling only for <SingleDatePicker/>
.SingleDatePicker_1 {
	width: 100%;
}

.SingleDatePickerInput {
	border: none;
	background-color: transparent;
	margin: 0;
	flex-grow: 1;
	width: 100% !important;


	.DateInput_1 {
		background-color: transparent;
		margin: 0;
		width: 100% !important;
	}

	.DateInput_input {
		cursor: pointer !important;
		background-color: transparent;
		padding: 0;

		font-weight: 500;
		font-size: 14px;
		line-height: 21px;
		letter-spacing: 0.181818px;
		color: #031615;
		text-align: left;
	}
}

// Styling only for <DateRangePicker/>
.DateRangePickerInput {
	background: none;
	border: none;

	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	color: #031615;

	//remove arrow right and change with text 'TO'
	.DateRangePickerInput_arrow {
		&:after {
			content: "TO";
			margin-right: 10px;
		}

		svg {
			display: none;
		}
	}

	.DateInput {
		background: none;

		&:first-child {
			margin-right: 10px;
		}
	}

	.DateInput_input {
		background-color: white;
		border: 1px solid #828282;
		border-radius: 8px;
		padding: 5px 8px;

		font-weight: 500;
		font-size: 14px;
		line-height: 21px;
		text-align: center;
		letter-spacing: 0.181818px;
		color: #031615;
		text-align: center;
	}
}