.tabs__merchant {
   justify-content: space-between;
}

.detailFood {
   padding: 13px 18px;
}

.merchantDoofoodDetail {
   display: flex;
   margin-top: 33px;

   &__info {
      background: #FFFFFF;
      border: 1px solid #E4E7E7;
      border-radius: 10px;
      display: flex;
      max-height: 282px;
      width: 60%;
      height: 282px;
   }

   &__img {
      >img {
         border-radius: 10px 0px 0px 10px;
         width: 282px;
         height: 282px;
         object-fit: cover;
      }
   }

   &__detail {
      position: relative;
      padding: 16px;
      overflow-y: auto;

      &.packageDetail {
         width: 100%;
      }

      &::-webkit-scrollbar {
         width: 15px;
         background-color: transparent;
      }

      &::-webkit-scrollbar-corner {
         background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
         border: 4px solid rgba(0, 0, 0, 0);
         background-clip: padding-box;
         -webkit-border-radius: 10px;
         background-color: #E4E7E7;
      }

      &__wrapper {
         display: flex;
         justify-content: space-between;
         margin-bottom: 14px;
      }

      >svg {
         position: absolute;
         top: 45%;
         right: 0;
      }

      &__card {
         flex-basis: 50%;
         padding-right: 10px;

         &.cardPackageDetail {
            flex-basis: 80%;
         }

         >div:nth-child(1) {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0.12987px;
            color: #333333;
            width: 100%;
         }

         >span {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.3px;
            color: #798685;
         }

         .goals {
            display: flex;
            margin-top: 8px;

            >button {
               background: #FAFAFA;
               border: 1px solid #17948C;
               border-radius: 8px;
               font-style: normal;
               font-weight: normal;
               font-size: 10px;
               line-height: 15px;
               letter-spacing: 0.155844px;
               color: #17948C;
               padding: 2px 8px;
               margin-right: 8px;
               margin-bottom: 8px;

               &:hover {
                  background: #17948C;
                  color: #FAFAFA;
                  cursor: pointer;
               }
            }
         }

         .coverageArea {
            button:not(:last-child) {
               background: #FAFAFA;
               border: 1px solid #17948C;
               border-radius: 8px;
               font-style: normal;
               font-weight: normal;
               font-size: 10px;
               line-height: 15px;
               letter-spacing: 0.155844px;
               color: #17948C;
               padding: 2px 8px;
               margin-right: 8px;
               margin-bottom: 8px;

               &:hover {
                  background: #17948C;
                  color: #FAFAFA;
                  cursor: pointer;
               }
            }

            button:last-child {
               background: #17948C;
               border: 1px solid #17948C;
               border-radius: 8px;
               font-style: normal;
               font-weight: normal;
               font-size: 10px;
               line-height: 15px;
               letter-spacing: 0.155844px;
               color: #FBFEFE;
               padding: 2px 8px;

               &:hover {
                  background: #147c75;
                  cursor: pointer;
               }
            }
         }
      }
   }

   &__stats {
      display: flex;
      flex-wrap: wrap;

      >div:nth-child(1) {
         margin: 0 3px 0 3px;
      }

      >div:nth-child(3) {
         margin: 3px 3px 0 3px;
      }

      >div:nth-child(4) {
         margin-top: 3px;
      }

      &__card {
         display: flex;
         flex-direction: column;
         justify-content: flex-start;
         flex-basis: 49%;
         width: 8.3rem;
         background: #FFFFFF;
         border: 1px solid #E4E7E7;
         border-radius: 10px;
         padding: 12px 12px;
         height: 138px;

         >.title {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.12px;
            color: #1C1C1C;
         }

         >.info {
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            letter-spacing: 0.1px;
            color: #1C1C1C;
            margin-top: 10px;
         }

         >.month {
            display: flex;
            align-items: center;
            background-color: #FAFAFA;
            border: 1px solid #17948C;
            border-radius: 8px;
            // padding: 0 8px;
            padding: 0 40px 0 8px;
            // width: 96px;
            width: fit-content;
            cursor: pointer;

            >.title {
               font-style: normal;
               font-weight: normal;
               font-size: 10px;
               line-height: 15px;
               display: flex;
               align-items: center;
               text-align: center;
               letter-spacing: 0.155844px;
               color: #17948C;
               flex: 1;
            }

            >svg {
               path {
                  fill: #17948C;
               }

               width: 16px;
            }
         }

         >.status {
            display: flex;
            align-items: center;
            margin-top: auto;

            >.number {
               font-style: normal;
               font-weight: normal;
               font-size: 12px;
               line-height: 18px;
               letter-spacing: 0.155844px;
               color: #EB5757;
               margin-right: 8px;
            }

            >.title {
               font-style: normal;
               font-weight: normal;
               font-size: 12px;
               line-height: 18px;
               letter-spacing: 0.155844px;
               color: #828282;
            }

            >div {
               display: flex;
               align-items: center;

               >.title {
                  font-style: normal;
                  font-weight: normal;
                  font-size: 10px;
                  line-height: 15px;
                  letter-spacing: 0.155844px;
                  color: #828282;
               }

               >.number {
                  font-style: normal;
                  font-weight: normal;
                  font-size: 10px;
                  line-height: 15px;
                  letter-spacing: 0.155844px;
                  color: #66D67B;
               }
            }
         }

         >.stars {
            display: flex;
            align-items: center;
            margin-top: 10px;

            >.title {
               font-style: normal;
               font-weight: 500;
               font-size: 24px;
               line-height: 36px;
               letter-spacing: 0.1px;
               color: #1C1C1C;
               margin: 0 6px;
            }

            >.number {
               font-style: normal;
               font-weight: 500;
               font-size: 24px;
               line-height: 36px;
               letter-spacing: 0.1px;
               color: #AFB6B6;
            }
         }

         >.content {
            >.media {
               display: flex;
               align-items: center;
               margin-top: 7px;

               >.title {
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 21px;
                  letter-spacing: 0.12px;
                  color: #1C1C1C;
                  margin-left: 11px;
               }
            }
         }

         >.source {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.1px;
            color: #1C1C1C;

            display: flex;
            align-items: center;
            margin-top: 10px;

            >span {
               font-style: normal;
               font-weight: 500;
               font-size: 24px;
               line-height: 36px;
               letter-spacing: 0.1px;
               color: #AFB6B6;
               margin-right: 8px;
            }
         }
      }
   }
}

.detailBatch {
   &__button {
      margin-left: auto;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.03em;
      color: #FAFAFA;

      padding: 5px 24px;
      background: #17948C;
      border: 1.2px solid #17948C;
      box-sizing: border-box;
      border-radius: 10px;

      &:hover {
         background: #12746d;
         cursor: pointer;
      }
   }
}

.renderTabs {
   margin-bottom: 19px;
}