/* ==========================================
              MODAL CONTAINER
==========================================*/
.modalContainer {
  position: absolute;
}

.modalOverley {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: hsla(100, 4%, 15%, 0.185);
  z-index: 99;
}

.modalOverleyCenter {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: hsla(100, 4%, 15%, 0.185);
  z-index: 99;
}

/*========================================
            MODAL RIGHT SIDE
========================================**/
.modalRight {
  position: fixed;
  right: 0;
  min-height: 100vh;
  height: 100vh;
  width: 800px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  >div {
    padding: 28px;
  }

  >div:last-child {
    flex-grow: 1;
  }

  >div:nth-child(1) {
    background-color: #262825;
    color: #fff;
  }

  >div:nth-child(2) {
    background-color: #F6F6F6;
  }

  >div:nth-child(3) {
    background-color: #1F7899;
  }

  @media only screen and (max-width : 961px) {
    width: 90vw;
    left: 5vw;
    top: 90px;
    min-height: 85vh;
    height: 85vh;
    overflow-y: auto;
  }
}



/*========================================
            MODAL FILTER
========================================**/

.modalFilter {
  width: 700px;
  @media screen and (max-width: 700px) {
    width: 100%;
  }

  .header {
    background: #FFFFFF;
    border: 1px solid #FAFAFA;
    border-radius: 10px 10px 0px 0px;
    padding: 10px;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .title {
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
      text-align: center;
      letter-spacing: 0.181818px;
      color: #000000;
    }

    svg {
      position: absolute;
      cursor: pointer;
      right: .5rem;
    }
  }

  .container {
    width: 100%;
    min-height: 300px;
    max-height: 80vh;
    overflow-x: auto;
    background: #E4E7E7;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1), 0px 0px 4px rgba(0, 0, 0, 0.15);
    border-radius: 0 0 10px 10px;
    padding: 24px 28px;

    &::-webkit-scrollbar {
      width: 15px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      -webkit-border-radius: 10px;
      background-color: #FAFAFA;
    }

    &::-webkit-scrollbar-button {
      width: 0;
      height: 0;
      display: none;
    }

    &::-webkit-scrollbar-corner {
      background-color: transparent;
    }

    .alignSelf {
      align-items: flex-start !important;
    }

    >.content {

      >.title {
        text-align: center;
        margin-bottom: 1rem;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 27px;
        letter-spacing: 0.181818px;
        color: #000000;
      }
    }

    .wrapper {
      display: flex;
      align-items: center;
      padding-bottom: 19px;
      width: 100%;


      >.title {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 27px;
        letter-spacing: 0.181818px;
        color: #000000;
        flex: 1;
        margin-right: 1rem;
      }

      span {
        margin: 0 7px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.181818px;
        color: #000000;
      }

      .date {
        display: flex;
        align-items: center;
        width: 23.5%;

        background: #FFFFFF;
        border: 1px solid #C9CFCE;
        box-sizing: border-box;
        border-radius: 10px;

        .title {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;
          align-items: center;
          letter-spacing: 0.01em;
          color: #17948C;
          padding: 14px 8px 14px 4px;
          border-left: 1px solid #C9CFCE;
        }

        svg {
          margin: 0 10px;

          path {
            fill: #17948C;
          }
        }
      }

      .text {
        background: #FFFFFF;
        border: 1px solid #C9CFCE;
        box-sizing: border-box;
        border-radius: 10px;
        padding: 14px;
        width: 500px;

        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 17px;
        display: flex;
        align-items: center;
        letter-spacing: 0.01em;
        color: #17948C;

        input {
          border: none;
          background: transparent;
          width: 100%;

          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 17px;
          display: flex;
          align-items: center;
          letter-spacing: 0.01em;
          color: #17948C;
        }
      }

      .dropdown {
        @extend .text;
        cursor: pointer;

        svg {
          margin-left: auto;

          path {
            fill: #17948C;
          }
        }
      }

      .radioButtonContainer {
        .radioButton {
          display: flex;
          align-items: center;

          .radioWrapper {
            display: flex;
            align-items: center;

            svg {
              cursor: pointer;
            }

            .title {
              font-style: normal;
              font-weight: 500;
              font-size: 13px;
              line-height: 19px;
              letter-spacing: 0.415584px;
              color: #626262;
              margin-left: 8px;
              padding-right: 24px;
            }
          }
        }

        .radioButtonContent {
          display: flex;
          align-items: center;
          margin-top: 24px;

          .time {
            display: flex;
            align-items: center;

            background: #FFFFFF;
            border: 1px solid #C9CFCE;
            box-sizing: border-box;
            border-radius: 10px;

            >.title {
              border-radius: 10px 0 0 10px;
              background: #F2F3F3;
              font-style: normal;
              font-weight: normal;
              font-size: 13px;
              line-height: 19px;
              display: flex;
              align-items: center;
              letter-spacing: 0.415584px;
              color: #17948C;
              padding: 11px 9px;
              border-right: 1px solid #C9CFCE;
            }

            svg {
              margin: 0 15px;
            }
          }
        }
      }
    }

    .action {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      button {
        border: 1px solid #17948C;
        box-sizing: border-box;
        border-radius: 10px;
        padding: 10px 25px;
        margin-left: 12px;
        cursor: pointer;

        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 17px;
        align-items: center;
        letter-spacing: 0.01em;
        color: #17948C;
        background: #FAFAFA;

        &:last-of-type {
          color: #FAFAFA;
          background: #17948C;
        }
      }
    }
  }
}

.filterStatusModal {

  &__dropdown {
    display: flex;
    align-items: center;
    justify-content: space-between;

    position: relative;
    background: #FFFFFF;
    border: 1px solid #C9CFCE;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 10px;
    width: 50%;

    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #17948C;

    svg {
      path {
        fill: #17948C;
      }
    }
  }

  &__option {
    position: absolute;
    top: 3.5rem;
    left: 0;
    background: #FFFFFF;
    border: 1px solid #C9CFCE;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 10px;
    width: 100%;
    max-height: 9.5rem;
    overflow-x: auto;
    cursor: pointer;

    &::-webkit-scrollbar {
      width: 15px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      -webkit-border-radius: 10px;
      background-color: #FAFAFA;
    }

    &::-webkit-scrollbar-button {
      width: 0;
      height: 0;
      display: none;
    }

    &::-webkit-scrollbar-corner {
      background-color: transparent;
    }

    >div {
      padding: 5px;
    }
  }
}

.modal {
  position: absolute;
}

.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: hsla(100, 4%, 15%, 0.185);
  backdrop-filter: blur(3px);
  z-index: 99999;
}