@mixin batch__form-input {
    background: #F2F3F3;
    border: 1px solid #C9CFCE;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 14px 16px;
    width: 100%;
    max-width: 100%;

    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 17px;

    &::placeholder {
        color: #17948C;
    }
}

@mixin batch__form-input-split {
    color: #17948C;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.01em;
    background-color: #F2F3F3;
    border: 1px solid #C9CFCE;
    border-radius: 10px;
    display: flex;
    min-height: 44px;
    width: 100%;

    align-items: center;

    input {
        background-color: #F2F3F3;
        border: none;
        width: 100%;
        margin-left: 16px;
        margin-right: 16px;
        color: #17948C;
    }

    >div:nth-child(1) {
        background-color: #ffffff;
        border-right: 1px solid #C9CFCE;
        box-sizing: border-box;
        border-radius: 10px;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 10px;
    }
}

@mixin batch__text-subheader {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 8px;
}

.create-batch {
    display: flex;
    width: 50%;
    flex-direction: column;
    padding: 13px 20px 90px;


    &__title {
        font-weight: 500;
        font-size: 24px;
        line-height: 24px;
        margin: 12px 0 32px;
    }

}

.create-batch__form-container {
    display: flex;
    flex-direction: column;
}

.create-batch__form-input {
    padding: 32px;
    margin-bottom: 24px;

    >div:not(:first-child) {
        margin-top: 16px;
    }

    &--error {
        font-size: 11px;
        line-height: 15px;
        letter-spacing: 0.01em;
        color: #EB5757;
        margin-top: 4px;
    }

    &__package {
        >div:nth-child(1) {
            @include batch__text-subheader()
        }
    }

    &__desc {
        >div:nth-child(1) {
            @include batch__text-subheader()
        }

        >textarea {
            @include batch__form-input()
        }
    }

    &__date {

        >div:nth-child(1) {
            @include batch__text-subheader()
        }

        .DateInput_input {
            background: #F2F3F3;
            border: 1px solid #C9CFCE;
            font-size: 12px;
            line-height: 17px;
        }
    }

    &__promo {
        >div:nth-child(1) {
            @include batch__text-subheader()
        }
    }

    &__price {
        >div:nth-child(1) {
            @include batch__text-subheader()
        }

        &__form--split {
            @include batch__form-input-split()
        }
    }

    &__sustanance {
        >div:nth-child(1) {
            @include batch__text-subheader()
        }
    }
}

.create-batch__form-submit {
    background: #17948C;
    border: 1.2px solid #17948C;
    border-radius: 10px;
    color: #FFF;
    cursor: pointer;
    padding: 5px 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    white-space: nowrap;
    align-self: flex-end;

}