/*====================================
            BUTTON SIZE
====================================*/
.buttonOntableStretch {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-radius: 5px;
    min-width: 52px;
    width: fit-content;

    height: 32px;
    font-size: 14px;
    padding: 0px 4px;

}

.buttonOntableForIcon {
    @extend .buttonOntableStretch;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
}

.buttonOntable {
    @extend .buttonOntableStretch;
    width: 100px;
}

.cursorPointer {
    cursor: pointer;
}

/*====================================
            BUTTON COLOR
====================================*/
.buttonYellow {
    background-color: #FFCC60;
}

.buttonRed {
    background-color: #D0021B;
}

.buttonGreen {
    background-color: rgb(102, 214, 123);
}

.buttonWhite {
    background-color: #fff;
    border: 1px solid #D3D3D3;
}

/*====================================
            BUTTON V2
====================================*/

.btn-sml {
    cursor: pointer;
    white-space: nowrap;
    min-width: 79px;
    height: 34px;
    border-radius: 10px;
}

.btn-sml--primary {
    @extend .btn-sml;
    border: 1px solid #17948C;
    background-color: #fff;
    color: #17948C;
}

.btn-sml--success {
    @extend .btn-sml;
    border: none;
    background-color: #17948C;
    color: #fff;
}

.exports {
    display: flex;
    justify-content: flex-end;

    button {
        background: #17948C;
        border: 1.2px solid #17948C;
        box-sizing: border-box;
        border-radius: 10px;
        padding: 6px 32px;
        margin-bottom: 18px;
        cursor: pointer;

        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.03em;
        color: #FAFAFA;
    }
}

/*====================================
            BUTTON V3
====================================*/
.btn {
    padding: 0.5rem 1.25rem;
    background-color: #ececec;
    color: #666666;
    font-weight: 500;
    border-radius: 8px;
    border: 1px solid #bbbbbb;
    cursor: pointer;

    &.btn-main {
        background-color: #17948C;
        border: 1px solid #17948C;
        color: white;
        
        &:hover {
            background: #12837b;
            border: 1px solid #12837b;
        }
    }

    &.btn-red {
        background-color: #D0021B;
        border: 1px solid #D0021B;
        color: white;
        
        &:hover {
            background: #b30015;
            border: 1px solid #b30015;
        }
    }

    &.btn-white {
        background-color: white;
        border: 1px solid white;
        
        &:hover {
            background: #ececec;
            border: 1px solid #ececec;
        }
    }
}

.btn-reset {
    background-color: transparent;
    border: none;
    padding: 5px;
    cursor: pointer;
}
