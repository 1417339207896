// ---- offer list mixin
@mixin carousel-table--primary-text {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.3px;
    color: #031615;
}

@mixin carousel-table--secondary-text {
    font-size: 10px;
    line-height: 15px;
    letter-spacing: 0.3px;
    color: #4F4F4F;
}

// ---- offer form mixin

@mixin carousel-form__text--primary($color : null) {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.0709534px;

    @if($color) {
        color: $color;
    }

    @else {
        color: #000000;

    }
}

@mixin carousel-form__text--subtitle {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.3px;
    color: #000000;
}

@mixin carousel-form__text--secondary {
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #17948C;
}

@mixin carousel-form__input($width : null, $backgroundColor : null) {
    @include carousel-form__text--secondary;

    border: 1px solid #C9CFCE;
    border-radius: 10px;
    padding: 14px 16px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @if($width) {
        width: $width;
    }

    @else {
        width: 100%;
    }

    @if $backgroundColor {
        background-color: $backgroundColor;
    }

    @else {
        background-color: #F2F3F3;

    }
}

@mixin carousel-form__input--split($backgroundColor : null) {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 45px;
    border: 1px solid #C9CFCE;
    border-radius: 10px;

    @if $backgroundColor {
        background-color: $backgroundColor;
    }

    @else {
        background-color: #F2F3F3;
    }

    input {
        @include merchant-create__text--primary;
        background-color: inherit;
        color: #17948C;
        border: none;
        width: 100%;
        margin-left: 16px;
        margin-right: 16px;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    >div:nth-child(1) {
        display: flex;
        align-items: center;
        // height: 100%;
        height: inherit;
        padding: 10px;
        background-color: #ffffff;
        border-right: 1px solid #C9CFCE;
        border-radius: 10px;

        svg {
            width: 20px;
            height: 20px;

            path {
                fill: #358B9D;
            }
        }
    }
}

@mixin carousel-form__textarea {
    background-color: #F2F3F3;
    border: 1px solid #C9CFCE;
    border-radius: 10px;
    padding: 14px 16px;
    width: 100%;
    max-width: 100%;
    min-height: 170px;
    @include carousel-form__text--secondary;
}

/*=========================================
            CAROUSEL LIST
=========================================*/
.carousel {
    display: flex;
    flex-direction: column;

    &__title {
        font-weight: 600;
        font-size: 24px;
        line-height: 48px;
        letter-spacing: 0.01em;
        color: #000000;
        margin-bottom: 9px;
    }
}

.carousel__statistic {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(calc(25% - 10px), 1fr));
    grid-gap: 10px;
    grid-auto-rows: 182px;
    margin-bottom: 23px;

    >div {
        background: #FFFFFF;
        border: 1px solid #E4E7E7;
        border-radius: 10px;
        padding: 14px;

        display: flex;
        flex-direction: column;

        >span:nth-child(1) {
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.5px;
            color: #000000;
            margin-bottom: 37px;
        }

        >span:nth-child(2) {
            font-weight: 500;
            font-size: 36px;
            line-height: 54px;
            letter-spacing: 0.5px;
            color: #000000;
        }

        >span:nth-child(3) {
            font-weight: 500;
            font-size: 12px;
            line-height: 21px;
            letter-spacing: 0.155844px;
            color: #828282;
            flex-grow: 1;
            display: flex;
            align-items: flex-end;
        }
    }
}

.carousel__button-create {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    >span {
        @include carousel-table--primary-text;
    }

    >button {
        padding: 8px 33px;
        margin: 0 10px;
        border-radius: 10px;
        background-color: #17948C;
        border: 1.2px solid #17948C;
        font-weight: 500;
        font-size: 14px;
        color: #FAFAFA;
        cursor: pointer;
    }
}

.carousel__table__list {
    >tbody td {
        vertical-align: top;
    }

    &__id {
        @include carousel-table--primary-text;
    }

    &__title {
        >div {
            display: flex;
            flex-direction: column;

            >span:nth-child(1) {
                @include carousel-table--primary-text;
                margin-bottom: 6px;
            }

            >span:nth-child(2) {
                @include carousel-table--secondary-text;
            }
        }
    }

    &__category {
        @include carousel-table--primary-text;
    }

    &__status {
        @include carousel-table--primary-text;
    }

    &__action {}
}

/*========================================
            CREATE CAROUSE
========================================*/

.carousel-create {
    position: relative;
    display: flex;
    flex-direction: column;

    &__title {
        font-weight: 600;
        font-size: 24px;
        line-height: 48px;
        letter-spacing: 0.01em;
        color: #000000;
        margin-bottom: 21px;
    }

    &__forms {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(calc(50% - 63px), 1fr));
        grid-column-gap: 63px;
    }

    &__form {
        background: #FFFFFF;
        border: 1px solid #E4E7E7;
        border-radius: 10px;
        padding: 24px;
    }

    &__submit {
        display: flex;
        justify-content: flex-end;

        >button {
            @include carousel-form__text--primary(#fff);
            margin-top: 43px;
            cursor: pointer;
            background: #17948C;
            border: 1px solid #17948C;
            box-sizing: border-box;
            border-radius: 10px;
            padding: 5px 10px;
        }
    }
}

.carousel-create__form {
    &__status {
        margin-bottom: 16px;

        >div:first-child {
            @include carousel-table--primary-text;
            margin-bottom: 8px;
        }
    }

    &__featured {
        margin-bottom: 16px;

        >div:first-child {
            @include carousel-table--primary-text;
            margin-bottom: 8px;
        }
    }

    &__category {
        margin-bottom: 16px;

        >div:first-child {
            @include carousel-form__text--primary;
            margin-bottom: 8px;
        }

        >div:nth-child(2) {
            display: flex;

            >* {
                margin-right: 36px;
            }
        }
    }

    &__banner {
        margin-bottom: 16px;

        >div:first-child {
            @include carousel-form__text--primary;
            margin-bottom: 8px;
        }

        >span {
            margin-top: 8px;
            font-weight: 500;
            font-size: 10px;
            line-height: 15px;
            letter-spacing: 0.415584px;
            color: #626262;
        }

        &--filled,
        &--empty {
            width: 320px;
            height: 123px;
            border: 1px solid #C9CFCE;
            border-radius: 17px;
        }

        &--empty {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            >span {
                margin-top: 8px;
                @include carousel-form__text--secondary;
            }

            svg {
                cursor: pointer;
                width: 58px;
                height: 58px;
            }
        }

        &--filled {
            position: relative;

            svg {
                box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.75);
                border-radius: 50%;
                cursor: pointer;
                position: absolute;
                top: 12px;
                right: 12px;
            }

            >img {
                width: calc(100% - 1px);
                height: calc(100% - 1px);
                border-radius: inherit;
                object-fit: cover;
            }


        }
    }

    &__thumbnail {
        margin-bottom: 16px;

        >div:first-child {
            @include carousel-form__text--primary;
            margin-bottom: 8px;
        }

        >span {
            margin-top: 8px;
            font-weight: 500;
            font-size: 10px;
            line-height: 15px;
            letter-spacing: 0.415584px;
            color: #626262;
        }

        &--filled,
        &--empty {
            width: 163px;
            height: 163px;
            border: 1px solid #C9CFCE;
            border-radius: 17px;
        }

        &--empty {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            >span {
                margin-top: 8px;
                @include carousel-form__text--secondary;
            }

            svg {
                cursor: pointer;
                width: 58px;
                height: 58px;
            }
        }

        &--filled {
            position: relative;

            svg {
                box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.75);
                border-radius: 50%;
                cursor: pointer;
                position: absolute;
                top: 12px;
                right: 12px;
            }

            >img {
                width: calc(100% - 1px);
                height: calc(100% - 1px);
                border-radius: inherit;
                object-fit: cover;
            }


        }
    }

    &__link-type {
        margin-bottom: 16px;

        >div:nth-child(1) {
            margin-bottom: 8px;
            @include carousel-form__text--primary;
        }

        >div:nth-child(2) {
            display: flex;
            margin-bottom: 8px;

            >* {
                margin-right: 36px;
            }
        }

        >input {
            @include carousel-form__input;
        }
    }

    &__flight {
        >div:first-child {
            @include carousel-form__text--primary;
            margin-bottom: 8px;
        }

        >div:nth-child(2) {
            svg {
                width: 24px;
                height: 24px;
                margin-right: 8px;
            }

            display: flex;
            align-items: center;
        }
    }

    &__status {}

    &__fetured {}
}