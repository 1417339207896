@mixin facilities-table--primary-text {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.3px;
    color: #031615;
}

@mixin facilities-table--secondary-text {
    font-size: 10px;
    line-height: 15px;
    letter-spacing: 0.3px;
    color: #4F4F4F;
}

.facilities {
    display: flex;
    flex-direction: column;

    &__title {
        font-weight: 600;
        font-size: 24px;
        line-height: 48px;
        letter-spacing: 0.01em;
        color: #000000;
        margin-bottom: 9px;
    }
}

.facilities__statistic {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(calc(25% - 10px), 1fr));
    grid-gap: 10px;
    grid-auto-rows: 182px;
    margin-bottom: 23px;

    >div {
        background: #FFFFFF;
        border: 1px solid #E4E7E7;
        border-radius: 10px;
        padding: 14px;

        display: flex;
        flex-direction: column;

        >span:nth-child(1) {
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.5px;
            color: #000000;
            margin-bottom: 37px;
        }

        >span:nth-child(2) {
            font-weight: 500;
            font-size: 36px;
            line-height: 54px;
            letter-spacing: 0.5px;
            color: #000000;
        }

        >span:nth-child(3) {
            font-weight: 500;
            font-size: 12px;
            line-height: 21px;
            letter-spacing: 0.155844px;
            color: #828282;
            flex-grow: 1;
            display: flex;
            align-items: flex-end;
        }
    }
}

.facilities__button-create {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    >span {
        @include facilities-table--primary-text;
    }

    >button {
        padding: 8px 33px;
        margin: 0 10px;
        border-radius: 10px;
        background-color: #17948C;
        border: 1.2px solid #17948C;
        font-weight: 500;
        font-size: 14px;
        color: #FAFAFA;
        cursor: pointer;
    }
}

.facilities__table__list {
    >tbody td {
        vertical-align: top;
    }

    &__id {
        @include facilities-table--primary-text;
    }



    &__facilities-type,
    &__facilities-name {
        @include facilities-table--primary-text;

    }


    &__facilities-icon {
        >div {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            border: 1px solid #C4C4C4;

            >img {
                height: 35px;
            }
        }

    }

    &__status {}

    &__action {}
}