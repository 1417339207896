.merchantPackageDetailFit {
   padding: 13px 22px 13px;

   .header {
      display: flex;
      align-items: center;

      .title {
         font-style: normal;
         font-weight: 600;
         font-size: 24px;
         line-height: 48px;
         letter-spacing: 0.01em;
         color: #000000;
      }

      .action {
         display: flex;
         align-items: center;
         margin-left: auto;
         position: relative;

         background: #46AC89;
         border-radius: 8px;
         padding: 2px 8px;
         cursor: pointer;

         .renderAction {
            position: absolute;
            right: 0;
            top: 2rem;
            z-index: 3;
            min-width: 170px;

            background: #FFFFFF;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
            border-radius: 5px;
            padding: 16px;

            .active,
            .suspend,
            .archive {
               font-style: normal;
               font-weight: normal;
               font-size: 12px;
               line-height: 14px;
               letter-spacing: 0.155844px;

               padding-bottom: 16px;
            }

            .active {
               color: #17948C;
            }

            .archive {
               color: #EB5757;
            }
         }

         .title {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.155844px;
            color: #FFFFFF;
         }

         svg {
            path {
               fill: #FFFFFF;
            }
         }
      }

   }

   .progression {
      display: flex;

      .wrapper {
         width: 50%;

         >.title {
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            letter-spacing: 0.1px;
            color: #1C1C1C;
         }

         >.info {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 17px;
            letter-spacing: 0.01em;
            color: #031615;
         }
      }
   }

   span {
      color: #AFB6B6;
   }
}

.greenVenue {
   width: 18px;
   height: 18px;
   background: #27AE60;
   border-radius: 100%;
   display: flex;
   margin: auto;
}

.blueVenue {
   width: 18px;
   height: 18px;
   background: #2F80ED;
   border-radius: 100%;
   margin: auto;
}