///////////////////////////////////////////////
/////////////////CREATE PACKAGE////////////////
///////////////////////////////////////////////

.create-package-food {
   padding: 13px 20px 90px;
   display: flex;
   flex-direction: column;

   >.title {
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 24px;

      margin: 12px 0 32px;
   }

   &__cards {
      display: flex;
      justify-content: space-between;
      margin-bottom: 2rem;

      &__new__content,
      &__preview {
         background: #FFFFFF;
         border-radius: 10px;
      }

      &__new {
         &__content {
            padding: 31px 32px;
            width: 524px;

            .content {
               margin-bottom: 32px;
               >.title {
                  font-style: normal;
                  font-weight: 500;
                  font-size: 12px;
                  line-height: 17px;
                  align-items: center;
                  margin-bottom: 8px;
               }

               >.textAreaContentStyle {
                  max-width: 100%;
                  background: #F2F3F3;
                  border: 1px solid #C9CFCE;
                  box-sizing: border-box;
                  border-radius: 10px;
                  padding: 14px 16px;
                  width: 100%;
                  margin-bottom: 24px;

                  font-style: normal;
                  font-weight: normal;
                  font-size: 12px;
                  line-height: 17px;

                  &::placeholder {
                     color: #17948C;
                  }
               }

               >.inputContentStyle {
                  background: #F2F3F3;
                  border: 1px solid #C9CFCE;
                  box-sizing: border-box;
                  border-radius: 10px;
                  padding: 14px 16px;
                  width: 100%;

                  font-style: normal;
                  font-weight: normal;
                  font-size: 12px;
                  line-height: 17px;

                  &::placeholder {
                     color: #17948C;
                  }

                  &.error {
                     border: 1px solid #FF0000;
                  }
               }

               >.add-image {
                  display: flex;
                  justify-content: center;
                  width: 100%;

                  cursor: pointer;
                  border: 1px solid #17948C;
                  box-sizing: border-box;
                  border-radius: 10px;
                  background-color: transparent;
                  padding: 10px;
                  margin-top: 32px;

                  font-size: 12px;
                  line-height: 17px;
                  letter-spacing: 0.01em;
                  color: #17948C;

                  >div {
                     margin-left: 8px;
                  }
               }

               >.categories {
                  display: flex;
                  align-items: center;
                  max-width: 24rem;
                  flex-wrap: wrap;
                  height: 100%;

                  >.category {
                     display: flex;
                     align-items: center;
                     background: #FFFFFF;
                     border: 1px solid #17948C;
                     box-sizing: border-box;
                     border-radius: 22px;
                     padding: 8px 12px;
                     margin-right: 12px;
                     margin-bottom: 12px;

                     .title {
                        flex: 1;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 17px;
                     }

                     >i {
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                     }

                     svg {
                        width: 16px;
                        margin-left: 10px;
                        cursor: pointer;

                        path {
                           fill: #EB5757;
                        }
                     }
                  }
               }

               >.dropdown {
                  display: flex;
                  align-items: center;
                  width: 100%;
                  padding: 14px 16px;
                  margin-top: 7px;
                  margin-bottom: 32px;

                  background: #F2F3F3;
                  border: 1px solid #C9CFCE;
                  box-sizing: border-box;
                  border-radius: 10px;

                  >.title {
                     font-style: normal;
                     font-weight: normal;
                     font-size: 12px;
                     line-height: 17px;
                     flex: 1;
                  }

                  >svg {
                     width: 24px;

                     path {
                        fill: #17948C;
                     }

                     cursor: pointer;
                  }
               }

               >.inputContent {
                  display: flex;
                  align-items: center;
                  border-radius: 10px;

                  background: #F2F3F3;
                  border: 1px solid #C9CFCE;
                  box-sizing: border-box;
                  
                  &.error {
                     border: 1px solid #FF0000;
                  }

                  >.title {
                     font-style: normal;
                     font-weight: bold;
                     font-size: 12px;
                     line-height: 17px;

                     padding: 13px 12px;
                     background: #FFFFFF;
                     border: 1px solid #C9CFCE;
                     box-sizing: border-box;
                     border-radius: 10px;
                  }

                  >input {
                     font-style: normal;
                     font-weight: normal;
                     font-size: 12px;
                     line-height: 17px;
                     margin-left: 16px;
                     border: none;
                     background: none;
                     height: 100%;

                     &::placeholder {
                        color: #17948C;
                     }
                  }
               }

               >.imgContent {
                  display: flex;

                  img {
                     width: 343px;
                     height: 170px;
                     border-radius: 10px;

                     object-fit: cover;

                     box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1), 0px 0px 4px rgba(0, 0, 0, 0.15);
                     background-color: white;

                  }

                  >.actions {
                     display: flex;
                     flex-direction: column;
                     justify-content: flex-end;
                     margin-left: 24px;

                     >button {
                        margin-top: 12px;
                        padding: 5px 10px;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 24px;
                        white-space: nowrap;

                        border: 1px solid #17948C;
                        box-sizing: border-box;
                        border-radius: 10px;
                        cursor: pointer;

                        &:nth-child(1) {
                           color: #FAFAFA;
                           background: #17948C;
                        }

                        &:nth-child(2) {
                           color: #17948C;
                        }
                     }
                  }

                  >.actionsConsultation {
                     display: flex;
                     flex-direction: column;
                     justify-content: flex-end;
                     margin-left: 24px;

                     >button {
                        margin-top: 12px;
                        padding: 5px 10px;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 24px;
                        white-space: nowrap;

                        border: 1px solid #17948C;
                        box-sizing: border-box;
                        cursor: pointer;

                        &:nth-child(1) {
                           color: #FAFAFA;
                           background: #17948C;
                        }

                        &:nth-child(2) {
                           color: #17948C;
                        }
                     }
                  }
               }

               >.sub-title {
                  font-style: normal;
                  font-weight: 500;
                  font-size: 10px;
                  line-height: 15px;
               }
            }
         }

         >.action {
            display: flex;
            justify-content: flex-end;

            button {
               border: 1px solid #17948C;
               box-sizing: border-box;
               border-radius: 10px;
               padding: 5px 10px;
               margin-left: 16px;
               margin-top: 24px;
               cursor: pointer;

               font-style: normal;
               font-weight: 500;
               font-size: 14px;
               line-height: 24px;

               &:nth-child(1) {
                  color: #17948C;
               }

               &:nth-child(2) {
                  color: #FAFAFA;
                  background: #17948C;
               }
            }
         }
      }

      &__preview {
         padding: 37px 75px;
         width: 524px;
         height: 70rem;

         >.title {
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 24px;
            margin-bottom: 35px;
         }

         >.packagePreview {
            display: flex;
            background: #FFFFFF;
            box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1), 0px 0px 4px rgba(0, 0, 0, 0.15);
            border-radius: 10px;
            max-height: 133px;

            >.img {
               img {
                  border-radius: 10px 0px 0px 10px;
                  width: 133px;
                  height: 133px;
                  object-fit: cover;
               }
            }

            >.packageInfo {
               display: flex;
               flex-direction: column;
               padding: 14px 15px;

               >.title {
                  font-style: normal;
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 14px;
               }

               >.desc {
                  font-style: normal;
                  font-weight: normal;
                  font-size: 12px;
                  line-height: 17px;
                  margin-top: 13px;
               }

               >.price {
                  margin-top: auto;
                  font-style: normal;
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 14px;

                  >span {
                     font-weight: normal;
                     color: #828282;
                  }
               }
            }
         }

         >.mobilePreview {
            margin-top: 72px;
            position: relative;

            >.previewImage {
               box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1), 0px 0px 4px rgba(0, 0, 0, 0.15);

               .slider-images {
                  width: 100%;
                  height: 290px;
                  object-fit: cover;
               }

               >.action {
                  position: absolute;
                  padding: 20px 16px;
                  display: flex;
                  width: 100%;

                  >svg {
                     path {
                        fill: #FFFFFF;
                     }

                     &:nth-child(1) {
                        margin-right: auto;
                     }

                     &:nth-child(3) {
                        margin-left: 24px;
                     }
                  }
               }
            }

            >.info {
               background: #FFFFFF;
               border-radius: 20px 20px 0px 0px;
               padding: 20px 16px;
               position: absolute;
               top: 15rem;
               box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1), 0px 0px 4px rgba(0, 0, 0, 0.15);


               >.price {
                  font-style: normal;
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 14px;

                  >span {
                     font-weight: normal;
                     color: #828282;
                  }
               }

               >.title {
                  margin-top: 26px;
                  font-style: normal;
                  font-weight: 600;
                  font-size: 21px;
                  line-height: 24px;
               }

               >.location {
                  display: flex;
                  align-items: center;
                  margin-top: 12px;

                  >.title {
                     font-style: normal;
                     font-weight: 500;
                     font-size: 14px;
                     line-height: 19px;
                     margin-left: 12px;
                  }
               }

               >.dates {
                  display: flex;
                  align-items: center;
                  padding-bottom: 24px;
                  border-bottom: 1px solid #C4C4C4;

                  >.date {
                     display: flex;
                     align-items: center;
                     background: #FFFFFF;

                     border: 1px solid #17948C;
                     box-sizing: border-box;
                     border-radius: 20px;
                     padding: 13px 16px;
                     margin-right: 15px;
                     margin-top: 25px;

                     >.title {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 17px;
                        margin-left: 10px;
                        white-space: nowrap;
                     }

                     >svg {
                        width: 15.75px;
                        height: 18px;

                        path {
                           fill: #17948C;
                        }
                     }
                  }
               }

               >.desc {
                  >.title {
                     font-style: normal;
                     font-weight: 500;
                     font-size: 14px;
                     line-height: 19px;
                     margin-top: 22px;
                  }

                  >.sub-title {
                     font-style: normal;
                     font-weight: normal;
                     font-size: 12px;
                     line-height: 16px;
                     color: #495050;
                     margin-top: 16px;
                  }

                  >.action {
                     display: flex;
                     justify-content: center;

                     >button {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 16px;

                        border: none;
                        background: none;
                        color: #17948C;
                        margin-top: 8px;
                     }
                  }
               }

               >.package {
                  >.title {
                     font-style: normal;
                     font-weight: 500;
                     font-size: 14px;
                     line-height: 19px;
                  }

                  >.content {
                     display: flex;
                     align-items: center;
                     justify-content: center;

                     padding: 8px 12px;
                     border: 1px solid #17948C;
                     box-sizing: border-box;
                     border-radius: 10px;
                     width: fit-content;
                     margin: auto;
                     margin-top: 18px;

                     >.title {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 24px;
                     }
                  }
               }

               >.actionButton {
                  >button {
                     border-radius: 15px;
                     background: #EFD50C;
                     border: none;
                     padding: 18px 114px;
                     width: 100%;
                     margin-top: 20px;

                     font-style: normal;
                     font-weight: 600;
                     font-size: 18px;
                     line-height: 24px;
                  }
               }
            }
         }
      }
   }
}

///////////////////////////////////////////////
//////////////////CREATE GOAL//////////////////
///////////////////////////////////////////////

.createGoal {
   padding: 13px 20px 90px;

   >.title {
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 24px;

      margin: 12px 0 32px;
   }

   &__content {
      &__cards {
         background: #FFFFFF;
         border-radius: 10px;
         padding: 32px;

         >.wrapper {
            width: 50%;

            >.title {
               font-style: normal;
               font-weight: 500;
               font-size: 14px;
               line-height: 21px;
               margin-bottom: 8px;
            }

            >input,
            >textarea {
               background: #F2F3F3;
               border: 1px solid #C9CFCE;
               box-sizing: border-box;
               border-radius: 10px;
               padding: 14px 16px;
               width: 100%;
               margin-bottom: 24px;

               font-style: normal;
               font-weight: normal;
               font-size: 12px;
               line-height: 17px;

               &::placeholder {
                  color: #17948C;
               }
            }

            >.imports {
               display: flex;
               align-items: center;
               position: relative;

               >.title {
                  background: #F2F3F3;
                  border: 1px solid #C9CFCE;
                  box-sizing: border-box;
                  border-radius: 10px;
                  padding: 14px 16px;
                  width: 100%;
                  margin-bottom: 24px;
                  height: 47px;

                  font-style: normal;
                  font-weight: normal;
                  font-size: 12px;
                  line-height: 17px;

                  &::placeholder {
                     color: #17948C;
                  }
               }

               >.action {
                  background: #17948C;
                  border: 1px solid #17948C;
                  box-sizing: border-box;
                  border-radius: 10px;
                  padding: 10px;
                  white-space: nowrap;
                  position: absolute;
                  top: 0;
                  right: 0;
                  cursor: pointer;

                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 24px;

                  &.action-white {
                     color: #FFFFFF;
                  }
               }

               >.wrapper {
                  display: flex;
                  width: 90px;



                  >.card {
                     position: relative;

                     >img {
                        width: 90px;
                        height: 90px;
                        object-fit: cover;
                        border-radius: 10px;
                        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
                     }
                  }

                  >.action {
                     display: flex;
                     flex-direction: column;
                     justify-content: flex-end;
                     margin-left: 16px;

                     >button {
                        border: 1px solid #17948C;
                        box-sizing: border-box;
                        border-radius: 10px;
                        padding: 5px 10px;
                        margin-left: 16px;
                        white-space: nowrap;
                        cursor: pointer;

                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 24px;

                        &:nth-child(2) {
                           color: #17948C;
                           background: none;
                           margin-top: 12px;
                        }

                        &:nth-child(1) {
                           color: #FAFAFA;
                           background: #17948C;
                        }
                     }
                  }
               }
            }
         }
      }

      &__action {
         display: flex;
         align-items: center;
         justify-content: flex-end;
         margin-top: 16px;

         >button {
            border: 1px solid #17948C;
            box-sizing: border-box;
            border-radius: 10px;
            padding: 5px 10px;
            margin-left: 16px;
            cursor: pointer;

            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;

            &:nth-child(1) {
               color: #17948C;
            }

            &:nth-child(2) {
               color: #FAFAFA;
               background: #17948C;
            }
         }
      }
   }

   &__list {
      >.title {
         font-style: normal;
         font-weight: 500;
         font-size: 18px;
         line-height: 24px;
         margin-bottom: 12px;
      }

      &__content {
         background: #FFFFFF;
         border: 1px solid #BDBDBD;
         box-sizing: border-box;
         border-radius: 10px;
         padding: 32px;

         display: flex;
         flex-wrap: wrap;

         >.wrapper {
            display: flex;
            flex-direction: column;
            width: 90px;
            margin-bottom: 32px;

            &:not(:last-child) {
               margin-right: 32px;
            }

            >.card {
               position: relative;

               >img {
                  border-radius: 10px;
                  width: 90px;
                  height: 90px;
                  object-fit: cover;
                  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
               }

               >.edit {
                  background: #FFFFFF;
                  border: 1px solid #C9CFCE;
                  box-sizing: border-box;
                  padding: 6px;
                  cursor: pointer;
                  width: 28px;
                  height: 28px;

                  border-radius: 4px;
                  position: absolute;
                  top: -12px;
                  right: -12px;

                  >svg {
                     width: 16px;
                     height: 16px;

                     path {
                        fill: #828282;
                     }
                  }
               }
            }

            >.title {
               font-style: normal;
               font-weight: normal;
               font-size: 12px;
               line-height: 17px;
               text-align: center;
               margin: 8px 0;
            }

            >.info {
               display: flex;
               align-items: center;
               background: #FFFFFF;
               margin-top: auto;

               border: 1px solid #C9CFCE;
               box-sizing: border-box;
               border-radius: 10px;

               >svg {
                  margin-right: 11px;
                  margin-left: 9px;
               }

               >.title {
                  font-style: normal;
                  font-weight: normal;
                  font-size: 12px;
                  line-height: 17px;
                  padding: 9px;
                  border-left: 1px solid #C9CFCE;
               }
            }
         }
      }
   }
}

///////////////////////////////////////////////
//////////////////CREATE CITY//////////////////
///////////////////////////////////////////////

.createCity {
   padding: 13px 20px 90px;
   width: 50%;

   >.title {
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 24px;
      margin: 12px 0 32px;
   }

   &__cards {
      background: #FFFFFF;
      border-radius: 10px;
      padding: 16px 32px;

      >.title {
         font-style: normal;
         font-weight: 500;
         font-size: 14px;
         line-height: 21px;
         margin-bottom: 8px;
      }

      >input {
         background: #F2F3F3;
         border: 1px solid #C9CFCE;
         box-sizing: border-box;
         border-radius: 10px;
         width: 100%;
         padding: 14px 16px;


         font-style: normal;
         font-weight: normal;
         font-size: 12px;
         line-height: 17px;
         color: #17948C;

         &::placeholder {
            color: #17948C;
         }
      }

      >.error {
         font-size: 10px;
         line-height: 15px;
         align-items: center;
         letter-spacing: 0.01em;
         color: #EB5757;
         margin-top: 4px;
      }

      >.action {
         display: flex;
         align-items: center;
         justify-content: flex-end;
         margin-top: 16px;

         >button {
            background: #17948C;
            border: 1px solid #17948C;
            box-sizing: border-box;
            border-radius: 10px;
            padding: 5px 10px;
            cursor: pointer;

            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.03em;
            color: #FAFAFA;
         }
      }

      &__list {
         margin-top: 32px;

         >.title {
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: 0.03em;
            color: #000000;
            margin-bottom: 16px;
         }

         >.wrapper {
            background: #FFFFFF;
            border-radius: 10px;

            >.title {
               display: flex;
               align-items: center;
               justify-content: space-between;
               padding: 16px 26px;
               border-bottom: 1px solid #C9CECE;

               font-style: normal;
               font-weight: 600;
               font-size: 14px;
               line-height: 21px;
               letter-spacing: 0.181818px;
               text-transform: uppercase;
               color: #031615;
            }

            >.list {
               padding: 13px 26px;

               >.content {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  padding-bottom: 12px;

                  >.title {
                     font-style: normal;
                     font-weight: 500;
                     font-size: 12px;
                     line-height: 18px;
                     letter-spacing: 0.3px;
                     text-transform: capitalize;
                     color: #031615;
                  }

                  >.edit {
                     background: #FFFFFF;
                     border: 1px solid #C9CFCE;
                     box-sizing: border-box;
                     padding: 6px;
                     cursor: pointer;
                     width: 28px;
                     height: 28px;

                     border-radius: 4px;

                     >svg {
                        width: 16px;
                        height: 16px;

                        path {
                           fill: #828282;
                        }
                     }
                  }
               }
            }
         }
      }
   }

   &__action {
      &__header {
         display: flex;
         align-items: center;
         justify-content: center;
         position: relative;

         background: #FFFFFF;
         border: 1px solid #FAFAFA;
         border-radius: 10px 10px 0px 0px;
         padding: 10px 284px;

         >.title {
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 28px;
            text-align: center;
            letter-spacing: 0.181818px;
            color: #000000;
         }

         >svg {
            position: absolute;
            right: 0;
            margin-right: 12px;
            cursor: pointer;
         }
      }

      &__content {
         background: #E4E7E7;
         box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1), 0px 0px 4px rgba(0, 0, 0, 0.15);
         border-radius: 0 0 10px 10px;
         padding: 24px 28px;
         display: flex;
         flex-direction: column;

         >.list {
            display: flex;
            align-items: center;

            >.title {
               font-style: normal;
               font-weight: 500;
               font-size: 18px;
               line-height: 27px;
               letter-spacing: 0.181818px;
               color: #000000;
               padding-right: 147px;
            }

            >input {
               width: 100%;
               background: #F2F3F3;
               border: 1px solid #C9CFCE;
               box-sizing: border-box;
               border-radius: 10px;
               padding: 14px 16px;

               font-style: normal;
               font-weight: normal;
               font-size: 12px;
               line-height: 17px;
               align-items: center;
               letter-spacing: 0.01em;
               color: #17948C;

               &::placeholder {
                  color: #17948C;
               }
            }
         }


         .error {
            font-size: 10px;
            line-height: 15px;
            align-items: center;
            letter-spacing: 0.01em;
            color: #EB5757;
            margin-left: 186px;
            margin-top: 4px;
         }

         >.actionButton {
            display: flex;
            justify-content: flex-end;
            margin-top: 24px;


            >button {
               background: #17948C;
               border: 1px solid #17948C;
               box-sizing: border-box;
               border-radius: 10px;
               padding: 16px 25px;

               font-style: normal;
               font-weight: normal;
               font-size: 18px;
               line-height: 17px;
               letter-spacing: 0.01em;
               color: #FAFAFA;
            }
         }
      }
   }
}

///////////////////////////////////////////////
//////////////////CREATE OFFER/////////////////
///////////////////////////////////////////////

.createOffer {
   padding: 13px 20px 90px;

   >.title {
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 24px;
      margin: 12px 0 32px;
   }

   &__cards {
      background: #FFFFFF;
      border-radius: 10px;
      padding: 24px;

      >.title {
         font-style: normal;
         font-weight: 500;
         font-size: 24px;
         line-height: 24px;
         letter-spacing: 0.03em;
         color: #000000;
         margin-bottom: 32px;
      }

      >.wrapper {
         >.title {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.0709534px;
            color: #000000;
            margin-bottom: 8px;
         }

         >.userPhoto {
            margin-bottom: 16px;
            position: relative;
            width: 100px;
            height: 100px;

            >img {
               width: 100px;
               height: 100px;
               border-radius: 100%;
               object-fit: cover;
            }

            >svg {
               position: absolute;
               bottom: 0;
               right: 0;
               cursor: pointer;
            }
         }
      }

      >.cards {
         display: flex;
         justify-content: space-between;

         >.card {
            flex-basis: 45%;

            >.wrapper {
               select {
                  margin-bottom: 32px;
               }

               >.title {
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 21px;
                  letter-spacing: 0.0709534px;
                  color: #000000;
                  margin-bottom: 8px;
               }

               >.validation {
                  display: flex;
                  align-items: center;
                  margin-bottom: 16px;

                  >input {
                     background: #F2F3F3;
                     border: 1px solid #C9CFCE;
                     box-sizing: border-box;
                     border-radius: 10px;
                     padding: 14px 16px;
                     width: 100%;
                     margin-right: 12px;

                     font-style: normal;
                     font-weight: normal;
                     font-size: 12px;
                     line-height: 17px;
                     letter-spacing: 0.01em;
                     color: #17948C;

                     &::placeholder {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 17px;
                        letter-spacing: 0.01em;
                        color: #BDBDBD;
                     }
                  }
               }

               >textarea {
                  &::placeholder {
                     font-style: normal;
                     font-weight: normal;
                     font-size: 12px;
                     line-height: 17px;
                     letter-spacing: 0.01em;
                     color: #BDBDBD !important;
                  }
               }

               >.error {
                  font-style: normal;
                  font-weight: normal;
                  font-size: 12px;
                  line-height: 17px;
                  letter-spacing: 0.01em;
                  color: #EB5757;
                  margin-bottom: 16px;
               }

               >.actionWrapper {
                  display: flex;
                  margin-bottom: 19px;

                  >.buttonAction {
                     display: flex;
                     align-items: center;

                     >.circle {
                        width: 20px;
                        height: 20px;
                        border: 2px solid #337F99;
                        border-radius: 100%;
                        margin-right: 8px;
                        cursor: pointer;

                        &-full {
                           @extend .circle;
                           background: #337F99;
                        }
                     }

                     >.title {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 13px;
                        line-height: 19px;
                        letter-spacing: 0.415584px;
                        color: #626262;
                        margin-right: 18px;
                     }
                  }
               }

               >.date {
                  display: flex;
                  align-items: center;
                  background: #F2F3F3;
                  border: 1px solid #C9CFCE;
                  box-sizing: border-box;
                  border-radius: 10px;
                  margin-bottom: 16px;

                  >.title {
                     font-style: normal;
                     font-weight: normal;
                     font-size: 12px;
                     line-height: 17px;
                     letter-spacing: 0.01em;
                     color: #17948C;
                     padding: 13px 16px;
                  }

                  >.icon {
                     background: #FFFFFF;
                     border: 1px solid #C9CFCE;
                     border-radius: 10px;
                     box-sizing: border-box;
                     width: 44px;
                     height: 44px;
                     cursor: pointer;

                     >svg {
                        width: 16px;
                        height: 18px;
                        margin-top: 13px;
                        margin-left: 13px;

                        path {
                           fill: #358B9D;
                        }
                     }
                  }
               }

               >.text {
                  display: flex;
                  align-items: center;
                  margin-bottom: 16px;

                  >input {
                     background: #F2F3F3;
                     border: 1px solid #C9CFCE;
                     box-sizing: border-box;
                     border-radius: 10px;
                     padding: 15px 14px;
                     width: 50px;

                     font-style: normal;
                     font-weight: 500;
                     font-size: 14px;
                     line-height: 21px;
                     letter-spacing: 0.0709534px;
                     color: #17948C;

                     /* Chrome, Safari, Edge, Opera */
                     &::-webkit-outer-spin-button,
                     &::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                     }

                     /* Firefox */
                     &[type=number] {
                        -moz-appearance: textfield;
                     }

                  }

                  >.title {
                     font-style: normal;
                     font-weight: 500;
                     font-size: 14px;
                     line-height: 21px;
                     letter-spacing: 0.0709534px;
                     color: #17948C;
                     margin-left: 12px;
                  }
               }

               >textarea {
                  background: #F2F3F3;
                  border: 1px solid #C9CFCE;
                  border-radius: 10px;
                  padding: 14px 20px;
                  width: 100%;

                  font-style: normal;
                  font-weight: normal;
                  font-size: 12px;
                  line-height: 17px;
                  letter-spacing: 0.01em;
                  color: #17948C;

                  &::placeholder {
                     color: #17948C;
                  }
               }

               >.dropdown {
                  display: flex;
                  align-items: center;
                  background: #F2F3F3;
                  border: 1px solid #C9CFCE;
                  box-sizing: border-box;
                  border-radius: 10px;
                  padding: 14px 16px;
                  width: 100%;
                  position: relative;

                  &.dropdownAction {
                     margin-bottom: 12px;
                  }

                  >.title {
                     font-style: normal;
                     font-weight: normal;
                     font-size: 12px;
                     line-height: 17px;
                     letter-spacing: 0.01em;
                     color: #17948C;
                     flex: 1;
                  }

                  >svg {
                     cursor: pointer;

                     path {
                        fill: #17948C;
                     }
                  }
               }

               >.actionDate {
                  display: flex;
                  align-items: center;
                  margin-bottom: 16px;

                  >.date {
                     display: flex;
                     align-items: center;
                     background: #F2F3F3;
                     border: 1px solid #C9CFCE;
                     box-sizing: border-box;
                     border-radius: 10px;
                     width: 100%;

                     >.title {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 17px;
                        letter-spacing: 0.01em;
                        color: #17948C;
                        padding: 13px 16px;
                     }

                     >.icon {
                        background: #FFFFFF;
                        border: 1px solid #C9CFCE;
                        border-radius: 10px;
                        box-sizing: border-box;
                        width: 44px;
                        height: 44px;
                        cursor: pointer;

                        >svg {
                           width: 16px;
                           height: 18px;
                           margin-top: 13px;
                           margin-left: 13px;

                           path {
                              fill: #358B9D;
                           }
                        }
                     }
                  }

                  >.time {
                     display: flex;
                     margin-left: 25px;

                     .title {
                        padding: 11px 9px;
                        background: #F2F3F3;
                        border: 1px solid #C9CFCE;
                        box-sizing: border-box;
                        border-radius: 10px 0 0 10px;

                        font-style: normal;
                        font-weight: normal;
                        font-size: 13px;
                        line-height: 19px;
                        letter-spacing: 0.415584px;
                        color: #17948C;
                     }

                     .info {
                        padding: 11px 9px;
                        background: #FFFFFF;
                        border: 1px solid #C9CFCE;
                        box-sizing: border-box;
                        border-radius: 0px 10px 10px 0px;

                        font-style: normal;
                        font-weight: 500;
                        font-size: 13px;
                        line-height: 19px;
                        letter-spacing: 0.415584px;
                        color: #979797;
                     }
                  }
               }

               >.images {
                  position: relative;
                  margin-bottom: 12px;

                  >img {
                     width: 343px;
                     height: 170px;
                     border-radius: 10px;
                  }

                  >.icon {
                     position: absolute;
                     top: 0;
                     left: 19rem;
                     background: linear-gradient(255.07deg, #D8D8D8 3.42%, rgba(196, 196, 196, 0) 82.7%);
                     border: 0 10px 0 0;

                     >svg {
                        margin: 8px;
                     }
                  }
               }

               >.imports {
                  display: flex;
                  align-items: center;
                  position: relative;
                  margin-top: 12px;

                  >.title {
                     background: #F2F3F3;
                     border: 1px solid #C9CFCE;
                     box-sizing: border-box;
                     border-radius: 10px;
                     padding: 14px 16px;
                     width: 100%;
                     margin-bottom: 24px;
                     height: 47px;

                     font-style: normal;
                     font-weight: normal;
                     font-size: 12px;
                     line-height: 17px;

                     &::placeholder {
                        color: #17948C;
                     }
                  }

                  >.action {
                     background: #17948C;
                     border: 1px solid #17948C;
                     box-sizing: border-box;
                     border-radius: 10px;
                     padding: 10px;
                     white-space: nowrap;
                     position: absolute;
                     top: 0;
                     right: 0;
                     cursor: pointer;

                     font-style: normal;
                     font-weight: 500;
                     font-size: 14px;
                     text-transform: uppercase;
                     line-height: 24px;
                     color: #FFFFFF;
                  }
               }

               .username {
                  display: flex;
                  align-items: center;
                  background: #F2F3F3;
                  border: 1px solid #C9CFCE;
                  box-sizing: border-box;
                  border-radius: 10px;

                  >.title {
                     font-style: normal;
                     font-weight: normal;
                     font-size: 12px;
                     line-height: 17px;
                     letter-spacing: 0.01em;
                     color: #17948C;
                     padding: 14px 16px;
                  }

                  >input {
                     background: none;
                     border: none;
                     border-left: 1px solid #C9CFCE;
                     padding: 15px 14px;

                     font-style: normal;
                     font-weight: 500;
                     font-size: 14px;
                     line-height: 21px;
                     letter-spacing: 0.0709534px;
                     color: #17948C;

                     &::placeholder {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 17px;
                        letter-spacing: 0.01em;
                        color: #BDBDBD;
                     }
                  }
               }
            }
         }
      }
   }

   .container {
      display: flex;
      align-items: center;

      >.username {
         min-width: 420px;
         margin-right: 12px;
      }
   }

   >.buttonAction {
      display: flex;
      justify-content: flex-end;

      >button {
         margin-top: 12px;
         padding: 5px 10px;
         font-style: normal;
         font-weight: 500;
         font-size: 14px;
         line-height: 24px;
         white-space: nowrap;
         margin-left: 16px;

         border: 1px solid #17948C;
         box-sizing: border-box;
         border-radius: 10px;
         cursor: pointer;

         &:nth-child(2) {
            color: #FAFAFA;
            background: #17948C;
         }

         &:nth-child(1) {
            color: #17948C;
         }
      }
   }

   .flexAlign {
      display: flex;
      align-items: center;
      justify-content: center;

      >svg {
         margin-right: 8px;
      }
   }

}

.createUser {

   &__dropdown {
      position: absolute;
      background: #FFFFFF;
      border: 1px solid #C9CFCE;
      box-sizing: border-box;
      padding: 14px 16px;
      border-radius: 10px;

      width: 100%;
      top: 4rem;
      left: 0;

      >.title {
         font-style: normal;
         font-weight: normal;
         font-size: 12px;
         line-height: 17px;
         letter-spacing: 0.01em;
         color: #17948C;
         flex: 1;
         cursor: pointer;

         &:not(:last-child) {
            margin-bottom: 16px;
         }
      }

      >svg {
         cursor: pointer;

         path {
            fill: #17948C;
         }
      }
   }

   .buttonAdd {
      display: flex;
      align-items: center;
      width: fit-content;
      margin-left: auto;
      padding: 10px 22px;
      background: #FFFFFF;
      border: 1px solid #17948C;
      border-radius: 10px;
      cursor: pointer;

      >.title {
         font-style: normal;
         font-weight: normal;
         font-size: 12px;
         line-height: 17px;
         letter-spacing: 0.01em;
         color: #17948C;
         margin-left: 8px;
      }
   }
}

.createBusiness {
   .business {
      margin-bottom: 12px;
   }


   .date {
      display: flex;
      align-items: center;
      background: #F2F3F3;
      border: 1px solid #C9CFCE;
      box-sizing: border-box;
      border-radius: 10px;
      margin-bottom: 16px;

      >.title {
         font-style: normal;
         font-weight: normal;
         font-size: 12px;
         line-height: 17px;
         letter-spacing: 0.01em;
         color: #17948C;
         padding: 13px 16px;
      }

      >.icon {
         background: #FFFFFF;
         border: 1px solid #C9CFCE;
         border-radius: 10px;
         box-sizing: border-box;
         width: 44px;
         height: 44px;
         cursor: pointer;

         >svg {
            width: 16px;
            height: 18px;
            margin-top: 13px;
            margin-left: 13px;

            path {
               fill: #358B9D;
            }
         }
      }
   }

   .dropdown {
      margin-bottom: 12px !important;
   }

   .inputContentStyle {
      margin-bottom: 12px !important;
   }

   .businessMargin {
      margin-bottom: 16px;
      width: 500px !important;
   }

   .buttonAction {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      >button {
         font-style: normal;
         font-weight: 500;
         font-size: 14px;
         line-height: 24px;
         letter-spacing: 0.03em;

         padding: 5px 10px;
         margin-left: 11px;
         box-sizing: border-box;
         border-radius: 10px;
         cursor: pointer;

         &:nth-child(1) {
            border: 1px solid #EB5757;
            color: #EB5757;
            background: transparent;
         }

         &:nth-child(2) {
            border: 1px solid #17948C;
            background: #17948C;
            color: #FFFFFF;
         }
      }
   }

   .buttonActionAdd {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 100px;

      background: #FFFFFF;
      border: 1px solid #17948C;
      box-sizing: border-box;
      border-radius: 10px;
      cursor: pointer;

      >svg {
         width: 24px;
         height: 24px;
      }

      >button {
         border: none;
         background: transparent;

         font-style: normal;
         font-weight: normal;
         font-size: 12px;
         line-height: 17px;
         letter-spacing: 0.01em;
         color: #17948C;
      }
   }

   .buttonActionSave {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      >button {
         font-style: normal;
         font-weight: 500;
         font-size: 14px;
         line-height: 24px;
         letter-spacing: 0.03em;

         padding: 5px 10px;
         margin-left: 11px;
         box-sizing: border-box;
         border-radius: 10px;
         cursor: pointer;

         &:nth-child(1) {
            border: 1px solid #17948C;
            color: #17948C;
            background: transparent;
         }

         &:nth-child(2) {
            border: 1px solid #17948C;
            background: #17948C;
            color: #FFFFFF;
         }
      }
   }
}

.dropContent {
   width: 100% !important;
   margin-bottom: 12px;

   &__absolute {
      z-index: 3;
      min-width: 435px;
      margin-top: 0;
   }
}

input[type="file"] {
   display: none;
}

label {
   cursor: pointer;
}

.justifyContent {
   justify-content: space-between;
   border-bottom: none;
}

.halfWidth {
   width: 50%;
}

.fullWidth {
   width: 100% !important;
}

.fullBasis {
   flex-basis: 100% !important;
   padding: 0;

   .validation {
      input {
         margin-right: 0 !important;
      }
   }
}

.flexBetween {
   display: flex;
   justify-content: space-between;
}

.withPadding {
   padding: 13px 8px;
   background: #FFFFFF;
   border: 1px solid #C9CFCE;
   box-sizing: border-box;
   border-radius: 10px;
}

.titleInput {
   display: flex;
   align-items: center;

   background: #FFFFFF;
   border: 1px solid #C9CFCE;
   box-sizing: border-box;
   border-radius: 10px;
   width: 100%;


   .textIcon {
      background: #FFFFFF;
      border: 1px solid #C9CFCE;
      border-radius: 10px;
      padding: 13px 12px;

      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 17px;
      letter-spacing: 0.01em;
      color: #17948C;
   }
}

.smallActionButton {
   display: flex;
   justify-content: flex-end;

   >button {
      color: #FFFFFF;
      padding: 7px 30px;
      background: #17948C;
      border-radius: 10px;
      border: none;
      cursor: pointer;

      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 17px;
      letter-spacing: 0.01em;
   }
}

.defaultInput {
   input {
      width: 100%;
      border: none;
      background: transparent;

      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 17px;
      letter-spacing: 0.01em;
      color: #17948C;
      padding-left: 8px;
   }
}

.modalContainerTitle {
   padding: 10px 0 !important;
}


.noMargin {
   margin: 0 !important;
}

.modalCreate {
   width: 48%;
   margin-bottom: 8px;

   >.title {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 8px;
   }

   .inputModal {
      background: #FFFFFF;
      border: 1px solid #C9CFCE;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 10px 12px;
   }

   .date {
      display: flex;
      align-items: center;
      background: #FFFFFF;
      border: 1px solid #C9CFCE;
      box-sizing: border-box;
      border-radius: 10px;
      margin-bottom: 16px;

      >.icon {
         background: #FFFFFF;
         border: 1px solid #C9CFCE;
         border-radius: 10px;
         box-sizing: border-box;
         width: 44px;
         height: 44px;
         cursor: pointer;



         >svg {
            width: 16px;
            height: 18px;
            margin-top: 13px;
            margin-left: 13px;

            path {
               fill: #358B9D;
            }
         }
      }

      >.title {
         font-style: normal;
         font-weight: normal;
         font-size: 12px;
         line-height: 17px;
         letter-spacing: 0.01em;
         color: #17948C;
         padding: 13px 16px;
      }
   }
}

.modalSeeImage {
   max-height: 85vh;
   min-width: 720px;
   overflow-x: auto;
   background: #000000;
   border-radius: 0px 0px 10px 10px;
   display: flex;
   justify-content: center;

   >img {
      object-fit: contain;
      max-width: 445px;
   }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
   -webkit-appearance: none;
   margin: 0;
}

/* Firefox */
input[type=number] {
   -moz-appearance: textfield;
}

.emptyStateCards {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;

   background: #FFFFFF;
   border: 1px solid #E4E7E7;
   border-radius: 10px;
   padding: 35px 36px;
   margin-bottom: 25px;

   >.title {
      font-style: normal;
      font-weight: 600;
      font-size: 17px;
      line-height: 25px;
      text-align: center;
      letter-spacing: 0.01em;
      color: #031615;
      margin-top: 10px;
   }

   >.info {
      text-align: center;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.207792px;
      color: #798685;
      margin-bottom: 15px;
   }

   button {
      background: #17948C;
      border: 1.2px solid #17948C;
      border-radius: 10px;
      cursor: pointer;

      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.03em;
      color: #FAFAFA;
      padding: 5px 12px;
   }
}

.emptyStateOrder {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;

   >.title {
      width: 65%;
      padding-top: 126px;
      padding-bottom: 116px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      letter-spacing: 0.3px;
      color: #AFB6B6;
   }
}