@mixin globalCustomScroll {
    ::-webkit-scrollbar-track {
        // border-radius: 10px;
        // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        // background-color: #F5F5F5;
        background-color: rgb(238, 240, 238);
    }

    ::-webkit-scrollbar {
        width: 12px;
        // background-color: #F5F5F5;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: rgb(206, 204, 204);
    }
}