.sessionDetailMerchant {
   padding: 10px 18px 20px;

   .breadcrumb.sessionDetailMerchant {
      padding-left: 0;
   }

   &__header {
      display: flex;
      align-items: center;
   }

   &__title {
      flex: 1;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 48px;
      align-items: center;
      letter-spacing: 0.01em;
      color: #000000;
      text-transform: capitalize;
   }

   &__button {
      display: flex;
      align-items: center;

      >div {
         display: flex;
         align-items: center;
         margin-left: 12px;
         cursor: pointer;
         font-style: normal;
         font-weight: 500;
         font-size: 14px;
         line-height: 24px;
         text-align: center;
         letter-spacing: 0.03em;
         color: #FAFAFA;
         box-sizing: border-box;
         border-radius: 10px;
         padding: 4px 8px;

         >svg {
            margin-right: 8px;
         }
      }

      >div:nth-child(1) {
         background: #4F4F4F;
         border: 1.2px solid #4F4F4F;
      }

      >div:nth-child(2) {
         background: #A30015;
         border: 1.2px solid #A30015;
      }

      >div:nth-child(3) {
         background: #17948C;
         border: 1.2px solid #17948C;
      }
   }

   &__cards {
      display: flex;
      margin-top: 22px;

      &__card {
         background: #FFFFFF;
         border: 1px solid #E4E7E7;
         border-radius: 10px;
         padding: 12px;
         margin-right: 15px;
         min-width: 220px;
         position: relative;

         >svg {
            position: absolute;
            top: 3rem;
            right: 0.5rem;
            cursor: pointer;
         }

         >div:nth-child(1) {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.12px;
            color: #1C1C1C;
            margin-bottom: 10px;
         }

         >div:nth-child(2) {
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            letter-spacing: 0.1px;
            color: #1C1C1C;
            margin-bottom: 14px;
         }

         >div:nth-child(3) {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0.155844px;
            color: #828282;

            >span {
               font-style: normal;
               font-weight: normal;
               font-size: 12px;
               line-height: 18px;
               letter-spacing: 0.155844px;
               color: #EB5757;
               margin-right: 5px;
            }
         }
      }
   }

   &__tabs {
      display: flex;
      justify-content: space-between;
      margin-top: 27px;
      border-bottom: 1px solid #E4E7E7;

      >div {
         font-style: normal;
         font-weight: 600;
         font-size: 16px;
         line-height: 24px;
         letter-spacing: 0.342857px;
         color: #17948C;
         border-bottom: 4px solid #17948C;
         text-transform: uppercase;
         padding: 4px 32px;
      }

      >button {
         font-style: normal;
         font-weight: 500;
         font-size: 14px;
         line-height: 24px;
         text-align: center;
         letter-spacing: 0.03em;
         color: #FAFAFA;
         background: #17948C;
         border: 1.2px solid #17948C;
         border-radius: 10px;
         padding: 5px 23px;
         border: none;
         cursor: pointer;
      }
   }
}

.closeSession {
   background: #E4E7E7;
   border-radius: 10px;
   position: fixed;
   z-index: 4;
   width: 80%;
   left: 15rem;
   top: 6rem;

   &__header {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #FFFFFF;
      border-radius: 10px 10px 0px 0px;
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.03em;
      color: #000000;
      text-transform: uppercase;
      padding: 17px 0;

      >svg {
         position: absolute;
         right: 1rem;
         cursor: pointer;
      }
   }

   &__stats {
      display: flex;
      justify-content: space-around;
      background: #4F4F4F;
      border-radius: 0px 0px 10px 10px;
      padding: 33px 55px;

      &__wrapper {
         >span {
            font-style: normal;
            font-weight: 500;
            font-size: 10px;
            line-height: 15px;
            text-align: center;
            letter-spacing: 0.12987px;
            color: #FFFFFF;
         }

         >div {
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 27px;
            text-align: center;
            letter-spacing: 0.311688px;
            color: #FFFFFF;
            text-transform: capitalize;
         }
      }
   }

   &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 32px 0;

      >div:nth-child(1) {
         font-style: normal;
         font-weight: normal;
         font-size: 24px;
         line-height: 36px;
         letter-spacing: 0.3px;
         color: #4F4F4F;
         margin-bottom: 16px;
      }

      >div:nth-child(2) {
         font-style: normal;
         font-weight: normal;
         font-size: 14px;
         line-height: 21px;
         letter-spacing: 0.3px;
         color: #4F4F4F;
         margin-bottom: 32px;
      }

      >textarea {
         padding: 16px 21px;
         border: 1px solid #000000;
         box-sizing: border-box;
         border-radius: 5px;

         &::placeholder {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.3px;
            color: #4F4F4F;
         }
      }
   }

   &__button {
      display: flex;
      justify-content: center;
      padding-bottom: 32px;

      >button {
         font-style: normal;
         font-weight: 500;
         font-size: 16px;
         line-height: 24px;
         letter-spacing: 0.207792px;
         color: #FFFFFF;
         padding: 15px 69px;
         margin-left: 15px;
         border-radius: 10px;
         border: none;
         cursor: pointer;
      }

      >button:nth-child(1) {
         background: #66D67B;
      }

      >button:nth-child(2) {
         background: #E44D60;
      }
   }
}

.cancelSession {
   background: #E4E7E7;
   border-radius: 10px;
   position: fixed;
   z-index: 4;
   width: 80%;
   left: 15rem;
   top: 6rem;

   &__header {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #FFFFFF;
      border-radius: 10px 10px 0px 0px;
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.03em;
      color: #000000;
      text-transform: uppercase;
      padding: 17px 0;

      >svg {
         position: absolute;
         right: 1rem;
         cursor: pointer;
      }
   }

   &__stats {
      display: flex;
      justify-content: space-around;
      background: #E44D60;
      border-radius: 0px 0px 10px 10px;
      padding: 33px 55px;

      &__wrapper {
         >span {
            font-style: normal;
            font-weight: 500;
            font-size: 10px;
            line-height: 15px;
            text-align: center;
            letter-spacing: 0.12987px;
            color: #FFFFFF;
         }

         >div {
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 27px;
            text-align: center;
            letter-spacing: 0.311688px;
            color: #FFFFFF;
            text-transform: capitalize;
         }
      }
   }

   &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 32px 0;

      >div:nth-child(1) {
         font-style: normal;
         font-weight: normal;
         font-size: 24px;
         line-height: 36px;
         letter-spacing: 0.3px;
         color: #4F4F4F;
         margin-bottom: 16px;
      }

      >div:nth-child(2) {
         font-style: normal;
         font-weight: normal;
         font-size: 14px;
         line-height: 21px;
         letter-spacing: 0.3px;
         color: #4F4F4F;
         margin-bottom: 32px;
      }

      >textarea {
         padding: 16px 21px;
         border: 1px solid #000000;
         box-sizing: border-box;
         border-radius: 5px;

         &::placeholder {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.3px;
            color: #4F4F4F;
         }
      }
   }

   &__button {
      display: flex;
      justify-content: flex-end;
      padding-bottom: 32px;
      padding-right: 10rem;

      >button {
         font-style: normal;
         font-weight: 500;
         font-size: 16px;
         line-height: 24px;
         letter-spacing: 0.207792px;
         color: #FFFFFF;
         padding: 15px 69px;
         margin-left: 15px;
         border-radius: 10px;
         cursor: pointer;
         border: none;
      }

      >button:nth-child(1) {
         background: #66D67B;
      }

      >button:nth-child(2) {
         background: #E44D60;
      }
   }
}

.addGuestModal {
   position: fixed;
   left: 50%;
   top: 30%;
   transform: translateX(-40%);
   z-index: 4;

   &__header {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      background: #FFFFFF;
      border: 1px solid #FAFAFA;
      border-radius: 10px 10px 0px 0px;
      padding: 15px 0;

      svg {
         position: absolute;
         cursor: pointer;
         top: 15%;
         right: 2%;

         width: 16px;
         height: 16px;
      }

      >.title {
         font-style: normal;
         font-weight: 600;
         font-size: 14px;
         line-height: 19px;
         letter-spacing: 0.03em;
         color: #000000;
      }
   }

   &__body {
      background: #E4E7E7;
      border-radius: 0 0 10px 10px;
      padding: 12px 14px;

      display: flex;
      flex-direction: column;
      justify-content: center;

      input {
         border: none;
      }

      &__wrapper {
         display: flex;
         align-items: center;
         margin-bottom: 12px;

         >.title {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0.3px;
            color: #000000;
            margin: 12px 0 8px;
            min-width: 10rem;
         }

         >.input {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            background: #FFFFFF;
            border: 1px solid #C9CFCE;
            box-sizing: border-box;
            border-radius: 10px;

            input {
               width: 100%;
               font-style: normal;
               font-weight: normal;
               font-size: 12px;
               line-height: 17px;
               letter-spacing: 0.01em;
               color: #17948C;
            }
         }
      }
   }

   &__button {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin: 10px 0 5px;

      button {
         cursor: pointer;
         background: #17948C;
         border-radius: 10px;
         border: none;
         padding: 7px 25px;

         font-style: normal;
         font-weight: normal;
         font-size: 12px;
         line-height: 17px;
         letter-spacing: 0.01em;
         color: #FFFFFF;
      }
   }
}

.modalAddSchedule__card__content__capsule.class {
   .modalAddSchedule__card__content__capsule__text {
      flex: 1;
      padding: 12px 14px;
   }

   >svg {
      cursor: pointer;

      >path {
         fill: #17948C;
      }
   }
}