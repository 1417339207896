.detailMerchant {
    padding: 10px 18px 0;
}

.detailMerchant__header {
    margin-bottom: 32px;

    &__merchantName {
        display: flex;
        align-items: center;

        >div:nth-child(1) {
            display: flex;
            font-weight: 600;
            font-size: 24px;
            align-items: center;
            letter-spacing: 0.01em;
            color: #000000;
            flex: 1;
            text-transform: capitalize;

            >span {
                margin-left: 15px;
                color: #AFB6B6;
            }
        }

        button {
            background: #17948C;
            border: 1.2px solid #17948C;
            border-radius: 10px;
            color: #FFF;
            padding: 8px 23px;
            cursor: pointer;

            &:hover {
                background: #117a73;
                color: #FAFAFA;
            }
        }
    }

    &__merchantInfo {
        display: flex;
        align-items: center;

        >div {
            font-weight: 500;
            font-size: 14px;
            letter-spacing: 0.12px;
            color: #1C1C1C;
            margin-right: 32px;
            margin-left: 6px;
        }

        >svg {
            cursor: pointer;
        }
    }

    &__goBack {
        color: #66D67B;

        &__icon {
            margin-right: 8px;
        }
    }

    &__breadcrumbs {
        font-size: 14px;
        align-items: center;
        letter-spacing: 0.01em;
        color: #798685;

        >a:hover {
            color: #000000;
        }
    }
}

.detailMerchant__card {
    border-radius: 15px;
    background-color: #fff;
    -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);

    &__header {
        text-transform: uppercase;
        display: flex;
        border-bottom: 1px solid #AFB6B6;
        margin-top: 30px;

        &.space-between {
            justify-content: space-between;
        }

        &__nav {
            font-weight: 600;
            font-size: 16px;
            text-align: center;
            letter-spacing: 0.342857px;
            color: #AFB6B6;
            padding: 5px 30px;
        }

        &__nav--active {
            font-size: 16px;
            font-weight: 600;
            color: #17948C;
            border-bottom: 3px solid #17948C;
        }
    }
}

.detailMerchant__card {
    &__info {
        display: flex;
        margin-top: 24px;

        &Venue {
            background: #FFFFFF;
            border: 1px solid #E4E7E7;
            border-radius: 10px;
            height: fit-content;
            flex-basis: 60%;

            >div:nth-child(1) {
                >img {
                    width: 282px;
                    height: 282px;
                    object-fit: cover;
                    border-radius: 10px 0 0 10px;
                }
            }

            >div:nth-child(2) {
                position: relative;
                display: flex;
                flex-wrap: wrap;
                font-weight: 500;
                font-size: 12px;
                letter-spacing: 0.12987px;
                color: #333333;
                overflow-y: scroll;
                width: 100%;

                &::-webkit-scrollbar {
                    width: 15px;
                    background-color: #fff;
                }

                &::-webkit-scrollbar-thumb {
                    border: 4px solid rgba(0, 0, 0, 0);
                    background-clip: padding-box;
                    -webkit-border-radius: 10px;
                    background-color: #E4E7E7;
                }

                &::-webkit-scrollbar-button {
                    width: 0;
                    height: 0;
                    display: none;
                }

                &::-webkit-scrollbar-corner {
                    background-color: transparent;
                }

                >div {
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 32px;
                    width: 95%;
                    padding: 10px 18px;

                    >span {
                        font-size: 14px;
                        letter-spacing: 0.3px;
                        color: #798685;
                        text-transform: capitalize;
                    }
                }

                >svg {
                    position: absolute;
                    right: 0;
                    top: 42%;
                    cursor: pointer;
                }
            }
        }

        &Statistics {
            padding-left: 8px;
            display: grid;
            grid-gap: 8px;
            flex-wrap: wrap;
            flex-basis: 40%;
            width: 100%;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            grid-template-rows: repeat(2, minmax(0, 1fr));
            max-height: 760px;

            >div:nth-child(3) {
                >div:nth-child(2) {
                    >span {
                        font-weight: 500;
                        font-size: 24px;
                        line-height: 36px;
                        letter-spacing: 0.1px;
                        color: #1C1C1C;
                    }

                    >span:nth-child(2) {
                        color: #AFB6B6;
                    }
                }
            }

            >div:nth-child(4) {
                >div:nth-child(2) {
                    >div {
                        align-items: center;
                        display: flex;

                        >span {
                            font-weight: 500;
                            font-size: 24px;
                            line-height: 36px;
                            letter-spacing: 0.1px;
                            color: #1C1C1C;
                        }

                        >div {
                            font-style: normal;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 21px;
                            letter-spacing: 0.1px;
                            color: #1C1C1C;
                            padding-left: 10px;
                        }
                    }
                }
            }

            >div {
                position: relative;
                background: #FFFFFF;
                border: 1px solid #E4E7E7;
                border-radius: 10px;
                padding: 12px;

                >div:nth-child(1) {
                    font-weight: 500;
                    font-size: 14px;
                    letter-spacing: 0.12px;
                    color: #1C1C1C;
                    margin-bottom: 10px;
                }

                >div:nth-child(2) {
                    font-weight: 500;
                    font-size: 24px;
                    letter-spacing: 0.1px;
                    color: #1C1C1C;
                    margin-bottom: 3px;
                }

                >div:nth-child(3) {
                    display: flex;
                    align-items: center;
                    justify-content: space-evenly;
                    background: #FAFAFA;
                    border: 1px solid #17948C;
                    border-radius: 8px;
                    width: 110px;
                    padding: 0 8px;
                    cursor: pointer;

                    >div {
                        color: #17948C;
                        letter-spacing: 0.155844px;
                        font-size: 10px;
                    }

                    >svg {
                        width: 16px;
                    }

                    >svg path {
                        fill: #17948C;
                    }
                }

                >svg {
                    position: absolute;
                    right: 10px;
                    top: 40%;
                    cursor: pointer;
                }
            }
        }
    }

    &__todayClass {
        &__header {
            display: flex;
            justify-content: space-between;
            background: #FFFFFF;
            border: 1px solid #FAFAFA;
            border-radius: 10px 10px 0px 0px;
            padding: 12px 22px;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.12px;
            color: #1C1C1C;
        }

        &__content {
            &__wrapper {
                background: #E4E7E7;
                padding: 12px 22px;
                min-height: 181px;
                max-height: 181px;
                overflow-y: scroll;

                &::-webkit-scrollbar {
                    width: 15px;
                    background-color: transparent;
                }

                &::-webkit-scrollbar-thumb {
                    border: 4px solid rgba(0, 0, 0, 0);
                    background-clip: padding-box;
                    -webkit-border-radius: 10px;
                    background-color: #FAFAFA;
                }

                &::-webkit-scrollbar-button {
                    width: 0;
                    height: 0;
                    display: none;
                }

                &::-webkit-scrollbar-corner {
                    background-color: transparent;
                }
            }

            &__capsule {
                display: flex;
                align-items: center;
                padding: 19px 23px;
                background: #FFFFFF;
                border: 1px solid #E4E7E7;
                border-radius: 10px;
                margin-bottom: 6px;

                .open {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 10px;
                    line-height: 12px;
                    letter-spacing: 0.03em;
                    color: #AFB6B6;
                    padding-right: 80px;

                    >div {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 10px;
                        line-height: 12px;
                        letter-spacing: 0.03em;
                        color: #AFB6B6;
                        padding-right: 80px;
                    }

                    >span {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 19px;
                        letter-spacing: 0.03em;
                        color: #000000;
                    }
                }

                .title {
                    div {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 19px;
                        letter-spacing: 0.03em;
                        color: #000000;
                        text-transform: capitalize;
                    }

                    span {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 19px;
                        letter-spacing: 0.03em;
                        color: #17948C;
                    }
                }

                .guests {
                    margin-left: auto;

                    >div {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 10px;
                        line-height: 12px;
                        text-align: right;
                        letter-spacing: 0.03em;
                        color: #AFB6B6;
                    }

                    .guestNumber,
                    .guestNumberZero {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 21px;
                        line-height: 24px;
                        text-align: right;
                        letter-spacing: 0.015em;
                    }

                    .guestNumber {
                        color: #17948C;
                    }

                    .guestNumberZero {
                        color: #AFB6B6;
                    }
                }
            }
        }
    }

    &__button {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 36px;

        button {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            text-align: center;
            letter-spacing: 0.03em;
            box-sizing: border-box;
            border-radius: 10px;
            padding: 5px 12px;
            cursor: pointer;
        }

        a:nth-child(1) {
            button {
                color: #17948C;
                background: transparent;
                border: 1.2px solid #17948C;
            }
        }

        a:nth-child(2) {
            button {
                color: #FAFAFA;
                background: #17948C;
                border: 1.2px solid #17948C;
                margin-left: 12px;
            }
        }
    }
}

.merchantFit {
    &__card {
        &__stats {
            display: flex;

            >div {
                background: #FFFFFF;
                border: 1px solid #E4E7E7;
                border-radius: 10px;
                padding: 14px;
                margin-right: 20px;
                width: 260px;
                height: 134px;

                >div {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    color: #1C1C1C;

                    >span {
                        &:nth-child(1) {
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 21px;
                            letter-spacing: 0.12px;
                        }

                        &:nth-child(2) {
                            font-weight: 500;
                            font-size: 24px;
                            line-height: 36px;
                            letter-spacing: 0.1px;
                            flex-grow: 1;
                            margin-bottom: 20px;
                        }
                    }
                }
            }
        }
    }
}

.merchantAccount {
    &__header {
        color: #233903;
        font-size: 24px;
        font-weight: bold;
    }

    &__content {
        display: flex;

        >div {
            width: 50%;
            height: 50vh;
        }
    }

    &__content__left {
        border-right: 1px solid #979797;
    }

    &__content__right {}
}

.switch {

    &Published,
    &Featured {
        &--on {
            path {
                fill: #17948C;
            }
        }

        &--off {
            path {
                fill: #AFB6B6;
            }
        }
    }
}

.actionModal {
    background: #FFFFFF;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1), 0px 0px 4px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    position: absolute;
    z-index: 2;
    right: 2rem;
    top: 0;
    width: 200px;
    padding: 16px 25px;

    &__close {
        position: absolute;
        right: 5px;
        top: 5px;
    }

    >div:not(:last-child) {
        margin-bottom: 19px;
    }

    >div,
    a {
        display: flex;
        align-items: center;
        cursor: pointer;

        >div {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 17px;
            letter-spacing: 0.01em;
            color: #000000;
            margin-left: 9px;
        }
    }
}

.actionModalDelete {
    background: #E4E7E7;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1), 0px 0px 4px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    position: absolute;
    z-index: 2;
    right: 65px;
    top: 0;
    width: 286px;

    >div:nth-child(1) {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #FFFFFF;
        border-radius: 10px 10px 0px 0px;
        padding: 12px 8px;
        cursor: pointer;

        >svg {
            position: absolute;
            right: 0;
        }

        >div {
            font-family: Poppins;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
            text-align: center;
            letter-spacing: 0.03em;
            color: #000000;
        }
    }

    >div:nth-child(2) {
        padding: 18px 14px;

        >div {
            text-align: left;
        }

        >div:nth-child(1) {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;
            letter-spacing: 0.3px;
            color: #000000;
        }

        >div:nth-child(2) {
            margin-top: 44px;
            display: flex;
            justify-content: center;

            >button {
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 14px;
                text-align: center;
                letter-spacing: 0.3px;
                box-sizing: border-box;
                border-radius: 10px;
                padding: 8px 30px;
                border: none;
                cursor: pointer;
            }

            >button:nth-child(1) {
                margin-right: 12px;
                color: #17948C;
                border: 1px solid #17948C;
                background: transparent;
            }

            >button:nth-child(2) {
                background: #E44D60;
                border-radius: 10px;
                color: #FFFFFF;
            }
        }
    }
}