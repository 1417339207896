.stats {
  &__card {
    background: #FFFFFF;
    border: 1px solid #E4E7E7;
    border-radius: 10px;
    padding: 14px;
    height: 134px;
    margin: 0 0.5rem;

    display: flex;
    flex-direction: column;
    color: #1C1C1C;

    >span {
        &:nth-child(1) {
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.12px;
        }

        &:nth-child(2) {
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            letter-spacing: 0.1px;
            flex-grow: 1;
            margin-bottom: 20px;
        }
    }

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}