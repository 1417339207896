.bookingFoodDetail {
   background: #FFFFFF;
   border: 1px solid #E4E7E7;
   border-radius: 10px;
   padding: 18px 32px;
   flex-basis: 77%;
   margin-right: 15px;
   padding-bottom: 40px;

   >.title {
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      letter-spacing: 0.207792px;
      color: #333333;
      margin-bottom: 18px;
   }

   .container {
      display: flex;
      flex-wrap: wrap;
      height: 100%;

      >.wrapper {
         width: 33%;
         padding-right: 40px;
         padding-bottom: 10px;

         >.containerWrapper {
            display: flex;
            align-items: center;

            >.content {
               font-style: normal;
               font-weight: normal;
               font-size: 14px;
               line-height: 21px;
               letter-spacing: 0.3px;
               color: #4F4F4F;
               margin-right: 8px;
            }

            >.drop {
               background: #46AC89;
               border-radius: 8px;
               display: flex;
               align-items: center;
               padding: 2px 8px;
               width: fit-content;

               font-style: normal;
               font-weight: normal;
               font-size: 12px;
               line-height: 18px;
               letter-spacing: 0.155844px;
               color: #FFFFFF;

               svg {
                  cursor: pointer;

                  path {
                     fill: #FFFFFF;
                  }
               }
            }
         }

         >.title {
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 27px;
            letter-spacing: 0.12987px;
            color: #333333;
            margin-bottom: 8px;
         }

         >.content {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.3px;
            color: #4F4F4F;
         }

         >.drop {
            background: #46AC89;
            border-radius: 8px;
            display: flex;
            align-items: center;
            padding: 2px 8px;
            width: fit-content;

            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0.155844px;
            color: #FFFFFF;

            &--gold {
               @extend .drop;
               background-color: #EDAE0F;
            }

            svg {
               cursor: pointer;

               path {
                  fill: #FFFFFF;
               }
            }
         }
      }
   }
}

.profilePictureFood {
   width: 144px;
   height: 144px;
   clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
   -webkit-clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);

   img {
      object-fit: cover;
      width: 100%;
      height: 100%;
   }
}

.memberFoodDetail {
   padding: 13px 18px;

   >.header {
      display: flex;
      align-items: center;

      >.title {
         font-style: normal;
         font-weight: 600;
         font-size: 24px;
         line-height: 48px;
         letter-spacing: 0.01em;
         color: #000000;
         margin-right: auto;

         span {
            color: #AFB6B6;
         }
      }

      >.action {
         display: flex;
         align-items: center;
         padding: 2px 8px;

         background: #46AC89;
         border-radius: 8px;
         cursor: pointer;
         position: relative;

         >.title {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.155844px;
            color: #FFFFFF;
         }

         >svg {
            path {
               fill: #FFFFFF;
            }
         }

         .renderAction {
            position: absolute;
            right: 0;
            top: 2rem;
            z-index: 3;
            min-width: 170px;

            background: #FFFFFF;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
            border-radius: 5px;
            padding: 16px;

            .active,
            .suspend,
            .archive {
               font-style: normal;
               font-weight: normal;
               font-size: 12px;
               line-height: 14px;
               letter-spacing: 0.155844px;

               padding-bottom: 16px;
            }

            .active {
               color: #17948C;
            }

            .archive {
               color: #EB5757;
            }
         }
      }
   }

   >.headerCards {
      display: flex;
      width: 100%;

      >.card {
         background: #FFFFFF;
         border: 1px solid #E4E7E7;
         border-radius: 10px;
         padding: 14px 15px;
         margin-right: 14px;
         width: 33%;

         display: flex;
         flex-direction: column;
         position: relative;

         >svg {
            position: absolute;
            right: .5rem;
            top: 3rem;
            cursor: pointer;
         }

         >.title {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.12px;
            color: #1C1C1C;
         }

         >.content {
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            letter-spacing: 0.1px;
            color: #1C1C1C;
            margin-top: 8px;
         }

         >.info {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0.155844px;
            color: #828282;
            margin-top: auto;
         }

         &--large {
            @extend .card;
            width: 100%;
            margin-right: 0;

            >.wrapper {
               display: flex;
               align-items: center;
               margin-bottom: 11px;

               >.title {
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 21px;
                  letter-spacing: 0.12px;
                  color: #1C1C1C;
                  width: 10%;
               }

               >.content {
                  padding-left: 10px;
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 21px;
                  letter-spacing: 0.12px;
                  color: #828282;
               }

               >.action {
                  font-style: normal;
                  font-weight: normal;
                  font-size: 12px;
                  line-height: 18px;
                  letter-spacing: 0.12px;
                  color: #17948C;
                  width: 15%;
                  padding-right: 55px;
                  position: relative;
                  cursor: pointer;

                  >svg {
                     position: absolute;
                     right: .5rem;
                     top: 1rem;
                  }
               }

               >.card {
                  background: #FFFFFF;
                  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1), 0px 0px 4px rgba(0, 0, 0, 0.15);
                  border-radius: 5px;
                  padding: 8px;
                  height: 100%;
                  display: flex;
                  margin: auto;
                  cursor: pointer;

                  &Actived {
                     background: rgba($color: #031615, $alpha: 0.35);
                     box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1), 0px 0px 4px rgba(0, 0, 0, 0.15);
                     padding: 8px;
                     height: 100%;
                     display: flex;
                     margin: auto;
                     cursor: pointer;
                  }

                  >img {
                     width: 62px;
                     height: 62px;
                     border-radius: 10px;
                     object-fit: cover;
                  }

                  >.content {
                     padding-left: 8px;

                     >.title {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 17px;
                        letter-spacing: 0.01em;
                        color: #333333;
                     }

                     >.stats {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 14px;
                        letter-spacing: 0.03em;
                        color: #031615;
                        margin-top: 8px;
                        margin-bottom: 10px;
                     }
                  }
               }
            }
         }
      }
   }
}

.flexRight {
   margin-left: auto !important;
}