/*==============================
        Merchant SECTION
================================*/
.merchantContainer{
    // padding : 0px 28px 38px 40px
}

/*===================================
    EACH COLOM OF TABLE LIST MERCHANT
=====================================*/
@mixin tableMerchants--primary-text {
    font-weight: 500;
    font-size: 12px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #031615;
}
.tableMerchantsToggle .react-toggle-track,
.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track{
    background-color: #C4C4C4;
}

.tableMerchantsToggle.react-toggle--checked .react-toggle-track,
.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track{
    background-color: #358B9D;
}


.tableMerchantsToggleText{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 12px;
    letter-spacing: 0.0709534px;
    color: #fff;
}
.tableMerchants{
    &__id{
        vertical-align: top;
        @include tableMerchants--primary-text;
    }
    &__name{
        vertical-align: top;
        @include tableMerchants--primary-text;
        max-width: 150px;
    }
    &__sport{
        vertical-align: top;
        @include tableMerchants--primary-text;
        max-width: 150px;
    }
    &__region{
        vertical-align: top;
        @include tableMerchants--primary-text;
    }
    &__booking{
        vertical-align: top;
        @include tableMerchants--primary-text;
    }
    &__total-slot > div{
        display: flex;
        flex-direction: column;
        align-items: center;

    }
    &__feature{
        text-align: center;
    }
    &__status{
        div{
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        span{
            @include tableMerchants--primary-text;
            height: 25px;
            min-width: 109px;
            border-radius: 5px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            &:not(:first-child){
                margin-top: 7px;
            }
        }
        &--partner{
            background: rgba(#1F7899, 0.3);
            mix-blend-mode: normal;
        }
        &--published{
            background: rgba(#66D67B, 0.3);
            mix-blend-mode: normal;
        }
    }
    &__action{
        i{
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 2px;
            border-radius: 4px;
            border: 1px solid #C9CFCE;
            width: fit-content;
            cursor: pointer;
        }
        svg{
            width: 24px;
            height: 24px;
        }
        svg path{
            fill : #828282;
        }
    }
}

/*===================================
    EACH COLOM OF TABLE LIST OFFER
=====================================*/
.tableListOffer{
    &__no{
        text-align: center;
    }
    &__merchant{
        width: 200px;
    }
    &__date{
        width: 150px;
    }
    &__duration{
        width: 70px;
    }
    &__featured{
        text-align: center;
    }

    &__status{
        text-align: center;
    }
    &__action{
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
}