.customeCheckbox {
   display: block;
   position: relative;
   cursor: pointer;
   font-size: 22px;
   line-height: 24px;
   height: 16px;
   width: 16px;
   clear: both;
 }
 
 /* Hide the browser's default checkbox */
 .customeCheckbox input {
   position: absolute;
   opacity: 0;
   cursor: pointer;
 }
 
 /* Create a custom checkbox */
 .checkmark {
   position: absolute;
   top: 0px;
   left: 0px;
   height: 16px;
   width: 16px;
   background-color: #fff;
   border-radius: 3px;
   transition: all 0.3s ease-out;
   -webkit-transition: all 0.3s ease-out;
   -moz-transition: all 0.3s ease-out;
   -ms-transition: all 0.3s ease-out;
   -o-transition: all 0.3s ease-out;
   border: 1px solid #66D67B;
 }
 
 /* When the checkbox is checked, add a blue background */
 .customeCheckbox input:checked ~ .checkmark {
   background-color: #66D67B;
   border-radius: 5px;
   -webkit-transform: rotate(0deg) scale(1);
   -ms-transform: rotate(0deg) scale(1);
   transform: rotate(0deg) scale(1);
   opacity: 1;
   border:1px solid #66D67B;
 }
 
 /* Create the checkmark/indicator (hidden when not checked) */
 .checkmark:after {
   position: absolute;
   content: "";
   left: 2px;
   top: 2px;
   height: 0px;
   width: 0px;
   border-radius: 5px;
   border: solid #009bff;
   border-width: 0 3px 3px 0;
   -webkit-transform: rotate(0deg) scale(0);
   -ms-transform: rotate(0deg) scale(0);
   transform: rotate(0deg) scale(0);
   opacity: 1;
   transition: all 0.3s ease-out;
   -webkit-transition: all 0.3s ease-out;
   -moz-transition: all 0.3s ease-out;
   -ms-transition: all 0.3s ease-out;
   -o-transition: all 0.3s ease-out;
 }
 
 /* Show the checkmark when checked */
 .customeCheckbox input:checked ~ .checkmark:after {
   -webkit-transform: rotate(45deg) scale(1);
   -ms-transform: rotate(45deg) scale(1);
   transform: rotate(45deg) scale(1);
   opacity: 1;
   left: 3.5px;
   top: 0.5px;
   width: 4px;
   height: 8px;
   border: 1px solid #fff;
   border-width: 0 2.5px 2.5px 0;
   background-color: transparent;
   border-radius: 0;
 }
 
 /* Ripple Effect */
 .checkmark::before {
   position: absolute;
   content: "";
   left: 10px;
   top: 10px;
   width: 0px;
   height: 0px;
   border-radius: 5px;
   border: 3px solid #66D67B;
   -webkit-transform: scale(0);
   -ms-transform: scale(0);
   transform: scale(0);
 }
 
 .customeCheckbox input:checked ~ .checkmark::before {
   left: -3px;
   top: -3px;
   width: 24px;
   height: 24px;
   border-radius: 5px;
   -webkit-transform: scale(3);
   -ms-transform: scale(3);
   transform: scale(3);
   opacity: 0;
   z-index: 999;
   transition: all 0.3s ease-out;
   -webkit-transition: all 0.3s ease-out;
   -moz-transition: all 0.3s ease-out;
   -ms-transition: all 0.3s ease-out;
   -o-transition: all 0.3s ease-out;
 }
 
 /* Style the checkmark/indicator */
 .customeCheckbox .checkmark:after {
   left: 5.5px;
   top: 1.5px;
   width: 4px;
   height: 8px;
   border: solid white;
   border-width: 0 2.5px 2.5px 0;
   -webkit-transform: rotate(45deg);
   -ms-transform: rotate(45deg);
   transform: rotate(45deg);
 }
 