@mixin pageTitle {
    font-weight: 600;
    font-size: 24px;
    line-height: 48px;
    letter-spacing: 0.01em;
    color: #000000;
}

.pageTitle {
    @include pageTitle;
}

@mixin pageSubTitle {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.207792px;
    color: #000000;
}

.pageSubTitle {
    @include pageSubTitle;
}