.browseClass {
   &__title {
      font-weight: 500;
       font-size: 24px;
       line-height: 24px;
       letter-spacing: 0.03em;
       color: #000000;
       margin-top: 12px;
       margin-bottom: 32px;
   }

   &__list {
      background: #FFFFFF;
      border-radius: 10px;
      padding: 16px 10px;
      margin-top: 17px;

      &__search {
         position: relative;

         background: #F7F8F8;
         border: 1px solid #C9CFCE;
         box-sizing: border-box;
         border-radius: 10px;
         width: 20%;

         display: flex;
         align-items: center;

         input {
            width: 100%;
            flex: 1;
            background: none;
            border: none;
            padding: 12px 18px;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 17px;
            display: flex;
            align-items: center;
            letter-spacing: 0.01em;
            color: #17948C;

            &::placeholder {
               color: #17948C;
            }
         }

         >svg {
            position: absolute;
            right: 1rem;

            path {
               fill: #828282
            }
         }
      }
   }

   &__content {

      >div {
         display: flex;
         align-items: center;
         flex-wrap: wrap;
         align-content: stretch;
         width: 100%;

      }

      &__cards {
         margin: 0 2px;
         display: flex;
         background: #FFFFFF;
         border: 1px solid #BDBDBD;
         box-sizing: border-box;
         border-radius: 10px;
         padding: 14px 10px;
         margin-top: 15px;
         width: 20rem;
         overflow: hidden;

         &:hover {
            background-color: rgba($color: #000000, $alpha: 0.1);
            cursor: pointer;
         }

         &__img {
            margin-right: 16px;

            >img {
               border-radius: 10px;
               width: 101px;
               height: 101px;
               object-fit: cover;
            }
         }

         &__title {
            >.className {
               font-style: normal;
               font-weight: 600;
               font-size: 14px;
               line-height: 14px;
               letter-spacing: 0.03em;
               color: #333333;
               margin-bottom: 13px;
            }

            >.classGoal {
               display: flex;
               align-items: center;

               >.title {
                  font-style: normal;
                  font-weight: normal;
                  font-size: 12px;
                  line-height: 17px;
                  letter-spacing: 0.01em;
                  color: #495050;
                  margin-bottom: 10px;

                  &:after {
                     content: ",\00A0"
                  }

                  &:last-of-type:after {
                     content: ""
                  }
               }

            }

            >.classRating {
               display: flex;
               align-items: center;
               margin-bottom: 8px;

               >div:nth-child(1) {
                  display: flex;
                  align-items: center;

                  >img {
                     height: 16px;
                  }

                  margin-right: 16px;
               }

               >div:nth-child(2),
               >div:nth-child(3) {
                  font-style: normal;
                  font-weight: normal;
                  font-size: 12px;
                  line-height: 17px;
                  letter-spacing: 0.01em;
                  color: #17948C;
                  margin-right: 5px;
               }
            }

            >.classDesc {
               display: flex;
               align-items: center;

               >div {
                  font-style: normal;
                  font-weight: normal;
                  font-size: 12px;
                  line-height: 17px;
                  letter-spacing: 0.01em;
                  color: #333333;
                  opacity: 0.5;
                  white-space: nowrap;
                  overflow: hidden;
                  // text-overflow: ellipsis;

                  &:after {
                     content: ",\00A0"
                  }

                  &:last-of-type:after {
                     content: ""
                  }
               }
            }
         }
      }
   }
}

.tableDashboard .rdt_TableRow button
{
    background: #17948C;
    border: 1.2px solid #17948C;
    border-radius: 10px;
    color: #FFF;
    padding: 5px 15px;
    cursor: pointer;
}
   .tableDashboard .rdt_TableRow button:hover
   {
      background: #117a73;
      color: #FAFAFA;
   }