.classSessionDetail {
   padding: 13px 18px;

   &__header {
      display: flex;
      align-items: center;
   }

   &__title {
      flex: 1;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 48px;
      letter-spacing: 0.01em;
      color: #000000;
   }

   &__button {
      >button {
         font-style: normal;
         font-weight: 500;
         font-size: 14px;
         line-height: 24px;
         text-align: center;
         letter-spacing: 0.03em;
         color: #FAFAFA;
         background: #17948C;
         border: 1.2px solid #17948C;
         box-sizing: border-box;
         border-radius: 10px;
         padding: 5px 22px;
      }
   }
}

.detailMerchant__card__infoVenue.detailClass {
   display: flex;
   height: 285px;
}

.detailStats {
   flex-basis: 41%;
}

.detailClass__card {
   display: flex;
   flex-wrap: nowrap !important;
   flex-direction: column;

   >div {
      margin-bottom: 0 !important;
   }

   &__info {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.155844px;
      color: #828282;

      >span {
         font-style: normal;
         font-weight: normal;
         font-size: 12px;
         line-height: 18px;
         letter-spacing: 0.155844px;
         color: #EB5757;
         margin-right: 6px;
         padding-top: 29px;
      }
   }
}