@import "../../Styles/base/mixin";

/*========================================
        SESSION BALANCER CALENDAR
========================================**/
.sessionBalancer {
  margin-top: 24px;
}

.sessionBalancer__header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  padding: 24px;

  >div {
    display: flex;
    align-items: center;

    *:not(:last-child) {
      margin-right: 8px;
    }
  }

  &__option {
    display: flex;

    div {
      display: flex;
      align-items: center;
      padding: 4px 12px;
      height: 36px;
      border-radius: 5px;
      cursor: pointer;
      -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
      box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
    }

    &--active {
      background-color: rgb(102, 214, 123);
      color: #fff;
    }

    &__addIcon {
      svg path {
        fill: #fff;
      }

      svg {
        fill: #fff;
        margin-right: 4px;
      }
    }
  }
}


.sessionBalancer__monthSlider {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 24px;

  &__month {
    display: flex;
    align-items: center;
    font-size: 16px;

    span {
      width: 100px;
      text-align: center;
    }

    >i {
      border: 1px solid rgb(225, 225, 225);
      height: 28px;
      width: 28px;
      border-radius: 28px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: 16px;
        height: 16px;
      }

      svg path {
        fill: #212529;
      }
    }
  }

  &__year {
    font-size: 12px;
  }
}

.sessionBalancer__days {
  display: flex;
  align-items: center;
  height: 36px;

  >span {
    font-size: 12px;
    text-align: center;
    width: 14.28%;
  }
}

.sessionBalancer__dates {
  display: flex;
  flex-wrap: wrap;

  >div {
    cursor: pointer;
  }
}

.sessionBalancer__dates__date {
  display: flex;
  min-height: 100px;
  height: fit-content;
  width: 14.28%;
  border-top: 1px solid #EAEAEA;

  &:not(:nth-child(7n)) {
    border-right: 1px solid #EAEAEA;
  }

  flex-direction: column;
  align-items: center;

  &__header {
    font-size: 12px;
    border-bottom: 1px solid #e1e1e1;
    padding: 4px 6px;
    text-align: center;
    width: 100%;
  }

  &__content {
    padding: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;

    >span:nth-child(1) {
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 8px;
    }
  }

  &__content__simple {
    background-color: rgb(102, 214, 123);
    width: 100%;
    font-size: 12px;
    padding: 8px;
    border-radius: 5px;
    text-align: center;

    &:hover {
      -webkit-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.75);
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.75);
    }
  }

  &__content__detail {
    @extend .sessionBalancer__dates__date__content__simple;
    text-align: left;
    font-weight: 500;
    color: #212529;
  }


  &__content--loading {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
  }
}

/*========================================
                DATE STATE
========================================**/
.date--today {
  background-color: rgb(148, 195, 233);

  .calendaerSB__content__dates__date__header {
    font-weight: bold;
  }
}

.date--focus {
  // font-size: 24px;
}

.date--infocus {
  opacity: 0.3;
}

/*==============================================
      SESSION BALANCER MODAL INPUT
CUSTOM MODAL SYLE  SECTION FOR SESSION BALANCER
==============================================*/

//First section of this modal
.modalSessionBalancer__header {
  display: flex;
  justify-content: space-between;

  &__closeModal {
    cursor: pointer;

    svg path {
      fill: #fff;
    }
  }
}

//Second section of this modal
.modalSessionBalancer__Quota {}

.modalSessionBalancer__inputQuota__inputSelect {
  display: block;
  // font-size: 16px;
  font-family: sans-serif;
  // font-weight: 700;
  // color: #444;
  line-height: 1.3;
  padding: .6em 1.4em .5em .8em;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  // border: 1px solid #aaa;
  // box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
  // border-radius: .5em;
  border-radius: 5px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
  // ,linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
  background-repeat: no-repeat, repeat;
  background-position: right .7em top 50%, 0 0;
  background-size: .65em auto, 100%;
  margin: 24px 0;

  &:hover {
    border-color: #888;
  }
}

//Button For submit on this modal
.modalSessionBalancer__inputQuota {
  &__submit {
    margin-top: 24px;
    width: 100%;
    height: 36px;
    font-size: 18px;
    font-weight: bold;
    border-radius: 5px;
    color: #fff;
    background-color: rgb(102, 214, 123);
    align-self: flex-end;
    border: none;
  }

  &__submit--success {
    @extend .modalSessionBalancer__inputQuota__submit;

  }

}


/*========================================
    CUSTOME CARD/TABLE STYLE FOR MODAL INPUT
========================================*/
// Custome style for MODAL_ASSIGN_DAILY
.quotaAssignDaily {
  &__header {
    >th {
      border-top: none;
    }
  }

  &__progress {
    display: flex;
    flex-direction: column;
    text-align: center;

    span {
      margin-bottom: 4px;
    }
  }

  &__quota,
  &__total {
    text-align: center;
  }

  &__quota {
    input {
      @extend .inputForModalQuota
    }
  }

  &__option {
    display: flex;
    justify-content: center;
  }
}

// Custome style for sub quota of MODAL_ASSIGN_DAILY
.subQuotaAssignDaily {
  background-color: #F6F6F6;

  &__navigation {
    display: flex;
    justify-content: space-between;
    padding: 12px;

    &__left {
      &__search {
        border: 1px solid #D0D0D0;
        border-radius: 9px;
        display: flex;
        align-items: center;
        padding: 4px;
        width: 220px;

        >input {
          border: none;
          padding: 4px;
          flex-grow: 1;
          background-color: transparent;
          cursor: pointer;
        }
      }

      >span {
        color: #828282;
        font-size: 12px;
      }
    }

    &__right {
      display: flex;
      align-items: center;

      >* {
        cursor: pointer;
      }

      >span {
        color: #E44D60;
        font-size: 12px;
        margin-right: 24px;
      }

      >button {
        color: #fff;
        font-weight: 500;
        font-size: 14px;
      }
    }
  }

  &__table {
    th {
      border-top: none;
    }

    td {
      border-bottom: 1px solid #979797;
    }

    th,
    td {
      width: 33.33%;
    }

    td:nth-child(2) {
      text-align: center;
    }

    td:nth-child(3) {
      text-align: right;
      color: #E44D60;
      font-size: 12px;

      span {
        cursor: pointer;
      }
    }



    input {
      @extend .inputForModalQuota;
      background-color: transparent;
    }
  }
}


// Custome style for MODAL_ASSIGN_MONTHLY
.quotaAssignMonthly {
  &__navigation {
    display: flex;
    padding-top: 12px;

    >span {
      flex-grow: 1;
      text-align: center;
      cursor: pointer;
    }

    &--active {
      border-bottom: 3px solid rgb(102, 214, 123);
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
      font-weight: 500;
    }
  }

  &__description {
    color: #A4A4A4;
    font-size: 12px;
    margin: 12px 0;
    padding: 0 12px;
  }

  &__table {

    th,
    td {
      width: 33.33%;
    }

    td:not(:first-child) {
      text-align: center;
    }

    input {
      @extend .inputForModalQuota
    }
  }
}

.inputForModalQuota {
  width: 100px;
  height: 32px;
  margin-right: 4px;
  border-radius: 5px;
  padding: 0 4px;
  border: 1px solid #D0D0D0;
}

.filterSubQuota {
  @include globalCustomScroll();

  &::-webkit-scrollbar {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  //re-styling default style from tool-tip plugin
  margin-left: 8px !important;
  width: 220px;
  height: 200px;
  overflow-y: auto;
  color: #1C1C1C !important;
  background-color: #fff !important;
  border-radius: 10px;

  &.place-top {
    &:after {
      border-top-color: #fff !important;
      border-top-style: solid !important;
      border-top-width: 6px !important;
    }
  }

  -webkit-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.75);

  p {
    padding: 10px 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:hover {
      background-color: #D8D8D8;
      border-radius: 5px;
      cursor: pointer;
    }
  }

}