.create-consultation {
    &__header {
        padding: 20px;
    }

    .wrapper-consultation {
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 0px;

        &__content {
            width: 330px;
            // height: 255px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            // box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.08);

            >img{
                cursor: pointer;
                width: 20%;
            }
        }

        &__contentMobile {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            padding: 60px 0px 0px 20px;
            // box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.08);

            >img{
                border-radius: 4px;
                cursor: pointer;
                width: 150px;
                height: 200px;
                object-fit: cover;
            }
        }
    }
    &__button {
        text-align: center;
        color: #CCCCCC;
        padding-top: 5px;
        margin: 0px 0px 0px 0px;
        cursor: pointer;
        border: 2px dashed #CCCCCC;
        border-radius: 12px;
        height: 36px;
        min-width: 400px;
    }
}