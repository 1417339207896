.tabDashboard {
   display: flex;
   grid-column-gap: 24px;

   width: 100%;
   margin-top: 32px;

   &__cards {
      flex-basis: 33%;

      .cardHeader {
         background: #FFFFFF;
         border: 1px solid #FAFAFA;
         border-radius: 10px 10px 0px 0px;
         padding: 13px 16px;

         .title {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.12px;
            color: #1C1C1C;
         }

         .action {
            display: flex;
            justify-content: space-between;
            align-items: center;

            button {
               background: #FFFFFF;
               border: 1px solid #17948C;
               box-sizing: border-box;
               border-radius: 22px;
               padding: 8px 12px;
               margin-top: 11px;

               font-style: normal;
               font-weight: normal;
               font-size: 12px;
               line-height: 17px;
               letter-spacing: 0.01em;
               color: #17948C;

               &:hover {
                  cursor: pointer;
                  background: #17948C;
                  border: 1px solid #FFFFFF;
                  color: #FFFFFF;
               }
            }
         }
      }
   }
}

.tabDashboard__cards {
   .cardWrapper {
      background: #E4E7E7;
      border: 1px solid #E4E7E7;
      border-radius: 0 0 10px 10px;
      padding: 17px 20px;

      display: flex;
      flex-direction: column;
      justify-content: space-around;

      margin-bottom: 5rem;

      .dates {
         display: flex;
         align-items: center;
         justify-content: space-between;
         margin-bottom: 15px;

         &__day {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
            letter-spacing: 0.03em;
            color: #798685;
         }

         &__date {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 14px;
            letter-spacing: 0.01em;
            color: #798685;
         }
      }

      .card {
         background: #FFFFFF;
         border: 1px solid #E4E7E7;
         border-radius: 10px;
         margin-bottom: 16px;

         display: flex;

         >a {
            display: flex;

            >div {
               padding: 12px 16px;
               display: flex;
               flex-direction: column;
               justify-content: flex-start;
            }
         }


         &__title {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
         }

         &__price {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
            letter-spacing: 0.03em;
            color: #17948C;
            margin-top: 5px;
         }

         &__created {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0.03em;
            color: #798685;
         }

         &__action {
            display: flex;
            margin-top: auto;
            width: fit-content;

         }

         &__button {
            display: flex;
            background: #17948C;
            border-radius: 8px;
            border: none;
            padding: 2px 8px;

            font-style: normal;
            font-weight: normal;
            font-size: 10px;
            line-height: 15px;
            letter-spacing: 0.155844px;
            color: #FFFFFF;

            &:not(:first-child) {
               margin-left: 8px;
            }
         }

         &__subTitle {
            font-style: normal;
            font-weight: 600;
            font-size: 10px;
            line-height: 12px;
            letter-spacing: 0.03em;
            color: #AFB6B6;
         }

         &__views {
            font-style: normal;
            font-weight: 600;
            font-size: 21px;
            line-height: 24px;
            letter-spacing: 0.015em;
            color: #17948C;
         }

         &__img {
            padding: 0 !important;
         }

         &__orderIMG {
            width: 45px;
            height: 45px;
            border-radius: 100%;
            object-fit: cover;
         }

         &__offerIMG {
            width: 79px;
            height: 79px;
            border-radius: 10px;
            object-fit: cover;
            padding: 0;
         }

         &__packageIMG {
            width: 113px;
            height: 115px;
            border: 1px solid #E4E7E7;
            border-radius: 10px 0px 0px 10px;
            object-fit: cover;
         }
      }
   }
}