@mixin user-create--content-title-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.12px;
    color: #1C1C1C;
}

@mixin user-create--primary-text($color : true) {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.12987px;
    color: #333333;

    @if $color {
        color: $color;
    }

    @else {
        color: #031615;
    }

}

@mixin user-create--secondary-text($color : true) {
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.3px;
    color: #798685;

    @if $color {
        color: $color;
    }

    @else {
        color: #031615;
    }
}

@mixin user-create--meta-text($color : true) {
    font-size: 10px;
    line-height: 15px;
    letter-spacing: 0.3px;


    @if $color {
        color: $color;
    }

    @else {
        color: #798685;
    }
}

/*===============================
        PAGE DETAIL USER 
===============================*/
.user-detail {

    &__header {
        display: flex;

        >span:nth-child(1) {
            font-weight: 600;
            font-size: 24px;
            line-height: 48px;
            letter-spacing: 0.01em;
            color: #000000;
            margin-right: 18px;
        }

        >span:nth-child(2) {
            font-weight: 600;
            font-size: 24px;
            line-height: 48px;
            letter-spacing: 0.01em;
            color: #AFB6B6;
            margin-right: 18px;

        }

        img {
            width: 40px;
            height: 44px;
            object-fit: cover;
            clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
        }
    }
}

/*===============================
        TAB USER INFO
===============================*/
.user-detail-info {
    display: flex;
    flex-direction: column;
}

//statistic container
.user-detail-info__statistic {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(calc(20% - 13px), 1fr));
    grid-column-gap: 16px;
    margin-bottom: 16px;

    //general style cards ons tatistic
    >div {
        background-color: white;
        border: 1px solid #E4E7E7;
        border-radius: 10px;
        padding: 12px;
        display: flex;
        align-items: center;
        min-height: 133px;

        >div {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            align-self: start;

            >span:nth-child(1) {
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                letter-spacing: 0.12px;
                color: #1C1C1C;
                margin-bottom: 10px;
            }

            >span:nth-child(2) {
                font-weight: 500;
                font-size: 24px;
                line-height: 36px;
                letter-spacing: 0.1px;
                color: #1C1C1C;
            }
        }

    }
}

//user info and user corporate container
.user-detail-info__user {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(calc(20% - 13px), 1fr));
    grid-column-gap: 16px;
    margin-bottom: 16px;



    >div {
        background: #FFFFFF;
        border: 1px solid #E4E7E7;
        border-radius: 10px;
        padding: 12px;
    }

    >div:nth-child(1) {
        grid-column: span 3;
    }

    >div:nth-child(2) {
        grid-column: span 2;
    }
}

//styling user info
.user-detail-info__user__user-info {
    display: flex;
    flex-direction: column;

    >div:nth-child(2) {
        display: flex;
        align-items: center;

        >img {
            width: 98px;
            height: 108px;
            object-fit: cover;
            clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
            margin: 0 66px;
        }

        >table {
            min-width: 413px;

            td {
                vertical-align: top;
                padding-bottom: 32px;
            }
        }
    }
}

//styling user info specific compoent
.user-detail-info__user__user-info {
    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        >span {
            @include user-create--content-title-text;
        }

        >div {
            display: flex;
            align-items: center;

            >svg:not(:last-child) {
                margin-right: 12px;
            }
        }

    }

    &__name,
    &__phone,
    &__mail {
        >div:nth-child(1) {
            @include user-create--primary-text;
        }

        >div:nth-child(2) {
            @include user-create--secondary-text;
        }

    }


    &__role {
        >div {
            display: flex;
            align-items: center;

            span {
                @include user-create--primary-text;
            }

            svg {
                width: 12px;
                height: 12px;
                margin-left: 12px;
            }
        }

        .roles {
            display: flex;
            align-items: center;
            border-radius: 8px;
            background: #2F80ED;
            width: fit-content;
            padding: 2.5px 8px;
            cursor: pointer;
            margin-top: 5px;


            >div:nth-child(1) {

                @include user-create--meta-text(#fff)
            }

            >i {
                margin-left: 8px;
            }
        }
    }

    // &__role {
    //     >div:nth-child(1) {
    //         display: flex;
    //         align-items: center;

    //         span {
    //             @include user-create--primary-text;
    //         }

    //         svg {
    //             width: 12px;
    //             height: 12px;
    //             margin-left: 12px;
    //         }
    //     }

    //     >div:nth-child(2) {
    //         display: flex;
    //         align-items: center;
    //         border-radius: 8px;
    //         background: #2F80ED;
    //         width: fit-content;
    //         padding: 2.5px 8px;
    //         cursor: pointer;


    //         >span:nth-child(1) {

    //             @include user-create--meta-text(#fff)
    //         }

    //         >i {
    //             margin-left: 8px;
    //         }
    //     }
    // }

    


    &__status {
        >div:nth-child(1) {
            @include user-create--primary-text;
        }

        >div:nth-child(2) {
            display: flex;
            align-items: center;
            border-radius: 8px;
            background: #2F80ED;
            width: fit-content;
            padding: 2.5px 8px;
            cursor: pointer;
            margin-top: 12px;
            position: relative;


            >span:nth-child(1) {

                @include user-create--meta-text(#fff)
            }

            >div:nth-child(2) {
                margin-top: 50px;
            }
        }

    }

}

// stling user corporate 
.user-detail-info__user__user-corp {
    display: flex;
    flex-direction: column;

    >div:nth-child(1) {
        display: flex;
        justify-content: space-between;
        align-items: center;

        >span {
            @include user-create--content-title-text;
        }

    }

    >table {
        width: 100%;

        td {
            vertical-align: top;
            padding-bottom: 32px;
        }
    }

    &__company,
    &__disc,
    &__benefit,
    &__position,
    &__tag,
    &__status {
        >div:nth-child(1) {
            @include user-create--primary-text;
        }

        >div:nth-child(2) {
            @include user-create--secondary-text()
        }
    }
}

.user-detail-info__user__user-trainer {
    display: flex;
    flex-direction: column;

    >div:nth-child(1) {
        @include user-create--content-title-text;
    }

    table td {
        vertical-align: top;
        padding-bottom: 32px;
    }

    &--capsule {
        display: flex;
        flex-wrap: wrap;


        >span {
            padding: 2px 12px;
            font-size: 10px;
            line-height: 17px;
            letter-spacing: 0.01em;
            color: #17948C;

            border: 1px solid #17948C;
            box-sizing: border-box;
            border-radius: 22px;

            margin-right: 6px;
            margin-bottom: 6px;
        }
    }

    &__name {
        >div {
            display: flex;
            flex-direction: column;

            >span:nth-child(1) {
                @include user-create--primary-text;
                margin-bottom: 8px;
            }

            >span:nth-child(2) {
                @include user-create--secondary-text;
            }
        }
    }

    &__achivement {
        >div {
            display: flex;
            flex-direction: column;

            >span:nth-child(1) {
                @include user-create--primary-text;
                margin-bottom: 8px;
            }

            >span:nth-child(2) {
                @include user-create--secondary-text;
            }
        }
    }

    &__sports {
        >div {
            display: flex;
            flex-direction: column;

            >span:nth-child(1) {
                @include user-create--primary-text;
                margin-bottom: 8px;
            }

        }
    }

    &__status {
        >div {
            display: flex;
            flex-direction: column;

            >span:nth-child(1) {
                @include user-create--primary-text;
                margin-bottom: 8px;
            }

        }
    }

    &__venue {
        >div {
            display: flex;
            flex-direction: column;

            >span:nth-child(1) {
                @include user-create--primary-text;
                margin-bottom: 8px;
            }

        }
    }

    &__classes {
        >div {
            display: flex;
            flex-direction: column;

            >span:nth-child(1) {
                @include user-create--primary-text;
                margin-bottom: 8px;
            }

        }
    }

}

// membership section
.user-detail-info__membership {

    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(calc(33% - 7px), 1fr));
    grid-column-gap: 16px;

    >div {
        background: #FFFFFF;
        border: 1px solid #E4E7E7;
        border-radius: 10px;
        padding: 12px 14px;

        >div:nth-child(1) {
            display: flex;
            justify-content: space-between;
            align-items: center;

            span {
                @include user-create--content-title-text;
            }
        }

        >table {
            width: 100%;

            td {
                vertical-align: top;
                padding-bottom: 32px;
            }
        }
    }
}

.user-detail-info__membership__trainer {
    &__type {
        >div {
            display: flex;
            flex-direction: column;

            >span:nth-child(1) {
                @include user-create--primary-text;
            }

            >span:nth-child(2) {
                background: #0E5854;
                border-radius: 8px;
                padding: 2.4px 8px;
                width: fit-content;
                @include user-create--meta-text(#fff)
            }
        }
    }

    &__status {
        >div {
            display: flex;
            flex-direction: column;

            >span:nth-child(1) {
                @include user-create--primary-text;
            }

            >span:nth-child(2) {
                background-color: #46AC89;
                padding: 2.4px 8px;
                border-radius: 8px;
                width: fit-content;
                @include user-create--meta-text(#fff)
            }

            >div {
                margin-top: 42px;
            }

        }
    }

    &__since,
    &__source {
        >div {
            display: flex;
            flex-direction: column;

            >span:not(:last-child) {
                margin-bottom: 4px;
            }

            >span:nth-child(1) {
                @include user-create--primary-text;
            }

            >span:nth-child(2) {
                @include user-create--secondary-text;
            }

            >span:nth-child(3) {
                @include user-create--meta-text;
            }
        }
    }

    &__benefit {
        width: 40%;
        display: flex;
        flex-direction: column;

        >span {
            @include user-create--content-title-text;
            margin-bottom: 12px;

        }

        &__progress {
            display: flex;
            flex-direction: column;
            margin-bottom: 8px;

            >div:first-child {
                @include user-create--content-title-text;
                margin-bottom: 8px;
            }



            >div:last-child {
                display: flex;
                align-items: center;
                margin-top: 8px;

                >span {
                    font-size: 10px;
                    line-height: 17px;
                    letter-spacing: 0.01em;
                    color: #031615;
                    margin-left: 13px;
                }
            }
        }

    }
}

.user-detail-info__membership__doofood {
    &__type {
        >div {
            display: flex;
            flex-direction: column;

            >span:nth-child(1) {
                @include user-create--primary-text;
            }

            >span:nth-child(2) {
                background: #EDAE0F;
                border-radius: 8px;
                padding: 2.4px 8px;
                width: fit-content;
                @include user-create--meta-text(#fff)
            }
        }
    }

    &__status {
        >div {
            display: flex;
            flex-direction: column;

            >span:nth-child(1) {
                @include user-create--primary-text;
            }

            >span:nth-child(2) {
                background-color: #46AC89;
                padding: 2.4px 8px;
                border-radius: 8px;
                width: fit-content;
                @include user-create--meta-text(#fff)
            }

            >div {
                margin-top: 42px;
            }

        }
    }

    &__since,
    &__source,
    &__transaction {
        >div {
            display: flex;
            flex-direction: column;

            >span:not(:last-child) {
                margin-bottom: 4px;
            }

            >span:nth-child(1) {
                @include user-create--primary-text;
            }

            >span:nth-child(2) {
                @include user-create--secondary-text;
            }

            >span:nth-child(3) {
                @include user-create--meta-text;
            }
        }
    }
}

/*==========================================
        TAB DOOFIT (its transaction)
==========================================*/
.user-detail-fit {
    display: flex;
    flex-direction: column;

    &__export {
        display: flex;
        flex-direction: row-reverse;
        margin-bottom: 15px;
        cursor: pointer;

        >div {
            padding: 5px 32px;
            background: #17948C;
            border: 1.2px solid #17948C;
            box-sizing: border-box;
            border-radius: 10px;

            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            text-align: center;
            letter-spacing: 0.03em;
            color: #FAFAFA;
        }
    }
}

//statistic container
.user-detail-fit__statistic {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(calc(20% - 13px), 1fr));
    grid-column-gap: 16px;
    margin-bottom: 16px;

    //general style cards ons tatistic
    >div {
        background-color: white;
        border: 1px solid #E4E7E7;
        border-radius: 10px;
        padding: 12px;
        display: flex;
        align-items: center;

        >div {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            height: 100%;
            align-self: start;

            >span:nth-child(1) {
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                letter-spacing: 0.12px;
                color: #1C1C1C;
                margin-bottom: 10px;
            }

            >span:nth-child(2) {
                font-weight: 500;
                font-size: 24px;
                line-height: 36px;
                letter-spacing: 0.1px;
                color: #1C1C1C;

            }
        }

    }

    &--increase {
        margin-top: 12px;
        display: flex;
        flex-grow: 1;
        display: flex;
        align-items: center;

        >* {
            align-self: flex-end;
        }

        >span {
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0.155844px;
            margin-right: 4px;
        }

        >span:nth-child(2) {
            color: #66D67B;
        }

        >span:nth-child(3) {
            color: #828282;
        }
    }

    &--decrease {
        @extend .user-detail-fit__statistic--increase;

        >span:nth-child(2) {
            color: #EB5757;
        }
    }
}

.user-detail-fit__table {
    padding: 20px 0;

    &__header {
        display: flex;
        align-items: center;
        padding: 0 16px 20px 16px;

        >svg {
            margin-right: 6px;
        }

        >div:nth-child(3) {
            font-size: 14px;
            line-height: 21px;
            text-align: center;
            letter-spacing: 0.181818px;
            color: #031615;
            margin-right: 18px;
        }

        >div:nth-child(2),
        >div:nth-child(4) {
            background: #FFFFFF;
            border: 1px solid #828282;
            box-sizing: border-box;
            border-radius: 8px;
            padding: 5.5px 8px;
            margin-right: 18px;

            >span {
                margin-right: 8px;
                font-size: 14px;
                line-height: 21px;
                text-align: center;
                letter-spacing: 0.181818px;
                color: #031615;
            }
        }

        >div:nth-child(5) {
            background: #17948C;
            border-radius: 10px;
            padding: 4px 8px;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            letter-spacing: 0.342857px;
            color: #FFFFFF;
        }
    }

    table tbody td {
        vertical-align: top;
    }

}


/*==========================================
        TAB DOOFOOD(its transaction)
==========================================*/
.user-detail-food {
    display: flex;
    flex-direction: column;

    &__export {
        display: flex;
        flex-direction: row-reverse;
        margin-bottom: 15px;
        cursor: pointer;

        >div {
            padding: 5px 32px;
            background: #17948C;
            border: 1.2px solid #17948C;
            box-sizing: border-box;
            border-radius: 10px;

            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            text-align: center;
            letter-spacing: 0.03em;
            color: #FAFAFA;
        }
    }
}

//statistic container
.user-detail-food__statistic {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(calc(20% - 13px), 1fr));
    grid-column-gap: 16px;
    margin-bottom: 16px;

    //general style cards ons tatistic
    >div {
        background-color: white;
        border: 1px solid #E4E7E7;
        border-radius: 10px;
        padding: 12px;
        display: flex;
        align-items: center;

        >div {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            height: 100%;
            align-self: start;

            >span:nth-child(1) {
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                letter-spacing: 0.12px;
                color: #1C1C1C;
                margin-bottom: 10px;
            }

            >span:nth-child(2) {
                font-weight: 500;
                font-size: 24px;
                line-height: 36px;
                letter-spacing: 0.1px;
                color: #1C1C1C;

            }
        }

    }

    &--increase {
        margin-top: 12px;
        display: flex;
        flex-grow: 1;
        display: flex;
        align-items: center;

        >* {
            align-self: flex-end;
        }

        >span {
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0.155844px;
            margin-right: 4px;
        }

        >span:nth-child(2) {
            color: #66D67B;
        }

        >span:nth-child(3) {
            color: #828282;
        }
    }

    &--decrease {
        @extend .user-detail-food__statistic--increase;

        >span:nth-child(2) {
            color: #EB5757;
        }
    }
}

.user-detail-food__table {
    padding: 20px 0;

    &__header {
        display: flex;
        align-items: center;
        padding: 0 16px 20px 16px;

        >svg {
            margin-right: 6px;
        }

        >div:nth-child(3) {
            font-size: 14px;
            line-height: 21px;
            text-align: center;
            letter-spacing: 0.181818px;
            color: #031615;
            margin-right: 18px;
        }

        >div:nth-child(2),
        >div:nth-child(4) {
            background: #FFFFFF;
            border: 1px solid #828282;
            box-sizing: border-box;
            border-radius: 8px;
            padding: 5.5px 8px;
            margin-right: 18px;

            >span {
                margin-right: 8px;
                font-size: 14px;
                line-height: 21px;
                text-align: center;
                letter-spacing: 0.181818px;
                color: #031615;
            }
        }

        >div:nth-child(5) {
            background: #17948C;
            border-radius: 10px;
            padding: 4px 8px;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            letter-spacing: 0.342857px;
            color: #FFFFFF;
        }
    }

    table tbody td {
        vertical-align: top;
    }
}

/*==========================================
        TAB TRAINER (its transaction)
==========================================*/
.user-detail-trainer {
    display: flex;
    flex-direction: column;
}

//statistic container
.user-detail-trainer__statistic {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(calc(20% - 13px), 1fr));
    grid-column-gap: 16px;
    margin-bottom: 16px;

    //general style cards ons tatistic
    >div {
        background-color: white;
        border: 1px solid #E4E7E7;
        border-radius: 10px;
        padding: 12px;
        display: flex;
        align-items: center;

        >div {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            height: 100%;
            align-self: start;

            >span:nth-child(1) {
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                letter-spacing: 0.12px;
                color: #1C1C1C;
                margin-bottom: 10px;
            }

            >span:nth-child(2) {
                font-weight: 500;
                font-size: 24px;
                line-height: 36px;
                letter-spacing: 0.1px;
                color: #1C1C1C;

            }
        }

    }

    &--increase {
        margin-top: 12px;
        display: flex;
        flex-grow: 1;
        display: flex;
        align-items: center;

        >* {
            align-self: flex-end;
        }

        >span {
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0.155844px;
            margin-right: 4px;
        }

        >span:nth-child(2) {
            color: #66D67B;
        }

        >span:nth-child(3) {
            color: #828282;
        }
    }

    &--decrease {
        @extend .user-detail-trainer__statistic--increase;

        >span:nth-child(2) {
            color: #EB5757;
        }
    }
}

.user-detail-trainer__export {
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 15px;
    cursor: pointer;

    >div {
        padding: 5px 32px;
        background: #17948C;
        border: 1.2px solid #17948C;
        box-sizing: border-box;
        border-radius: 10px;

        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.03em;
        color: #FAFAFA;
    }
}

//trainer table
.user-detail-trainer__table {
    padding: 20px 0;

    &__header {
        display: flex;
        align-items: center;
        padding: 0 16px 20px 16px;

        >svg {
            margin-right: 6px;
        }

        >div:nth-child(3) {
            font-size: 14px;
            line-height: 21px;
            text-align: center;
            letter-spacing: 0.181818px;
            color: #031615;
            margin-right: 18px;
        }

        >div:nth-child(2),
        >div:nth-child(4) {
            background: #FFFFFF;
            border: 1px solid #828282;
            box-sizing: border-box;
            border-radius: 8px;
            padding: 5.5px 8px;
            margin-right: 18px;

            >span {
                margin-right: 8px;
                font-size: 14px;
                line-height: 21px;
                text-align: center;
                letter-spacing: 0.181818px;
                color: #031615;
            }
        }

        >div:nth-child(5) {
            background: #17948C;
            border-radius: 10px;
            padding: 4px 8px;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            letter-spacing: 0.342857px;
            color: #FFFFFF;
        }
    }

    table tbody td {
        vertical-align: top;
    }
}

//styling each column 
.user-detail-trainer__table {
    &__bookings {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.3px;
        color: #031615;
    }
}