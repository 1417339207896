/*========================================
            TABLE CONTAINER
========================================*/
.tableDashboard {
    background-color: #fff;
    border-radius: 10px;
}


/*========================================
          TABLE NAVIGATION
========================================*/
.tableDashboard__navigations {
    display: flex;
    flex-direction: row;
    align-items: center;
    // border-bottom: 1px solid #E4E7E7;
    // margin-bottom: 10px;

    @media only screen and (max-width : 961px) {
        overflow-x: scroll;
    }
}

.topContainer {
    margin-top: 12px;
}

.tableDashboard__navigation {
    white-space: nowrap;
    padding: 18px 50px;
    color: rgba($color: #4F4F4F, $alpha: 0.3);
    border-bottom: 5px solid transparent;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.342857px;
    text-transform: uppercase;
}

.tableDashboard__date-state {
    display: flex;

    &__btn {
        background: #FFFFFF;
        border: 1px solid #17948C;
        box-sizing: border-box;
        border-radius: 22px;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 17px;
        letter-spacing: 0.01em;
        color: #17948C;
        padding: 8px 12px;
        cursor: pointer;

        &:hover {
            background: #17948C;
            color: #fff;
        }

        &:not(:first-child) {
            margin-left: 16px;
        }
    }

    .typeBasic {
        background: #FFFFFF;
        border: 1px solid #17948C;
        box-sizing: border-box;
        border-radius: 8px;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 10px;
        letter-spacing: 0.01em;
        color: #17948C;
        padding: 8px 12px;
    }

    .typePremium {
        background: #FFFFFF;
        border: 1px solid #f6c573;
        box-sizing: border-box;
        border-radius: 8px;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 10px;
        letter-spacing: 0.01em;
        color: #f6c573;
        padding: 8px 12px;
    }

    &__btn--active {
        @extend .tableDashboard__date-state__btn;
        background: #17948C;
        color: #fff;
    }
}

.tableDashboard__navigation--active {
    @extend .tableDashboard__navigation;
    border-bottom: 5px solid #17948C;
    color: #17948C !important;
}

//LEGACY
.tableDashboard__content__navigation {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #E4E7E7;
    margin-bottom: 10px;
    text-transform: uppercase;

    @media only screen and (max-width : 961px) {
        overflow-x: scroll;
    }

    >a {
        white-space: nowrap;
        font-weight: bold;
        font-size: 16px;

        padding: 18px 50px;
        color: rgba($color: #4F4F4F, $alpha: 0.3);
    }
}

.tableDashboard__content__navigation--active {
    border-bottom: 5px solid #17948C;
    color: #17948C !important;
    text-transform: uppercase;
}



/*========================================
            TABLE LIST CONTENT
========================================*/
.listInformation {
    border-collapse: collapse;
    min-width: 100%;

    table {
        width: 100%;
    }

    th {
        padding: 20px 5px;
        border-top: 1px solid #E4E7E7;
        text-align: left;
    }

    th {
        border-bottom: 1px solid #C9CFCE;
        color: #031615;
        font-weight: 500;
        font-size: 10px;
        letter-spacing: 0.181818px;
        text-transform: uppercase;
    }

    thead th,
    tbody td {
        padding: 12px 8px;
    }

    td {
        border-bottom: 1px solid #E4E7E7;
        font-size: 12px;
        font-weight: 500;
        word-wrap: break-word;
        & .smallText {
            font-size: 10px;
            color: #4F4F4F;
        }
    }

    &__filtered {
        display: flex;
        align-items: center;
        padding: 6px 5px;

        &__data {
            font-weight: 600;
            font-size: 10px;
            color: #4F4F4F;
        }

        &__filter {
            display: flex;
            align-items: center;
            font-weight: 500;
            font-size: 10px;
            color: #66D67B;
            margin-left: 8px;
        }

        &__closeFilter {
            margin-left: 8px;
            cursor: pointer;
        }
    }

    // td:not(:last-child){
    // 	border-right: 1px solid #EAEAEA;
    // }
}

@mixin listInformation__text--primary {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.3px;
    color: #031615;
}

@mixin listInformation__text--secondary($color:false) {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 15px;
    letter-spacing: 0.3px;

    @if($color) {
        color: $color;
    }

    @else {
        color: #4F4F4F;
    }
}

.listInformation--empty-state {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px;

    >img {
        width: 150px;
        height: 150px;
    }

    >div:nth-of-type(1) {
        @include listInformation__text--primary;
    }

    >div:nth-of-type(2) {
        @include listInformation__text--secondary;
    }

}

/*========================================
        MERCHANT DETAIL TABLE HEADER
========================================*/
.merchantDetailTable {
    padding: 10px 18px 0;

    svg {
        cursor: pointer;
    }

    td {
        vertical-align: top;
    }

    &__header {
        display: flex;
        align-items: center;
        background: #E4E7E7;
        padding: 14px 13px;

        &__title {
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            letter-spacing: 0.181818px;
            color: #1C1C1C;
            text-transform: uppercase;
            flex: 1;
        }

        &__action {
            display: flex;
            align-items: center;

            >a:not(:first-child) {
                margin-left: 16px;
            }

            &__button {
                display: flex;
                align-items: center;
                background: #17948C;
                border: 1.2px solid #17948C;
                box-sizing: border-box;
                border-radius: 10px;
                padding: 9px 8px;
                cursor: pointer;

                >button {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 24px;
                    letter-spacing: 0.03em;
                    color: #FAFAFA;
                    border: none;
                    background-color: transparent;
                    cursor: pointer;
                }
            }
        }
    }

    &__action {
        background: #FFFFFF;
        padding: 24px 11px;
        margin-top: 20px;

        &__btn {
            background: #FFFFFF;
            border: 1px solid #17948C;
            box-sizing: border-box;
            border-radius: 22px;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 17px;
            letter-spacing: 0.01em;
            color: #17948C;
            padding: 8px 12px;
            cursor: pointer;

            &:hover {
                background: #17948C;
                color: #fff;
            }

            &:not(:first-child) {
                margin-left: 16px;
            }
        }

        &__btn--active {
            @extend .merchantDetailTable__action__btn;
            background: #17948C;
            color: #fff;
        }
    }

    &__action.classDetail {
        margin-top: 0;
    }

    &__dates {
        display: flex;
        align-items: center;
        padding: 20px 16px 0;

        >div {
            display: flex;
            align-items: center;
            border: 1px solid #828282;
            box-sizing: border-box;
            border-radius: 8px;
            padding: 5px 8px;
            cursor: pointer;
        }

        >span {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.181818px;
            color: #031615;
            text-transform: uppercase;
            margin: 0 18px;
        }

        >svg {
            cursor: pointer;
            margin-right: 6px;
        }

        >button {
            margin-left: 18px;
            background: #17948C;
            border-radius: 10px;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.342857px;
            color: #FFFFFF;
            border: none;
            padding: 6px 8px;
            text-transform: uppercase;
            cursor: pointer;
        }
    }
}

/*========================================
        MERCHANT DETAIL TABLE HEADER
========================================*/
.tableData {

    &__text {
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.3px;
        color: #828282;
        word-wrap: break-word;

        .title {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0.3px;
            color: #4F4F4F;
        }

        .textCenter {
            text-align: center;
        }

        .nowrap {
            white-space: nowrap;
        }

        .ellipsis {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }


        .maxWidth {
            max-width: 10rem;
        }
    }

    &__filter {
        >td {
            >div {
                display: flex;
                align-items: center;

                >div {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 10px;
                    line-height: 15px;
                    letter-spacing: 0.3px;
                    color: #4F4F4F;
                    margin-right: 24px;

                    >span {
                        font-weight: 600;
                    }
                }

                >span {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 10px;
                    line-height: 15px;
                    letter-spacing: 0.3px;
                    color: #66D67B;
                    margin-right: 8px;
                }

                >svg {
                    cursor: pointer;
                }
            }
        }
    }

    &__custom {
        &__dates {
            min-width: 10rem;
        }

        &__rating {
            >div {
                min-width: max-content;
            }
        }

        &__review {
            text-align: left;
            min-width: 25rem;
        }

        &__title {
            >div:nth-child(1) {
                font-weight: 600;
                color: #4F4F4F;
            }
        }

        &__offerType {
            text-transform: capitalize;
        }

        &__classType {
            &__true {
                background: rgba($color: #1F7899, $alpha: 0.3);
                border-radius: 5px;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 14px;
                letter-spacing: 0.155844px;
                color: #000000;
                vertical-align: middle !important;
            }

            &__false {
                background: rgba($color: #EB5757, $alpha: 0.3);
                border-radius: 5px;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 14px;
                letter-spacing: 0.155844px;
                color: #000000;
                vertical-align: middle !important;
            }
        }

        &__statusGuest {
            border-radius: 8px;
            font-style: normal;
            font-weight: normal;
            font-size: 10px;
            line-height: 15px;
            letter-spacing: 0.155844px;
            color: #FFFFFF;
            text-transform: capitalize;
            padding: 2px 8px;
        }
    }
}

.statusCancelled {
    background: #8F2D56;
}

.statusConfirmed {
    background: #335C67;
}

.statusFinished {
    background: #46AC89;
}

.statusRejected {
    background: #A30015;
}

.statusPending {
    background: #E0CA3C;
}

.statusPublished {
    background: #46AC89;
}

.statusUnpublished {
    background: #BDBDBD;
}