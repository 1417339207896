.emptyClass{
   display: flex;
   justify-content: center;
   background: #FFFFFF;
   &__card{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: #FFFFFF;
      opacity: 0.9;
      border: 1px solid #E4E7E7;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      padding: 39px 75px;
      margin-top: 20px;
      margin-bottom: 30px;
      >div{
         font-style: normal;
         font-weight: 600;
         font-size: 17px;
         line-height: 25px;
         text-align: center;
         letter-spacing: 0.01em;
         color: #031615;
         margin-top: 16px;
      }
      >span{
         font-style: normal;
         font-weight: 500;
         font-size: 12px;
         line-height: 18px;
         letter-spacing: 0.207792px;
         color: #798685;
      }
      >button{
         font-style: normal;
         font-weight: 500;
         font-size: 14px;
         line-height: 24px;
         text-align: center;
         letter-spacing: 0.03em;
         color: #FAFAFA;
         background: #17948C;
         border: 1.2px solid #17948C;
         box-sizing: border-box;
         border-radius: 10px;
         padding: 5px 11px;
         cursor: pointer;
         margin-top: 15px;
      }
   }
}
