.dashboardMerchant{
   display: flex;
   justify-content: space-between;
   padding: 10px 18px 0;
   &__cards{
      flex-basis: 32%;
      &__header{
         background: #FFFFFF;
         border: 1px solid #FAFAFA;
         border-radius: 10px 10px 0px 0px;
         padding: 13px 16px;
         >div:nth-child(1){
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.12px;
            color: #1C1C1C;
            margin-bottom: 11px;
         }
         >div:nth-child(2){
            display: flex;
            justify-content: space-between;
            >button{
               font-style: normal;
               font-weight: normal;
               font-size: 12px;
               line-height: 17px;
               letter-spacing: 0.01em;
               color: #17948C;
               background: #FFFFFF;
               border: 1px solid #17948C;
               box-sizing: border-box;
               border-radius: 22px;
               padding: 8px 12px;
               cursor: pointer;
            }
         }
      }
      &__content{
         background: #E4E7E7;
         border: 1px solid #E4E7E7;
         border-radius: 0 0 10px 10px;
         padding: 20px 21px;
         >div:not(:last-child){
            margin-bottom: 16px;
         }
         &__container{
            background-color: transparent;
            >div:nth-child(1){
               display: flex;
               justify-content: space-between;
               align-items: center;
               >div:nth-child(1){
                  font-style: normal;
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 19px;
                  letter-spacing: 0.03em;
                  color: #798685;
               }
               >div:nth-child(2){
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 14px;
                  letter-spacing: 0.01em;
                  color: #798685;
               }
            }
         }
         &__wrapper{
            >div:not(:last-child){
               margin-bottom: 16px;
            }
         }
         &__card.package{
            height: 135px;
            background: #FFFFFF;
            >div:nth-child(1){
               >img{
                  height: 133px;
                  position: absolute;
                  border-radius: 10px 0px 0px 10px;
               }
            }
            >div:nth-child(2){
               >div:nth-child(1){
                  margin-left: 88px;
               }
            }
         }
         &__card.offers{
            height: 81px;
            background: #FFFFFF;
            >div:nth-child(1){
               >img{
                  height: 79px;
                  position: absolute;
                  border-radius: 10px;
               }
            }
            >div:nth-child(2){
               >div:nth-child(1){
                  margin-left: 88px;
               }
            }
         }
         &__card.latest.today{
            background: transparent;
            margin-bottom: 0;
         }
         &__card.latest{
            display: flex;
            align-items: center;
            height: 81px;
            padding: 11px;
            background: #FFFFFF;
            >div:nth-child(1){
               >img{
                  height: 45px;
                  width: 45px;
                  border-radius: 100%;
                  object-fit:cover;
               }
            }
         }
         &__card{
            background: #FFFFFF;
            border: 1px solid #E4E7E7;
            border-radius: 10px;
            display: flex;
            position: relative;
            &__text{
               display: flex;
               align-items: center;
               justify-content: space-between;
               padding: 17px;
               width: 100%;
               >div:nth-child(1){
                  >div{
                     margin-bottom: 2px;
                  }
                  >div:nth-child(1){
                     font-style: normal;
                     font-weight: 600;
                     font-size: 14px;
                     line-height: 14px;
                     letter-spacing: 0.03em;
                     color: #333333;
                  }
                  >div:nth-child(2){
                     font-style: normal;
                     font-weight: normal;
                     font-size: 12px;
                     line-height: 17px;
                     letter-spacing: 0.01em;
                     color: #828282;
                  }
                  >div:nth-child(3){
                     font-style: normal;
                     font-weight: 600;
                     font-size: 14px;
                     line-height: 14px;
                     letter-spacing: 0.03em;
                     color: #17948C;
                  }
               }
               >div:nth-child(2){
                  >div{
                     margin-bottom: 2px;
                  }
                  >div:nth-child(1){
                     font-style: normal;
                     font-weight: 600;
                     font-size: 10px;
                     line-height: 12px;
                     letter-spacing: 0.03em;
                     color: #AFB6B6;
                     text-transform: uppercase;
                  }
                  >div:nth-child(2){
                     font-style: normal;
                     font-weight: 600;
                     font-size: 21px;
                     line-height: 24px;
                     letter-spacing: 0.015em;
                     color: #17948C;
                  }
               }
            }
         }
      }
   }
}