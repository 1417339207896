.basicStylingInput {
    background: transparent;
    color: #17948C;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.01em;
}


.input {
    @extend .basicStylingInput;
    width: 343px;
    padding: 14px 16px;
    border: none;
    background: #F2F3F3;
}

.input--bordered {
    border: 1px solid #C9CFCE;
    border-radius: 10px;
}

.input--full {
    width: 100%;
}

.textarea {
    @extend .basicStylingInput;
    min-width: 343px;
    min-height: 170px;
    padding: 14px 16px;
}

.selectCss {
    @extend .basicStylingInput;
    display: block;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-image: url('../../../assets/images/icon/arrow-down.svg');
    border-radius: 10px;

    background-repeat: no-repeat, repeat;
    background-position: right .7em top 50%, 0 0;
    padding: 14px 16px;
}

.selectCss--stretch {
    @extend .selectCss;
    background-color: #F2F3F3;
    width: 100%;
    background-image: url('../../../assets/images/icon/arrow-down.png');
    border: 1px solid #C9CFCE;
}

.customSelect {
    @extend .basicStylingInput;
    background-color: #F2F3F3;
    padding: 14px 16px;
    border: 1px solid #C9CFCE;
    border-radius: 10px;
    position: relative;
    input {
        border: none;
        background: transparent;
        padding: 0;
        width: 100%;
        color: #17948C !important;
        &::placeholder {
            color: #17948c !important;
        }
    }
    button {
        border: none;
        outline: none;
        background-color: none;
        font-size: 10px;
        position: absolute;
        top: 50%;
        right: 14px;
        transform: translateY(-50%);
        color: #17948C !important;
    }
    & .loader {
        position: absolute;
        top: 50%;
        right: 50px;
        transform: translateY(-50%);
        color: #17948C !important;
    }
    & .suggestions {
        position: absolute;
        width: 100%;
        height: 100px;
        overflow: auto;
        padding: 0.5rem 0;
        background-color: white;
        top: calc(100% + 0.5rem);
        left: 0;
        border-radius: 5px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        &__item {
            padding: 0.3rem 1rem;
            cursor: pointer;
            &:hover {
                background-color: #ececec;
            }
        }
    }
}

.selectCss:-ms-expand {}

.selectCss:hover {}

.selectCss:focus {}

.selectCss option {}

.selectCss--emptyState {
    background-color: rgb(214, 214, 214);
    color: rgb(80, 80, 80);
}

.inputSplited {
    @extend .basicStylingInput;
    display: flex;
    width: 343px;
    // padding: 14px 16px;
}

.inputSplited--left {
    background-color: #fff;
    border-radius: 10px;
    border-right: 1px solid #C9CFCE;
    padding: 14px 16px;
}

.inputSplited--right {
    @extend .input;
    border: none;
}