.trainer{   
    &__title{
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.0709534px;
        color: #000000;
        margin-bottom: 8px;
        margin-top: 24px;
    }

    &__list{
        display: flex;
        flex-direction: column;
        border: 1px solid #C9CFCE;
        padding: 12px 16px;
        border-radius: 10px;
        margin-bottom: 10px;
        margin-top: 10px;

        &__card{
            border: 1px solid #C9CFCE;
            padding: 12px 16px;
            border-radius: 10px;
            margin-bottom: 10px;

            &__input{
                display: flex;
                justify-content: space-between;
                align-items: center;

                &__qty{
                    margin-bottom: 8px;
                    background: #F2F3F3;
                    border: 1px solid #C9CFCE;
                    box-sizing: border-box;
                    border-radius: 10px;
                    width: 60px;
                    padding:10px 16px;
                    align-items: center;
                    color: #17948C;
                    font-size: 12px;
                }
                
                &__desc{
                    margin-bottom: 8px;
                    background: #F2F3F3;
                    border: 1px solid #C9CFCE;
                    box-sizing: border-box;
                    border-radius: 10px;
                    width: 280px;
                    padding:10px 10px;
                    align-items: center;
                    font-size: 12px;
                    color: #17948C;

                    >select{
                        width: 100%;
                        border: none;
                        background: #F2F3F3;
                    }
                }
    
                >svg{
                    cursor: pointer;
                }
            }

            &__input2{
                margin-top: 6px;
                >textarea{
                    background: #F2F3F3;
                    border: 1px solid #C9CFCE;
                    box-sizing: border-box;
                    border-radius: 10px;
                    width: 100%;
                    padding:10px 16px;
                    color: #17948C;
                    font-size: 12px;
                }
            }
        }
    }
}