.createPackage {
   display: flex;

   &__image {
      img {
         width: 100% !important;
         height: 170px;
         object-fit: cover;
      }
   }

   &__mobile {
      background: #FFFFFF;
      border: 1px solid #E4E7E7;
      border-radius: 10px;
      height: fit-content;
   }

   &__form {
      background: #FFFFFF;
      border: 1px solid #E4E7E7;
      border-radius: 10px;
      padding: 24px;
      width: 60%;
      height: fit-content;
      margin-right: 53px;
   }

   &__title {
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 24px;
      letter-spacing: 0.03em;
      color: #000000;
      margin-bottom: 32px;
   }

   &__wrapper {
      padding-right: 53px;

      >.title {
         font-style: normal;
         font-weight: 500;
         font-size: 14px;
         line-height: 21px;
         letter-spacing: 0.0709534px;
         color: #000000;
         margin-bottom: 8px;
      }

      >input {
         background: #F2F3F3;
         border: 1px solid #C9CFCE;
         box-sizing: border-box;
         border-radius: 10px;
         padding: 14px 16px;
         margin-bottom: 16px;

         font-style: normal;
         font-weight: normal;
         font-size: 12px;
         line-height: 17px;
         letter-spacing: 0.01em;
         color: #17948C;
         width: 100%;

         &::placeholder {
            color: #17948C;
         }
      }

      >textarea {
         background: #F2F3F3;
         border: 1px solid #C9CFCE;
         box-sizing: border-box;
         border-radius: 10px;
         padding: 12px 20px;
         width: 100%;
         min-height: 170px;
         margin-bottom: 16px;

         font-style: normal;
         font-weight: normal;
         font-size: 12px;
         line-height: 17px;
         letter-spacing: 0.01em;
         color: #17948C;

         &::placeholder {
            color: #17948C;
         }
      }
   }

   &__capsules {
      display: flex;
      align-items: center;
      background: #F2F3F3;
      border: 1px solid #C9CFCE;
      box-sizing: border-box;
      border-radius: 10px;
      margin-bottom: 16px;

      .icon {
         display: flex;
         background: #FFFFFF;
         border: 1px solid #C9CFCE;
         box-sizing: border-box;
         border-radius: 10px;
         padding: 12px;
         margin-right: 16px;

         svg {
            path {
               fill: #17948C;
            }
         }
      }

      >.title {
         font-style: normal;
         font-weight: bold;
         font-size: 12px;
         line-height: 17px;
         letter-spacing: 0.01em;
         color: #17948C;

         background: #FFFFFF;
         border: 1px solid #C9CFCE;
         box-sizing: border-box;
         border-radius: 10px;
         padding: 13px 12px;
         margin-right: 16px;
      }

      >input {
         font-style: normal;
         font-weight: normal;
         font-size: 12px;
         line-height: 17px;
         letter-spacing: 0.01em;
         color: #17948C;
         border: none;
         background-color: transparent;

         &::placeholder {
            color: #17948C;
         }
      }
   }

   &__dropdown {
      display: flex;
      align-items: center;
      margin-bottom: 16px;

      >input {
         background: #F2F3F3;
         border: 1px solid #C9CFCE;
         box-sizing: border-box;
         border-radius: 10px;
         width: 50px;
         height: 50px;
         margin-right: 16px;

         font-style: normal;
         font-weight: 500;
         font-size: 14px;
         line-height: 21px;
         letter-spacing: 0.0709534px;
         color: #17948C;
         text-align: center;
      }

      &__wrapper {
         display: flex;
         align-items: center;

         >.title {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.0709534px;
            text-decoration-line: underline;
            color: #17948C;
            margin-right: 7px;
         }

         svg {
            cursor: pointer;

            path {
               fill: #17948C;
            }
         }
      }

   }

   &__card {
      margin-bottom: 16px;

      >.title {
         font-style: normal;
         font-weight: 600;
         font-size: 14px;
         line-height: 19px;
         text-align: center;
         letter-spacing: 0.03em;
         color: #000000;

         background: #FFFFFF;
         border: 1px solid #C9CFCE;
         border-radius: 10px 10px 0 0;
         padding: 14px 0;
      }

      .content {
         background: #F2F3F3;
         border: 1px solid #C9CFCE;
         border-top: none;
         box-sizing: border-box;
         border-radius: 0 0 10px 10px;
         padding: 15px 14px;

         &__body {
            display: flex;

            &.center {
               align-items: center;

               .iconAdd {
                  cursor: pointer;
                  margin-left: 23px;
                  margin-top: 8px;
               }
            }

            &__wrapper {

               &.primary {
                  width: 50%;
               }

               &.secondary {
                  margin-left: 12px;

                  input {
                     background: #FFFFFF;
                     border: 1px solid #C9CFCE;
                     box-sizing: border-box;
                     border-radius: 10px;
                     padding: 16px 12px;
                     width: 76px;
                     text-align: center;
                     margin-top: 8px;
                  }
               }

               >.title {
                  font-style: normal;
                  font-weight: normal;
                  font-size: 12px;
                  line-height: 18px;
                  letter-spacing: 0.3px;
                  color: #000000;
               }

               &__dropdown {
                  display: flex;
                  justify-content: space-between;
                  border: 1px solid #C9CFCE;
                  padding: 14px 12px;
                  box-sizing: border-box;
                  border-radius: 10px;
                  margin-top: 8px;
                  margin-bottom: 15px;
                  cursor: pointer;

                  &.fill {
                     background: #FFFFFF;
                  }

                  >.title {
                     font-style: normal;
                     font-weight: normal;
                     font-size: 12px;
                     line-height: 17px;
                     display: flex;
                     align-items: center;
                     letter-spacing: 0.01em;
                     color: #17948C;
                     border: none;
                     width: 100%;
                  }

                  svg {
                     width: 24px;

                     path {
                        fill: #17948C;
                     }
                  }
               }
            }
         }

         &__footer {
            display: flex;
            justify-content: flex-end;

            button {
               border: 1px solid #17948C;
               box-sizing: border-box;
               border-radius: 10px;
               background: transparent;
               padding: 8px 16px;

               font-style: normal;
               font-weight: normal;
               font-size: 12px;
               line-height: 17px;
               display: flex;
               align-items: center;
               letter-spacing: 0.01em;
               color: #17948C;
               cursor: pointer;

               &:nth-child(2) {
                  background: #17948C;
                  color: #FFFFFF;
                  margin-left: 16px;
               }
            }
         }
      }
   }

   &__button {
      width: 343px;
   }
}

// PREVIEW MOBILE
.packagePreview {
   &__margin {
      margin-top: 25px;
   }

   &__actions {
      padding: 16px 25px;

      button {
         background: #17948C;
         border-radius: 10px;
         width: 100%;
         padding: 16px 70px;
         border: none;
         cursor: pointer;

         font-style: normal;
         font-weight: 600;
         font-size: 18px;
         line-height: 24px;
         text-align: center;
         letter-spacing: 0.03em;
         color: #FFFFFF;
      }
   }

   &__actionsConsultation {
      padding: 15px 10px 0px 5px;

      button {
         background: #E7F8FE;
         border-radius: 12px;
         width: 100%;
         // padding: 16px 70px;
         border: 1px solid transparent;
         cursor: pointer;
         height: 40px;
         font-style: normal;
         font-weight: 600;
         font-size: 13px;
         line-height: 24px;
         text-align: center;
         letter-spacing: 0.03em;
         color: #337F99;
      }
   }

   &__title {
      margin-top: 16px;
   }

   &__capsules {
      >div {
         border: 1px solid #828282 !important;
         color: #828282 !important;
      }
   }

   &__price {
      display: flex;
      align-items: center;
      padding: 0 22px;
   }

   &__discount {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 24px;
      letter-spacing: 0.03em;
      text-decoration-line: line-through;
      color: #828282;
   }

   &__price {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 0.03em;
      color: #17948C;
   }
}