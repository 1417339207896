$sidebarSize : 230px; // 70px

/* ====================================
            HEADER SECTION
=======================================*/
.layoutDashboard__header {
    width: 100%;
    height: 80px;
    padding: 21px 20px 21px 20px;
    background-color: #ffffff;
    position: fixed;
    top: 0;
    z-index: 90;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px -5px 20px rgba(128, 128, 128, 0.5);

    &__leftSide {
        display: flex;
        align-items: center;

        img {
            margin-right: 62px;

            @media only screen and (max-width : 961px) {
                display: none;
            }
        }
    }

    &__rightSide {
        display: flex;
        align-items: center;

        >div:not(:last-child) {
            margin-right: 15px;
        }

        .layoutDashboard__header__rightSide__name {
            display: flex;
            flex-direction: column;
            text-align: end;
        }

        img {
            clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
            width: 30.7pt;
            height: 37.6pt;
            object-fit: cover;
            border-radius: 12px;
        }
    }
}

.layoutDashboard__header__rightSide__notification {
    display: flex;

    >i {
        svg {
            width: 40px;
            height: 40px;
        }
    }

    >span {
        height: 23px;
        width: 23px;
        border-radius: 23px;
        background-color: rgb(228, 77, 96);
        color: #ffffff;
        margin: -4px 0px 0px -16px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}


/* ====================================
            MAIN SECTION
=======================================*/
.contentLayoutHome {
    display: flex;
    margin-top: 80px;
    min-height: calc(100vh - 80px);
}

.mainContent {
    background-color: #F2F3F3;
    margin-left: $sidebarSize;
    font-size: 0.875rem;
    min-height: calc(100vh - 80px);
    width: calc(100% - 230px);
}


/* ====================================
            SIDEBAR STYLING
=======================================*/
// CONTEINER
.layoutDashboard__sidebar {
    @include globalCustomScroll;
    position: fixed;
    z-index: 600;
    color: #fff;
    height: 100vh;
    display: flex;
    flex: 1;

    &__innerScroll {
        display: flex;
        flex-direction: column;
        width: $sidebarSize;
        overflow-y: auto;
        background-color: #0F0F0F;
    }

    &__innerScroll__topSide {
        background-color: #031615;
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    &__innerScroll__bottomSide {
        background-color: #031615;
        padding-bottom: 24px;
        margin-bottom: 80px; // this value inherite from header -> main content margin-top
    }
}

// NAVIGATION
.layoutDashboard__sidebar {
    &__mainNav {
        display: flex;
        justify-content: center;
        flex-direction: column;

        >:nth-child(1) {
            padding: 16px 25px;
            display: flex;
            align-items: center;
            color: #F2F3F3;

            i {
                margin-right: 14px
            }

            span {
                font-weight: 300;
                font-size: 16px;
            }

            svg path {
                fill: #fff;
            }
        }
    }

    &__mainNav--active {
        >:nth-child(1) {
            border-left: 5px solid rgb(102, 214, 123);

            span {
                font-weight: 500;
            }

            color: rgb(102, 214, 123);

            svg path {
                fill: rgb(102, 214, 123);
            }
        }
    }

    &__subNav {
        >div {
            display: flex;
            align-items: center;
            margin-left: 71px;
            font-size: 14px;
            color: rgba(251, 254, 254, 0.5);
            padding: 16px 0;
        }
    }

    &__subNav--active {
        >div {
            color: #fff;
            font-weight: 500;
        }

        background: #000000;
    }
}

.modal-account {
    margin-top: 50px;
    margin-right: 40px;
    padding: 10px !important;
    border-radius: 10px;
    align-items: flex-start;
    justify-content: flex-start;

    >div {
        width: 100%;
        padding: 10px;
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }


    >div:not(:last-child) {
        margin-bottom: 0px !important;
        border-bottom: 1px solid #ddd;
    }

    >div:hover {
        background-color: #ddd;
    }

    .active-role {
        color: rgb(102, 214, 123);
    }
}

.modal-role {
    position: absolute;
    top: 64% !important;
    right: 16% !important;
    padding: 10px !important;
    border-radius: 10px;

    >div {
        width: 100%;
        padding: 10px;
        border-radius: 10px;
        margin-bottom: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    >div:not(:last-child) {
        margin-bottom: 5px !important;
        border-bottom: 1px solid #ddd;
    }

    >div:hover {
        background-color: #ddd;
    }

    .active-role {
        color: rgb(102, 214, 123);
    }
}

.actionModalContent {
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}