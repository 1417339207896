.tableFiltering {
   padding: 27px 20px 5px;
   border-radius: 10px 10px 0 0;

   .buttonTableHeader {
      display: flex;
      margin-bottom: 21px;

      >svg {
         margin-right: 8px;
      }

      .monthFilter {
         border: 1px solid #828282;
         box-sizing: border-box;
         border-radius: 8px;
         display: flex;
         align-items: center;
         margin-left: 8px;

         cursor: pointer;

         >div {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.181818px;
            color: #031615;
            padding: 5.5px 8px;
         }

      }

      .to {
         font-style: normal;
         font-weight: 500;
         font-size: 14px;
         line-height: 21px;
         letter-spacing: 0.181818px;
         color: #031615;
         margin-left: 8px;
      }

      .buttonTableFilter {
         margin-left: 18px;
         background: #17948C;
         border-radius: 10px;
         padding: 4px 8px;

         font-weight: 500;
         font-size: 16px;
         line-height: 24px;
         letter-spacing: 0.342857px;
         color: #FFFFFF;

         &:hover {
            background: #116e68;
            cursor: pointer;
         }
      }
   }
}

.filterButton {
   background: #17948C;
   border-radius: 10px;
   padding: 4px 8px;

   font-weight: 500;
   font-size: 16px;
   line-height: 24px;
   letter-spacing: 0.342857px;
   color: #FFFFFF;

   &:hover {
      background: #116e68;
      cursor: pointer;
   }
}

.filterTableDates {
   >button {
      border: 1px solid #17948C;
      box-sizing: border-box;
      border-radius: 22px;
      padding: 8px 12px;
      margin-right: 11px;

      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 17px;
      letter-spacing: 0.01em;
      color: #17948C;
      background-color: #fff;

      &:hover {
         background: #17948C;
         color: #fff;
         cursor: pointer;
      }
   }
}

.batchTable {
   border-radius: 0 0 10px 10px;

   >tbody {
      >tr {
         .photo {
            color: #17948C;
            cursor: pointer;
         }

         .capsuleTable {
            &__wrapper {
               display: flex;
               align-items: center;
               justify-content: center;


               .ellipsis {
                  display: -webkit-box;
                  -webkit-line-clamp: 3;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  text-overflow: ellipsis;
               }

               >div {
                  font-style: normal;
                  font-weight: normal;
                  font-size: 10px;
                  line-height: 15px;
                  letter-spacing: 0.155844px;

                  color: #FFFFFF;
                  padding: 2px 8px;
                  margin-right: 3px;
                  border-radius: 8px;
                  background: #17948C;
               }
            }

            &__span {
               font-style: normal;
               font-weight: normal;
               font-size: 10px;
               line-height: 15px;
               letter-spacing: 0.155844px;
               color: #4F4F4F;
               text-align: center;
               margin-top: 8px;
            }
         }

         .transactionPending {
            background: #E0CA3C;
            border-radius: 8px;
            padding: 2px 8px;

            font-style: normal;
            font-weight: normal;
            font-size: 10px;
            line-height: 15px;
            letter-spacing: 0.155844px;
            color: #FFFFFF;

         }

         .transactionConfirmed {
            @extend .transactionPending;
            background: #46AC89;
         }

         .transactionFinished {
            @extend .transactionPending;
            background: #335C67;
         }

         .transactionCancel {
            @extend .transactionPending;
            background: #8F2D56;
         }

         .transactionReject {
            @extend .transactionPending;
            background: #A30015;
         }
      }
   }
}