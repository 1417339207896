.breadcrumb {
    color: #798685;

    // >a:hover:not(:last-child) {
    //     color: #000000;
    //     cursor: pointer;
    // }

    // >a {

    //     text-transform: capitalize;
    // }

    // >a:last-child {
    //     cursor: default;
    // }
}