.search {
    position: relative;

    background: #F7F8F8;
    border: 1px solid #C9CFCE;
    box-sizing: border-box;
    border-radius: 10px;
    overflow: hidden;
    width: 25%;

    display: flex;
    align-items: center;

    input {
        width: 100%;
        flex: 1;
        background: none;
        border: none;
        padding: 8px 14px;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 17px;
        display: flex;
        align-items: center;
        letter-spacing: 0.01em;
        color: #17948C;

        &::placeholder {
            color: #17948C;
        }
    }

    >svg {
        position: absolute;
        right: 0.5rem;
        width: 20px;
        height: 20px;

        path {
            fill: #828282
        }
    }

    button {
        background-color: transparent;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        >svg {
            width: 20px;
            height: 20px;
    
            path {
                fill: #828282
            }
        }
    }
}