@mixin offer-table__text--primary {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.3px;
    color: #031615;
}

@mixin offer-table__text--secondary {
    font-size: 10px;
    line-height: 15px;
    letter-spacing: 0.3px;
    color: #4F4F4F;
}

// ----

@mixin offer-form__text--primary($color : null) {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.0709534px;

    @if($color) {
        color: $color;
    }

    @else {
        color: #000000;

    }
}

@mixin offer-form__text--subtitle {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.3px;
    color: #000000;
}

@mixin offer-form__text--secondary {
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #17948C;
}

@mixin offer-form__input($width : null, $backgroundColor : null) {
    @include offer-form__text--secondary;

    border: 1px solid #C9CFCE;
    border-radius: 10px;
    padding: 14px 16px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @if($width) {
        width: $width;
    }

    @else {
        width: 100%;
    }

    @if $backgroundColor {
        background-color: $backgroundColor;
    }

    @else {
        background-color: #F2F3F3;

    }
}

@mixin offer-form__input--split($backgroundColor : null) {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 45px;
    border: 1px solid #C9CFCE;
    border-radius: 10px;

    @if $backgroundColor {
        background-color: $backgroundColor;
    }

    @else {
        background-color: #F2F3F3;
    }

    input {
        @include merchant-create__text--primary;
        background-color: inherit;
        color: #17948C !important;
        border: none;
        width: 100%;
        margin-left: 16px;
        margin-right: 16px;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    >div:nth-child(1) {
        display: flex;
        align-items: center;
        height: inherit;
        padding: 10px;
        background-color: #ffffff;
        border-right: 1px solid #C9CFCE;
        border-radius: 10px;

        svg {
            width: 20px;
            height: 20px;

            path {
                fill: #358B9D;
            }
        }
    }
}

@mixin offer-form__textarea {
    background-color: #F2F3F3;
    border: 1px solid #C9CFCE;
    border-radius: 10px;
    padding: 14px 16px;
    width: 100%;
    max-width: 100%;
    min-height: 170px;
    @include offer-form__text--secondary;
}

/*=============================
        Offers List
=============================*/
.offers {
    display: flex;
    flex-direction: column;

    &__title {
        font-weight: 600;
        font-size: 24px;
        line-height: 48px;
        letter-spacing: 0.01em;
        color: #000000;
        margin-bottom: 9px;
    }
}

.offers__statistic {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(calc(25% - 10px), 1fr));
    grid-gap: 10px;
    grid-auto-rows: 182px;
    margin-bottom: 23px;

    >div {
        background-color: #FFFFFF;
        border: 1px solid #E4E7E7;
        border-radius: 10px;
        padding: 14px;

        display: flex;
        flex-direction: column;

        >span:nth-child(1) {
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.5px;
            color: #000000;
            margin-bottom: 37px;
        }

        >span:nth-child(2) {
            font-weight: 500;
            font-size: 36px;
            line-height: 54px;
            letter-spacing: 0.5px;
            color: #000000;
        }

        >span:nth-child(3) {
            font-weight: 500;
            font-size: 12px;
            line-height: 21px;
            letter-spacing: 0.155844px;
            color: #828282;
            flex-grow: 1;
            display: flex;
            align-items: flex-end;
        }
    }
}

.offers__button-create {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    >span {
        @include offer-table__text--primary;
    }

    >button {
        padding: 8px 33px;
        margin: 0 10px;
        border-radius: 10px;
        background-color: #17948C;
        border: 1.2px solid #17948C;
        font-weight: 500;
        font-size: 14px;
        color: #FAFAFA;
        cursor: pointer;
    }
}

.offers__table__list {
    >tbody td {
        vertical-align: top;
    }

    &__id {
        @include offer-table__text--primary;
    }

    &__date,
    &__title,
    &__impression {
        >div {
            display: flex;
            flex-direction: column;

            >span:nth-child(1) {
                @include offer-table__text--primary;
                margin-bottom: 6px;
            }

            >span:nth-child(2) {
                @include offer-table__text--secondary;
            }
        }
    }

    &__status {
        @include offer-table__text--primary;
    }

    &__action {}
}

/*=============================
        Offers Create
=============================*/
.offer-create {
    display: flex;
    flex-direction: column;

    &__title {
        font-weight: 600;
        font-size: 24px;
        line-height: 48px;
        letter-spacing: 0.01em;
        color: #000000;
        margin-bottom: 21px;
    }

    &__forms {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(calc(50% - 63px), 1fr));
        grid-gap: 63px;

        >div:first-child {
            display: flex;
            flex-direction: column;
        }
    }

    &__form {
        background-color: #FFFFFF;
        border: 1px solid #E4E7E7;
        border-radius: 10px;
        padding: 24px;
    }

    &__submit-form {
        display: flex;
        justify-content: flex-end;

        >button {
            @include offer-form__text--primary(#fff);
            margin-top: 43px;
            cursor: pointer;
            background: #17948C;
            border: 1px solid #17948C;
            box-sizing: border-box;
            border-radius: 10px;
            padding: 5px 10px;
        }
    }
}

.offer-create__form {
    &__name {
        margin-bottom: 16px;

        >div:nth-child(1) {
            @include offer-form__text--primary;
            margin-bottom: 8px;
        }

        >input {
            @include offer-form__input;
        }
    }

    &__featured {
        margin-bottom: 16px;

        >div:first-child {
            @include offer-form__text--primary;
            margin-bottom: 8px;
        }
    }

    &__type {
        margin-bottom: 16px;

        >div:nth-child(1) {
            @include offer-form__text--primary;
            margin-bottom: 8px;
        }

        >div:nth-child(2) {
            display: flex;

            >* {
                margin-right: 36px;
            }

        }

    }

    &__start-date {
        margin-bottom: 16px;

        >div:nth-child(1) {
            @include offer-form__text--primary;
            margin-bottom: 8px;
        }

        >div:nth-child(2) {
            @include offer-form__input--split;
        }
    }

    &__avail-for {
        margin-bottom: 16px;

        >div:nth-child(1) {
            @include offer-form__text--primary;
            margin-bottom: 8px;
        }

        >div:nth-child(2) {
            display: flex;
            align-items: center;

            input {
                @include offer-form__input($width : 55px);
            }

            span {
                @include offer-form__text--primary($color : #17948C);
                margin-left: 12px;
            }
        }
    }

    &__desc {
        margin-bottom: 16px;

        >div:nth-child(1) {
            margin-bottom: 8px;
            @include offer-form__text--primary;
        }

        >textarea {
            @include offer-form__textarea;
        }
    }

    &__classes {
        border: 1px solid #C9CFCE;
        box-sizing: border-box;
        border-radius: 10px;
        margin-bottom: 8px;

        &__header {
            padding: 14px;
            text-align: center;
            background-color: #ffffff;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            @include offer-form__text--primary;
        }

        &__main {
            padding: 12px;
            background-color: #F2F3F3;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;

            >div>* {
                margin-bottom: 8px;
            }
        }

        &__add-button {
            @include offer-form__input;
            margin-bottom: 16px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #ffffff;

            &:hover {
                background: #17948C;
                color: #FFFFFF;

                svg {
                    fill: #FFFFFF;
                }
            }

            >span {
                margin-left: 8px;
            }
        }
    }


    &__class {

        >div:nth-child(1) {
            @include offer-form__text--subtitle;
        }

        select {
            background-color: #FFFFFF;
        }
    }

    &__class-date {

        >div:nth-child(1) {
            @include offer-form__text--subtitle;
        }

        >div:nth-child(2) {
            @include offer-form__input--split($backgroundColor : #fff);
        }
    }

    &__class__time {


        >div:nth-child(1) {
            @include offer-form__text--subtitle;
        }

        input {
            @include offer-form__input($backgroundColor : #fff);
        }
    }

    &__link-type {
        margin-bottom: 16px;

        >div:first-child {
            @include offer-form__text--primary;
            margin-bottom: 8px;
        }

        >div:nth-child(2) {
            display: flex;
            margin-bottom: 8px;

            >* {
                margin-right: 36px;
            }

        }

        >input {
            @include offer-form__input;
        }
    }

    &__flight-time {
        margin-bottom: 16px;

        >* {
            &:not(:last-child) {
                margin-bottom: 8px;
            }
        }

        >div:nth-child(1) {
            @include offer-form__text--primary;
        }


        &__subtitle {
            @include offer-form__text--subtitle;
        }

        &__input {
            @include offer-form__input--split();
        }

        &__inputs {
            display: flex;
        }

        // >div:not(:nth-child(1))
    }

    &__photos {
        margin-bottom: 16px;

        >div:nth-child(1) {
            @include offer-form__text--primary;
            margin-bottom: 8px;
        }

        >div:nth-child(2) {
            margin-bottom: 16px;

            div {
                position: relative;

                svg {
                    cursor: pointer;
                    position: absolute;
                    right: 8px;
                    top: 8px;
                }

                img {
                    width: 100%;
                    border: 1px solid #C9CFCE;
                    border-radius: 10px;
                }
            }
        }
    }

    &__add-photo {
        position: relative;

        >[type="file"] {
            border: 0;
            clip: rect(0, 0, 0, 0);
            height: 1px;
            overflow: hidden;
            padding: 0;
            white-space: nowrap;
            width: 1px;
            position: absolute;

        }

        >[type="file"],
        >label {
            @include offer-form__input;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #ffffff;

            &:hover {
                background: #17948C;
                color: #FFFFFF;

                svg {
                    fill: #FFFFFF;
                }
            }

            >span {
                margin-left: 8px;
            }
        }
    }
}

/* The container */
.container-radio {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: fit-content;

    >div {
        padding-top: 4px;
        @include offer-form__text--secondary;
    }
}

/* Hide the browser's default radio button */
.container-radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom radio button */
.checkmark-radio {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #fff;
    border: 1px solid #979797;
    border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container-radio:hover input~.checkmark-radio {
    background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container-radio input:checked~.checkmark-radio {
    background-color: #fff;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark-radio:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.container-radio input:checked~.checkmark-radio:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.container-radio .checkmark-radio:after {
    top: 7.5px;
    left: 7.5px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #616060;
}

.inputTime {
    height: 100%;
    margin-left: 8px;

    a {
        display: none
    }

    .rc-time-picker-input {
        @include merchant-create__text--primary;
        height: 100%;
        padding: 14px 10px;
        border: 1px solid #C9CFCE;
        border-radius: 10px;
        background-color: #F2F3F3 !important;
    }

}