.detailDiscount{
   &__header{
      display: flex;
      align-items: center;
      margin-bottom: 32px;
      &__button{
         display: flex;
         align-items: center;
         background: #FFFFFF;
         border: 1.2px solid #17948C;
         box-sizing: border-box;
         border-radius: 10px;
         padding: 5px 11px;
         cursor: pointer;
         margin-left: 8px;
         font-style: normal;
         font-weight: 500;
         font-size: 14px;
         line-height: 24px;
         letter-spacing: 0.03em;
         color: #17948C;
         >svg{margin-right: 8px;}
         &:hover{
            background: #17948C;
            svg{path{fill: #FFFFFF;}}
            color: #FFFFFF;
         }
      }
      &__button.add{
         circle{stroke: #FFFFFF;}
         g{path{fill: #FFFFFF ;}}
         background: #17948C;
         color: #FFFFFF;
         border: 1.2px solid #17948C;
         &:hover{
            background: #106660;
            border: 1.2px solid #106660;
         }
      }
   }
   &__title{
      flex: 1;
   }
   &__cards{
      display: flex;
      align-items: center;
      &__small{
         background: #FFFFFF;
         border: 1px solid #E4E7E7;
         border-radius: 10px;
         padding: 12px;
         margin-bottom: 16px;
         width: 20%;
         min-height: 8rem;
      }
      &__smallFull{
         @extend .detailDiscount__cards__small;
         width: 40%;
      }
      &__med{
         @extend .detailDiscount__cards__small;
         width: 71.2%;
         min-height: 17rem;
      }
      &__full{
         @extend .detailDiscount__cards__small;
         width: 100%;
         min-height: 17rem;
         
      }
      &__title{
         font-style: normal;
         font-weight: 500;
         font-size: 14px;
         line-height: 21px;
         letter-spacing: 0.12px;
         color: #1C1C1C;
         margin-bottom: 10px;
      }
      &__stats{
         font-style: normal;
         font-weight: 500;
         font-size: 24px;
         line-height: 36px;
         letter-spacing: 0.1px;
         color: #1C1C1C;
         margin-bottom: 10px;
         >span{
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 27px;
            letter-spacing: 0.1px;
            color: #AFB6B6;
         }
      }
      &__info{
         font-weight: 500;
         font-size: 12px;
         line-height: 18px;
         letter-spacing: 0.12987px;
         color: #333333;
         >span{
            background: #46AC89;
            font-style: normal;
            font-weight: normal;
            font-size: 10px;
            line-height: 15px;
            letter-spacing: 0.155844px;
            color: #FFFFFF;
            border-radius: 8px;
            padding: 2px 8px;
         }
      }
      &__wrapper{
         display: flex;
         align-items: center;
         margin-bottom: 32px;
         >div{
            width: 55%;;
            >div{
               font-style: normal;
               font-weight: 500;
               font-size: 12px;
               line-height: 18px;
               letter-spacing: 0.12987px;
               color: #333333;
            }
            >span{
               font-style: normal;
               font-weight: normal;
               font-size: 14px;
               line-height: 21px;
               letter-spacing: 0.3px;
               color: #798685;
            }
         }
      }
      &__table{
         >table{
            width: 100%;
            th{
               font-style: normal;
               font-weight: 500;
               font-size: 12px;
               line-height: 18px;
               letter-spacing: 0.12987px;
               color: #333333;
               text-align: left;
               padding-bottom: 16px;
            }
            td{
               vertical-align: top;
               >div{
                  display: flex; 
                  align-items: center;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 14px;
                  line-height: 21px;
                  letter-spacing: 0.3px;
                  color: #798685;
               }
               ul{
                  font-style: normal;
                  font-weight: normal;
                  font-size: 14px;
                  line-height: 21px;
                  letter-spacing: 0.3px;
                  color: #798685;
                  padding: 0;
                  list-style: none; /* Remove default bullets */
               }
               ul li::before {
                  content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
                  color: #17948C; /* Change the color */
                  font-weight: bold; /* If you want it to be bold */
                  display: inline-block; /* Needed to add space between the bullet and the text */
                  width: 1em; /* Also needed for space (tweak if needed) */
                  margin-left: -1em; /* Also needed for space (tweak if needed) */
                }
            }
            
            .venueGreen, .venueBlue{
               width: 18px;
               height: 18px;
               border-radius: 100%;
               margin-right: 8px;
            }
            
            .venueGreen{
               background: #27AE60;
            }
            .venueBlue{
               background: #1F7899;
            }
            .tableTotalVoucher{
               display: flex;
               align-items: center;
               padding-bottom: 24px;
               >div{
                  background: #F2F3F3;
                  border: 1px solid #C9CFCE;
                  box-sizing: border-box;
                  border-radius: 10px;
                  padding: 7px 20px;
                  color: #17948C;
                  margin-right: 8px;
               }
               >span{
                  font-style: normal;
                  font-weight: normal;
                  font-size: 10px;
                  line-height: 15px;
                  letter-spacing: 0.3px;
                  color: #031615;
               }
            }
         }
      }
   }
   &__table{
      &__title{
         font-weight: 500;
         font-size: 14px;
         line-height: 21px;
         letter-spacing: 0.12px;
         color: #1C1C1C;
         margin-bottom: 21px;
      }
   }
}

.cardMargin{
   >div:not(:first-child){margin-left: 14px;}
}