.tabBatch {
   margin-top: 20px;

   &__header {
      >.title {
         font-style: normal;
         font-weight: 500;
         font-size: 18px;
         line-height: 24px;
         letter-spacing: 0.03em;
         margin-bottom: 16px;
         color: #000000;
      }

      &__cards {
         display: flex;
         align-items: center;
         flex-wrap: nowrap;

         width: 80vw;
         overflow-x: auto;

         &::-webkit-scrollbar {
            width: 15px;
            background-color: transparent;
         }

         &::-webkit-scrollbar-corner {
            background-color: transparent;
         }

         &::-webkit-scrollbar-thumb {
            border: 4px solid rgba(0, 0, 0, 0);
            background-clip: padding-box;
            -webkit-border-radius: 10px;
            background-color: #E4E7E7;
         }

         >.cardWrapper {
            background: #FFFFFF;
            border: 1px solid #E4E7E7;
            border-radius: 10px;
            padding: 16px;
            margin-right: 25px;

            display: flex;
            min-width: 345px;
            justify-content: space-between;

            &:hover {
               background: rgba($color: #000000, $alpha: 0.1);
               opacity: 0.9;
               cursor: pointer;
            }

            &--active {
               @extend .cardWrapper;
               border: 3px solid #17948C;
            }

            .card {
               .title {
                  font-style: normal;
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 19px;
                  letter-spacing: 0.03em;

                  color: #000000;
                  margin-bottom: 8px;
               }

               .price {
                  font-style: normal;
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 19px;
                  letter-spacing: 0.03em;
                  color: #17948C;

                  >span {
                     color: #AFB6B6;
                  }
               }

               .action {
                  display: flex;
                  margin-top: 8px;

                  button {
                     background: #17948C;
                     border-radius: 8px;
                     padding: 2px 8px;
                     border: none;
                     margin-right: 8px;

                     font-style: normal;
                     font-weight: normal;
                     font-size: 10px;
                     line-height: 15px;
                     letter-spacing: 0.155844px;
                     color: #FFFFFF;
                  }
               }
            }

            .purchases {
               .title {
                  font-style: normal;
                  font-weight: 600;
                  font-size: 10px;
                  line-height: 12px;
                  letter-spacing: 0.03em;
                  color: #AFB6B6;
               }

               .content {
                  font-style: normal;
                  font-weight: 600;
                  font-size: 21px;
                  line-height: 24px;
                  letter-spacing: 0.015em;
                  text-align: right;
                  color: #17948C;
               }
            }
         }
      }
   }
}

.tabBatch__action {
   display: flex;
   align-items: center;
   margin-top: 13px;
   margin-bottom: 15px;

   .title {
      flex: 1;

      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.03em;
      color: #000000;
   }

   .buttonWrapper {
      display: flex;
      align-items: center;

      .button {
         background: #17948C;
         border: 1.2px solid #17948C;
         box-sizing: border-box;
         border-radius: 10px;
         padding: 5px 22px;
         margin-left: 16px;

         display: flex;
         align-items: center;

         &:hover {
            background: #0f726b;
            border: 1.2px solid #0f726b;
            cursor: pointer;
         }

         .title {
            border: none;
            background: none;

            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.03em;
            color: #FAFAFA;

            &:hover {
               cursor: pointer;
            }
         }
      }
   }
}

.table_batch__transaction {
   margin-top: 50px;
}