.challenge{

    &__typeContent{
        display: flex;
        flex-direction: column;
        border: 1px solid #C9CFCE;
        padding: 16px;
        border-radius: 10px;
        margin-bottom: 16px;
        
        &__doofitmerchant{
            display: flex;
            flex-direction: column;
            border: 1px solid #C9CFCE;
            padding: 12px 16px;
            border-radius: 10px;
            margin-bottom: 10px;
            margin-top: 10px;
            
            &__title{
                margin-bottom: 10px;
            }
    
            &__list{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 10px;

                &__input{
                    margin-bottom: 8px;
                    background: #F2F3F3;
                    border: 1px solid #C9CFCE;
                    box-sizing: border-box;
                    border-radius: 10px;
                    width: 90%;
                    height: 46px;
                    padding:0 16px;
                    display: flex;
                    align-items: center;
                    margin-bottom: 8px;
                    
                    >select{
                        background: #F2F3F3;
                        border: none;
                        width: 100%;
                    }
                }

                .iconAdd{
                    cursor: pointer;
                }
            }
        }
    
        &__doofoodmerchant{
            display: flex;
            flex-direction: column;
            border: 1px solid #C9CFCE;
            padding: 12px 16px;
            border-radius: 10px;
            margin-bottom: 10px;
            margin-top: 10px;

            &__title{
                margin-bottom: 10px;
            }
    
            &__list{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 10px;

                &__input{
                    margin-bottom: 8px;
                    background: #F2F3F3;
                    border: 1px solid #C9CFCE;
                    box-sizing: border-box;
                    border-radius: 10px;
                    width: 90%;
                    height: 46px;
                    padding:0 16px;
                    display: flex;
                    align-items: center;
                    margin-bottom: 8px;
                    
                    >select{
                        background: #F2F3F3;
                        border: none;
                        width: 100%;
                    }
                }

                .iconAdd{
                    cursor: pointer;
                }
            }
        }
    
    
        &__input{
            margin-bottom: 8px;
            background: #F2F3F3;
            border: 1px solid #C9CFCE;
            box-sizing: border-box;
            border-radius: 10px;
            width: 100%;
            height: 46px;
            padding:0 16px;
            display: flex;
            align-items: center;
            margin-bottom: 8px;
            
            >select{
                background: #F2F3F3;
                border: none;
                width: 100%;
            }
        }
    }

    &__title{
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.0709534px;
        color: #000000;
        margin-bottom: 8px;
        margin-top: 24px;
    }

   &__content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #C9CFCE;
    padding: 16px;
    border-radius: 10px;
    margin-bottom: 16px;

    &__body{
        display: flex;
        flex-direction: column;
        margin-right: 10px;

        &__input{
            margin-bottom: 8px;
            background: #F2F3F3;
            border: 1px solid #C9CFCE;
            box-sizing: border-box;
            border-radius: 10px;
            width: 100%;
            height: 46px;
            padding:0 16px;
            display: flex;
            align-items: center;

            >input{
                margin-right: 10px;
            }
        }

        &__color{
            margin-top: 12px;

            .sketch-picker{
                width: 178px !important;
            }
        }
    }
   }

    &__radio{
        margin-bottom: 16px;
        margin-top: 16px;
    }
}

.modalChallenge{
    background-color: #fff;
    width: 100%;
    padding: 16px;
    border-radius: 10px;


    &__foodPackage{
        display: flex;
        flex-direction: column;

        >label{
            margin-bottom: 16px;
            text-align: center;
            font-weight: 600;
        }

        &__list{
            &__title{
                margin-top: 10px;
                font-size: 14px;
            }
            
            &__card{
                margin-top: 12px;
                border: 1px solid #C9CFCE;
                padding: 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-radius: 10px;

                >div{
                    background: #F2F3F3;
                    border: 1px solid #C9CFCE;
                    box-sizing: border-box;
                    border-radius: 10px;
                    height: 46px;
                    padding:0 10px;
                    color: #17948C;
                    display: flex;
                    align-items: center;
                    width: 30%;
                    
                    >select {
                        border: none;
                        background-color: transparent;
                        width: 100%;
                    }
                }

                >input{
                    background: #F2F3F3;
                    border: 1px solid #C9CFCE;
                    box-sizing: border-box;
                    border-radius: 10px;
                    height: 46px;
                    padding:0 10px;
                    color: #17948C;
                }
                
                >input:nth-child(2){
                    width: 30%;
                }
                
                >input:nth-child(3){
                    width: 30%;
                }

                >svg{
                    cursor: pointer;
                }
            }
        }

        .buttonAction{
            display: flex;
            align-self: flex-end;
            margin-top: 16px;

            >button:nth-child(1){
                margin-top: 12px;
                padding: 5px 10px;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                white-space: nowrap;
                margin-left: 16px;
                border: 1px solid #17948C;
                box-sizing: border-box;
                border-radius: 10px;
                cursor: pointer;
                color: #17948C;
            }
            
            >button:nth-child(2){
                margin-top: 12px;
                padding: 5px 10px;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                white-space: nowrap;
                margin-left: 16px;
                background-color: #17948C;
                box-sizing: border-box;
                border-radius: 10px;
                border: 1px solid #17948C;
                color: #fff;
                cursor: pointer;
            }
        }
    }
}


.challengePackage{
    &__list{
        display: flex;
        overflow-x: scroll;
        background-color: #ddd;
        padding: 16px;
        align-items: center;
        border-radius: 10px;
        max-width: 1085px;
        
        &__card{
            background-color: #fff;
            padding : 16px;
            border-radius: 10px;
            margin-right: 16px;

            >div{
                >p{
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 21px;
                    letter-spacing: 0.0709534px;
                    color: #000000;
                    margin-bottom: 8px;
                    margin-top: 8px;
                }

                >input{
                    margin-top: 8px;
                    margin-bottom: 16px;
                    background: #F2F3F3;
                    border: 1px solid #C9CFCE;
                    box-sizing: border-box;
                    border-radius: 10px;
                    height: 46px;
                    padding:0 10px;
                    color: #17948C;
                    width: 100%;
                }
                
                .radio{
                    display: flex;
                    align-items: center;
                    
                    >input {
                        margin-right: 8px;
                    }
                    
                    >label{
                        color: #17948C;
                    }
                }

                .images{
                    display: flex;
                    align-items: flex-end;
                    margin-bottom: 16px;
                    
                    >img{
                        border-radius: 10px;
                        margin-right: 8px;
                        width: 230px;
                    }

                    >button{
                        cursor: pointer;
                        padding: 5px 10px;
                        background: #17948C;
                        border: 1px solid #17948C;
                        border-radius: 10px;
                        font-weight: 500;
                        font-size: 14px;
                        letter-spacing: 0.03em;
                        color: #FAFAFA;
                        text-align: center;
                        height: 36px;
                        width: 90px;
                    }
                }

                .select{
                    margin-top: 8px;
                    margin-bottom: 16px;
                    background: #F2F3F3;
                    border: 1px solid #C9CFCE;
                    box-sizing: border-box;
                    border-radius: 10px;
                    height: 46px;
                    padding:0 10px;
                    color: #17948C;
                    width: 100%;
                    
                    display: flex;
                    align-items: center;
                    
                    >select{
                        width: 100%;
                        border:none;
                        background: #F2F3F3;
                    }
                }

                .divider{
                    width: 100%;
                    height: 2px;
                    background-color: #ddd;
                    margin-top: 22px;
                }

                .buttonAction{
                    display: flex;
                    align-self: flex-end;
                    margin-top: 10px;
        
                    &__cancel{
                        margin-top: 12px;
                        padding: 5px 10px;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 24px;
                        white-space: nowrap;
                        border: 1px solid #17948C;
                        box-sizing: border-box;
                        border-radius: 10px;
                        cursor: pointer;
                        color: #17948C;
                    }
                    
                    &__add{
                        margin-top: 12px;
                        padding: 5px 10px;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 24px;
                        white-space: nowrap;
                        background-color: #17948C;
                        box-sizing: border-box;
                        border-radius: 10px;
                        border: 1px solid #17948C;
                        color: #fff;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    &__action{
        display: flex;
        align-self: flex-end;
        justify-content: flex-end;
        margin-top: 10px;

        >button:nth-child(1){
            margin-top: 12px;
            padding: 5px 10px;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            white-space: nowrap;
            border: 1px solid #17948C;
            box-sizing: border-box;
            border-radius: 10px;
            cursor: pointer;
            color: #17948C;
        }
        
        >button:nth-child(2){
            margin-top: 12px;
            padding: 5px 10px;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            white-space: nowrap;
            margin-left: 16px;
            background-color: #17948C;
            box-sizing: border-box;
            border-radius: 10px;
            border: 1px solid #17948C;
            color: #fff;
            cursor: pointer;
        }
    }
}

.actionModal_activity {
    background: #FFFFFF;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1), 0px 0px 4px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    position: absolute;
    z-index: 2;
    right: 2rem;
    top: 0;
    left: calc(100% - 10px);
    width: 200px;
    padding: 16px 16px;

    &__close {
        position: absolute;
        right: 10px;
        top: 10px;
        width: 20px;
        height: 20px;
    }

    >div:not(:last-child) {
        margin-bottom: 19px;
    }

    >div,
    a {
        display: flex;
        align-items: center;
        cursor: pointer;

        >div {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 17px;
            letter-spacing: 0.01em;
            color: #000000;
            margin-left: 9px;
        }
    }
}

.action_createPlannerActivity {
    display: flex;
    justify-content: flex-end;

    button {
        border: 1px solid #17948C;
        box-sizing: border-box;
        border-radius: 10px;
        padding: 5px 10px;
        margin-left: 16px;
        margin-top: 24px;
        cursor: pointer;

        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;

        &:nth-child(1) {
            color: #17948C;
        }

        &:nth-child(2) {
            color: #FAFAFA;
            background: #17948C;
        }
    }
}


.createPlannerActivity_form {
    header {
        color: #555555;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 32px;
    }
    .content {
        >.title {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 17px;
            align-items: center;
            margin-bottom: 8px;
        }

        >.textAreaContentStyle {
            max-width: 100%;
            background: #F2F3F3;
            border: 1px solid #C9CFCE;
            box-sizing: border-box;
            border-radius: 10px;
            padding: 14px 16px;
            width: 100%;
            margin-bottom: 24px;

            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 17px;

            &::placeholder {
                color: #17948C;
            }
        }

        >.inputContentStyle {
            background: #F2F3F3;
            border: 1px solid #C9CFCE;
            box-sizing: border-box;
            border-radius: 10px;
            padding: 14px 16px;
            width: 100%;

            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 17px;

            &::placeholder {
                color: #17948C;
            }
        }

        >.add-image {
            display: flex;
            justify-content: center;
            width: 100%;

            cursor: pointer;
            border: 1px solid #17948C;
            box-sizing: border-box;
            border-radius: 10px;
            background-color: transparent;
            padding: 10px;
            margin-top: 32px;

            font-size: 12px;
            line-height: 17px;
            letter-spacing: 0.01em;
            color: #17948C;

            >div {
                margin-left: 8px;
            }
        }

        >.categories {
            display: flex;
            align-items: center;
            max-width: 24rem;
            flex-wrap: wrap;
            height: 100%;

            >.category {
                display: flex;
                align-items: center;
                background: #FFFFFF;
                border: 1px solid #17948C;
                box-sizing: border-box;
                border-radius: 22px;
                padding: 8px 12px;
                margin-right: 12px;
                margin-bottom: 12px;

                .title {
                    flex: 1;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 17px;
                }

                >i {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                }

                svg {
                    width: 16px;
                    margin-left: 10px;
                    cursor: pointer;

                    path {
                    fill: #EB5757;
                    }
                }
            }
        }

        >.dropdown {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 14px 16px;
            margin-top: 7px;
            margin-bottom: 32px;

            background: #F2F3F3;
            border: 1px solid #C9CFCE;
            box-sizing: border-box;
            border-radius: 10px;

            >.title {
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 17px;
                flex: 1;
            }

            >svg {
                width: 24px;

                path {
                    fill: #17948C;
                }

                cursor: pointer;
            }
        }

        >.inputContent {
            display: flex;
            align-items: center;
            border-radius: 10px;
            margin-bottom: 32px;

            background: #F2F3F3;
            border: 1px solid #C9CFCE;
            box-sizing: border-box;

            >.title {
                font-style: normal;
                font-weight: bold;
                font-size: 12px;
                line-height: 17px;

                padding: 13px 12px;
                background: #FFFFFF;
                border: 1px solid #C9CFCE;
                box-sizing: border-box;
                border-radius: 10px;
            }

            >input {
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 17px;
                margin-left: 16px;
                border: none;
                background: none;
                height: 100%;

                &::placeholder {
                    color: #17948C;
                }
            }
        }

        >.imgContent {
            display: flex;

            img {
                width: 343px;
                height: 170px;
                border-radius: 10px;

                object-fit: cover;

                box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1), 0px 0px 4px rgba(0, 0, 0, 0.15);
                background-color: white;

            }

            >.actions {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                margin-left: 24px;

                >button {
                    margin-top: 12px;
                    padding: 5px 10px;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 24px;
                    white-space: nowrap;

                    border: 1px solid #17948C;
                    box-sizing: border-box;
                    border-radius: 10px;
                    cursor: pointer;

                    &:nth-child(1) {
                    color: #FAFAFA;
                    background: #17948C;
                    }

                    &:nth-child(2) {
                    color: #17948C;
                    }
                }
            }
        }

        >.sub-title {
            font-style: normal;
            font-weight: 500;
            font-size: 10px;
            line-height: 15px;
        }
        }
}
