.user-table {
    display: flex;
    flex-direction: column;
}

@mixin user-table--primary-text {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.3px;
    color: #031615;
}

@mixin user-table--secondary-text {
    font-size: 10px;
    line-height: 15px;
    letter-spacing: 0.3px;
    color: #4F4F4F;
}

.user-table__header {
    &__title {
        font-weight: 600;
        font-size: 24px;
        line-height: 48px;
        letter-spacing: 0.01em;
        color: #000000;
        margin-bottom: 9px;
    }

    &__statistic {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
        grid-gap: 10px;
        grid-auto-rows: 182px;
        margin-bottom: 23px;

        >div {
            background: #FFFFFF;
            border: 1px solid #E4E7E7;
            border-radius: 10px;
            padding: 14px;
        }

        &__member {
            display: flex;
            flex-direction: column;

            >div:nth-child(1) {
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                letter-spacing: 0.5px;
                color: #000000;
                flex-grow: 1;
            }

            >div:nth-child(2) {
                font-weight: 500;
                font-size: 36px;
                line-height: 54px;
                letter-spacing: 0.5px;
                color: #000000;
            }

            >div:nth-child(3) {
                font-weight: 500;
                font-size: 12px;
                line-height: 21px;
                letter-spacing: 0.155844px;
                color: #828282;
                margin-bottom: 15px;

            }

            >div:nth-child(4) {
                font-weight: normal;
                font-size: 12px;
                line-height: 21px;
                letter-spacing: 0.155844px;
                color: #828282;
            }

        }

        &__chart {
            grid-column: 2/4;

            >div:nth-child(1) {
                text-align: center;
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                letter-spacing: 0.5px;
                color: #000000;

            }
        }
    }
}

.user-table__table {
    >tbody td {
        vertical-align: top;
    }

    &__regis {
        >span {
            @include user-table--primary-text;
        }
    }

    &__name {
        >div {
            display: flex;
            flex-direction: column;
            text-align: left;

            >span:nth-child(1) {
                @include user-table--primary-text;
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: 0.3px;
                color: #031615;
            }

            >span:nth-child(2) {
                @include user-table--secondary-text;
                font-style: normal;
                font-weight: normal;
                font-size: 10px;
                line-height: 15px;
                letter-spacing: 0.3px;
                color: #4F4F4F;
            }

            >span:nth-child(3) {
                @include user-table--secondary-text;
                font-style: normal;
                font-weight: normal;
                font-size: 10px;
                line-height: 15px;
                letter-spacing: 0.3px;
                color: #4F4F4F;
            }
        }

    }

    &__date {
        >div {
            display: flex;
            flex-direction: column;
            text-align: left;

            >span:nth-child(1) {
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: 0.3px;
                color: #031615;
                white-space: nowrap;
            }

            >span:nth-child(2) {
                font-style: normal;
                font-weight: normal;
                font-size: 10px;
                line-height: 15px;
                letter-spacing: 0.3px;
                color: #4F4F4F;
                white-space: nowrap;
            }

            >span:nth-child(3) {
                font-style: normal;
                font-weight: normal;
                font-size: 10px;
                line-height: 15px;
                letter-spacing: 0.3px;
                color: #4F4F4F;
            }
        }

    }

    &__contact {
        >div {
            display: flex;
            flex-direction: column;

            >span:nth-child(1) {
                @include user-table--primary-text;
            }

            >span:nth-child(2) {
                @include user-table--secondary-text;
            }
        }
    }

    &__membership {
        >div {
            display: flex;
            flex-direction: column;

            >span {
                @include user-table--primary-text;
            }
        }
    }

    &__role {
        div {
            display: flex;
            flex-direction: column;

            >span {
                background: #46AC89;
                color: #ffffff;
                font-weight: normal;
                font-size: 10px;
                line-height: 15px;
                letter-spacing: 0.155844px;

                border-radius: 8px;
                padding: 2px 8px;
                text-align: center;

                &:not(:last-child) {
                    margin-bottom: 8px;
                }

            }
        }
    }

    &__status {
        span {
            >div {
                width: fit-content;
                margin-top: 10px;
                background: #800000;
                color: #ffffff;
                font-weight: normal;
                font-size: 10px;
                line-height: 15px;
                letter-spacing: 0.155844px;
                border-radius: 8px;
                padding: 2px 8px;
                text-align: center;
            }

            ul {
            width: fit-content;
            margin-top: 10px;
            background: #46AC89;
            color: #ffffff;
            font-weight: normal;
            font-size: 10px;
            line-height: 15px;
            letter-spacing: 0.155844px;
            border-radius: 8px;
            padding: 2px 8px;
            text-align: center;
            }
        }
    }

    &__spend {
        @include user-table--primary-text;
    }

    &__action {
        vertical-align: middle !important;

        >div {
            display: flex;

            >i {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 24px;
                height: 24px;
                background-color: #FAFAFA;
                border: 1px solid #C9CFCE;
                border-radius: 4px;
                cursor: pointer;

                &:not(:last-child) {
                    margin-right: 9px;
                }

                svg {
                    width: 16px;
                    height: 16px;
                }
            }
        }
    }
}