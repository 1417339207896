.detailAsScreen{
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    
    >div{
      padding: 28px;
    }

    >div:first-child{
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
    }
    
    >div:last-child{
      flex-grow: 1;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
    }
  
    >div:nth-child(1){
      background-color: #262825;
      color: #fff;
    }
    >div:nth-child(2){
      background-color: #F6F6F6;
    }
    >div:nth-child(3){
      background-color: #1F7899;
    }
  
    @media only screen and (max-width : 961px){
      width: 90vw;
      left: 5vw;
      top :90px;
      min-height: 85vh;
      height: 85vh;
      overflow-y: auto;
    }
}