.membership {
    &__emptyState {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 20px;
        margin: 20px;

        >img {
            width: fit-content;
        }

        >h4 {
            width: fit-content;
            margin: 20px;
        }

        >button {
            width: fit-content;
        }
    }
}

.dropdown {
    margin-left: 5px;
}

.cardMembership {
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    margin-top: -20px;

    &__wrapper {
        margin: 20px 31px 0px 0px;
        padding: 20px;
        border: 1px solid lightgrey;
        border-radius: 14px;
        background-color: white;
        min-width: 285px;
        min-height: 250px;

    &__header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        >h5 {
            width: fit-content;
            font-weight: 600;
        }
        >span {
            cursor: pointer;
        }
    }

    &__content {
        margin-bottom: 10px;

    &__amount {
        position: absolute;
        margin-top: 15px;
        margin-left: -31px;
        font-weight: bold;
    }

    &__title {
        position: absolute;
        margin: 5px 0px 0px 10px;
        font-weight: 600;
        
            >div {
                margin-top: 2px;
                font-size: 12px;
                font-weight: 400; 
                }
            }
        }
    }
}