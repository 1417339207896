.session-detail {
    &__id {
        @include listInformation__text--primary;
    }

    &__name {
        @include listInformation__text--primary;
    }

    &__contact-info {
        >div {
            display: flex;
            flex-direction: column;

            >span:nth-child(1) {
                @include listInformation__text--primary;
            }

            >span:nth-child(2) {
                @include listInformation__text--secondary;
            }
        }
    }

    &__status {

        >span:first-child {
            padding: 2px 8px;
            border-radius: 10px;
            width: fit-content;
        }

        >span:nth-child(2) {
            margin-top: 8px;
        }

        >span:not(:first-child) {
            @include listInformation__text--secondary;
        }
    }
}