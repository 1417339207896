.tableTransaction__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 18px;

    @media only screen and (max-width : 961px) {
        flex-direction: column;
    }

    >span {
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 0.12px;
        color: #1C1C1C;
    }

    .tableTransaction__header__filter {
        font-weight: bold;
        font-size: 12px;

        display: flex;

        @media only screen and (max-width : 961px) {
            flex-direction: column;

            >div {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 5px;
            }
        }

        .tableTransaction__header__filter__button {
            padding: 8px 33px;
            margin: 0 10px;
            border-radius: 10px;
            background-color: #17948C;
            border: 1.2px solid #17948C;
            font-weight: 500;
            font-size: 14px;
            color: #FAFAFA;
            cursor: pointer;
        }
    }
}

.tableTransaction__filter {
    display: flex;
    padding: 24px 16px;

    display: flex;
    flex-direction: column;

    &__date {
        display: flex;
        margin-bottom: 21px;

        >div {
            svg {
                width: 24px;
                height: 24px;
                margin-right: 6px;
            }

            display: flex;
            align-items: center;
        }
    }
}



/*===================================
        TRANSATION DOOFIT
=====================================*/
.transaction-fit {
    &__title {
        font-weight: 600;
        font-size: 24px;
        line-height: 48px;
        letter-spacing: 0.01em;
        color: #000000;
        margin: 9px 0px;
    }
}


.transaction-fit__cards-statistic {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
    grid-gap: 10px;
    grid-auto-rows: 134px;
    margin-bottom: 23px;

    >div {
        background: #FFFFFF;
        border: 1px solid #E4E7E7;
        border-radius: 10px;
        padding: 14px;
    }

    >div:last-child {
        grid-row: 1/3;
        grid-column: 3/6;

    }

    >div:not(:last-child) {
        >div {
            display: flex;
            flex-direction: column;
            height: 100%;
            color: #1C1C1C;

            >span:nth-child(1) {
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                letter-spacing: 0.12px;
            }

            >span:nth-child(2) {
                font-weight: 500;
                font-size: 24px;
                line-height: 36px;
                letter-spacing: 0.1px;
                flex-grow: 1;
            }
        }
    }

    &__percentage--increase {
        display: flex;
        align-items: center;

        svg {
            height: 20px;
            width: 20px;
        }

        span {
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0.155844px;
        }

        >span:nth-of-type(1) {
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0.155844px;
            color: #66D67B;
        }

        >span:nth-of-type(2) {
            color: #828282;
        }
    }

    &__percentage--decrease {
        @extend .transaction-fit__cards-statistic__percentage--increase;

        >span:nth-of-type(1) {
            color: #EB5757;
        }
    }

    &__percentage--equal {
        @extend .transaction-fit__cards-statistic__percentage--increase;

        >span:nth-of-type(1) {
            color: #828282;
        }
    }
}

/*====================================
    TAB BOOKING : STYLING EACH COLUMN 
====================================*/
.bookingTable {
    td {
        vertical-align: top;
    }

    &__id {
        @include listInformation__text--primary;
        width: fit-content;
    }

    &__date {
        >div {
            display: flex;
            flex-direction: column;

            >span:nth-child(1) {
                @include listInformation__text--primary;
                white-space: nowrap;
            }

            >span:nth-child(2) {
                @include listInformation__text--secondary;
                white-space: nowrap;
            }
        }
    }

    &__merchant {
        @include listInformation__text--primary;
    }

    &__class {
        >div {
            display: flex;
            flex-direction: column;

            >span:nth-child(1) {
                @include listInformation__text--primary;
            }

            >span:nth-child(2),
            >span:nth-child(3) {
                @include listInformation__text--secondary;
            }
        }
    }

    &__owner {
        >div {
            >div {
                display: flex;
                flex-direction: column;

                >span {
                    max-width: 160px;
                    word-wrap: break-word;
                }

                >span:nth-child(1) {
                    @include listInformation__text--primary;
                }

                >span:nth-child(2),
                >span:nth-child(3) {
                    @include listInformation__text--secondary;
                }
            }
        }
    }

    &__source {

        // display: flex;
        // flex-direction: column;
        >span:nth-child(1) {
            @include listInformation__text--primary;
        }

        >span:nth-child(2) {
            display: block;
            @include listInformation__text--secondary;
        }
    }

    &__type {
        @include listInformation__text--primary;
    }

    &__status {
        >span:nth-child(1) {
            padding: 2px 8px;
            border-radius: 8px;
            white-space: nowrap;
            width: fit-content;
        }

        >span:nth-child(2) {
            margin-top: 4px;
        }

        >span:nth-child(2),
        >span:nth-child(3),
        >span:nth-child(4) {
            @include listInformation__text--secondary;
            display: block;
        }
    }

    &__action {
        vertical-align: middle !important;

        >div {
            display: flex;

            >i {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 24px;
                height: 24px;
                background-color: #FAFAFA;
                border: 1px solid #C9CFCE;
                border-radius: 4px;
                cursor: pointer;

                &:not(:last-child) {
                    margin-right: 9px;
                }

                svg {
                    width: 16px;
                    height: 16px;
                }
            }
        }
    }
}

/*=========================================
    TAB MEMBSHIP : EACH COLUMN STYLE
==========================================*/

.membershipTable {
    td {
        vertical-align: top;
    }

    &__id {
        @include listInformation__text--primary;
    }

    &__created {
        @include listInformation__text--primary;
        white-space: nowrap;
    }

    &__membership-name {
        max-width: 160px;
        @include listInformation__text--primary;
    }

    &__plan {
        max-width: 160px;

        >div:nth-child(1) {
            display: flex;
            flex-direction: column;

            >:nth-child(1) {
                @include listInformation__text--primary;
            }

            >:nth-child(2),
            >:nth-child(3) {
                @include listInformation__text--secondary;
                ;
                white-space: nowrap;
            }
        }
    }

    &__periode {
        @include listInformation__text--primary;
    }

    &__owner {
        max-width: 160px;

        >div {
            display: flex;
            flex-direction: column;

            >span:nth-child(1) {
                @include listInformation__text--primary;
            }

            >span:nth-child(1),
            >span:nth-child(2) {
                word-wrap: break-word;
            }

            >span:nth-child(2),
            >span:nth-child(3) {
                @include listInformation__text--secondary;
            }
        }
    }

    &__status {
        >span:nth-child(1) {
            border-radius: 8px;
            padding: 2px 8px;
            text-align: center;

        }
    }

    &__price {
        >div {
            display: flex;
            flex-direction: column;

            >span:nth-child(1) {
                @include listInformation__text--primary;
                white-space: nowrap;
            }

            >span:nth-child(2) {
                @include listInformation__text--secondary;
                ;
                white-space: nowrap;
            }
        }
    }

    &__action {
        vertical-align: middle !important;

        >div {
            display: flex;
            align-items: center;

            >i:nth-child(1) {
                margin-right: 9px;
            }

            >i {
                cursor: pointer;
                background: #FAFAFA;
                border: 1px solid #C9CFCE;
                box-sizing: border-box;
                border-radius: 4px;

                width: 24px;
                height: 24px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 4px;

                svg {
                    width: 16px;
                    height: 16px;
                }
            }
        }
    }
}

/*=========================================
    TAB MERCHANT PACKAGE : EACH COLUMN STYLE
==========================================*/
.merchantPackageTable {
    tbody td {
        vertical-align: top;
    }

    &__id {
        @include listInformation__text--primary;
    }

    &__date {
        @include listInformation__text--primary;
        white-space: nowrap;
    }

    &__merchant {
        div {
            >span:nth-child(1) {
                @include listInformation__text--primary;
                display: block;
                margin-bottom: 8px;
            }
        }
    }

    &__package {
        @include listInformation__text--primary;
    }

    &__owner {
        >div {
            display: flex;
            flex-direction: column;

            >span:nth-child(1) {
                @include listInformation__text--primary;
            }

            >span:nth-child(2),
            >span:nth-child(3) {
                @include listInformation__text--secondary;
            }
        }
    }

    &__status {
        >span:nth-child(1) {
            padding: 2px 8px;
            border-radius: 8px;
            white-space: nowrap;
            width: fit-content;
        }
    }

    &__action {
        vertical-align: middle !important;

        >div {
            display: flex;

            >i {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 24px;
                height: 24px;
                background-color: #FAFAFA;
                border: 1px solid #C9CFCE;
                border-radius: 4px;
                cursor: pointer;

                &:not(:last-child) {
                    margin-right: 9px;
                }

                svg {
                    width: 16px;
                    height: 16px;
                }
            }
        }
    }

}