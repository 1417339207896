.content {
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    padding: 10px;

    &__cards {
        max-width: 30rem;
        margin-bottom: 23px;
        padding: 20px;

        &__projects {
            display: flex;
            flex-direction: row;
            background: #FFFFFF;
            border: 1px solid #E4E7E7;
            border-radius: 10px;
            padding: 14px;
            cursor: pointer;
            // &:hover{
            //     transform: scale(1.05);
            // }

            &__picture {
                border: 1px solid #E4E7E7;
                border-radius: 10px;
                >img{
                    width: 100%;
                    height: 100%;
                }
            }

            &__title {
                margin-left: 20px;
                margin-top: 40px;
                padding: 5px;
                font-weight: 500;
                font-size: 14px;
                letter-spacing: 0.5px;
                color: #000000;
                width: fit-content;
            }

            &__icon{
                height: fit-content;
                width: fit-content;
            }
        }
    }
}
