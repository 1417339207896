.merchantFood__merchant-lists {
    margin-top: 15px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(377px, 1fr));
    grid-gap: 18px;
}

.merchantFood__merchant-lists__item {
    display: flex;
    background: #FFFFFF;
    border: 1px solid #BDBDBD;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 14px 10px;
    width: 100%;
    overflow: hidden;
    transform: scale(.99);
    transition: .5s;

    &:hover {
        background-color: rgba($color: #000000, $alpha: 0.1);
        transform: scale(1);
        cursor: pointer;
    }

    &__img {
        margin-right: 16px;

        >img {
            border-radius: 10px;
            width: 101px;
            height: 101px;
            object-fit: cover;
        }
    }

    &__title {
        >.merchantName {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 14px;
            letter-spacing: 0.03em;
            color: #333333;
            margin-bottom: 13px;
        }

        >.merchantGoal {
            display: flex;
            align-items: center;

            >.title {
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 17px;
                letter-spacing: 0.01em;
                color: #495050;
                margin-bottom: 10px;

                &:after {
                    content: ",\00A0"
                }

                &:last-of-type:after {
                    content: ""
                }
            }

        }

        >.merchantRating {
            display: flex;
            align-items: center;
            margin-bottom: 8px;

            >div:nth-child(1) {
                display: flex;
                align-items: center;

                >img {
                    height: 16px;
                }

                margin-right: 16px;
            }

            >div:nth-child(2),
            >div:nth-child(3) {
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 17px;
                letter-spacing: 0.01em;
                color: #17948C;
                margin-right: 5px;
            }
        }

        >.merchantCity {
            display: flex;
            align-items: center;

            >div {
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 17px;
                letter-spacing: 0.01em;
                color: #333333;
                opacity: 0.5;
                white-space: nowrap;
                overflow: hidden;
                // text-overflow: ellipsis;

                &:after {
                    content: ",\00A0"
                }

                &:last-of-type:after {
                    content: ""
                }
            }
        }
    }
}